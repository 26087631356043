/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/groups';
import * as Sentry from '@sentry/react';

export const fetchList =
  (siteId, page = 1, perPage = 10) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`api/v2/sites/${siteId}/animal-groups/`, {
        params: { page, perPage },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.adminGroup]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const addGroup =
  (siteId, values, actions) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.ADD_START });

    actions.setSubmitting(true);
    try {
      const { airspace } = values;
      const res = await api.post(`api/v2/sites/${siteId}/animal-groups/`, values);
      if (res) {
        const res2 = await api.patch(`api/v2/sites/${siteId}/animal-groups/${res.data.id}/location/`, { airspace });
        actions.setSubmitting(false);
        actions.setStatus(true);
        dispatch({ type: constants.ADD_SUCCESS, payload: normalize(res2.data, schema.group) });
        return true;
      }
      return false;
    } catch (e) {
      console.error(e.response && e.response.data);
      dispatch({ type: constants.ADD_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const closeGroup =
  (siteId, groupId, values, actions) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.CLOSE_START });

    actions.setSubmitting(true);
    try {
      const res = await api.patch(`api/v2/sites/${siteId}/animal-groups/${groupId}/close/`, values);
      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.CLOSE_SUCCESS, payload: normalize(res.data, schema.group) });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      dispatch({ type: constants.CLOSE_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const clear = () => ({ type: constants.CLEAR });
