/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/sites/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const AVAILABILITY_LOAD_START = prefix('AVAILABILITY_LOAD_START');
export const AVAILABILITY_LOAD_SUCCESS = prefix('AVAILABILITY_LOAD_SUCCESS');
export const AVAILABILITY_LOAD_FAILED = prefix('AVAILABILITY_LOAD_FAILED');

export const DEVICE_LOAD_START = prefix('DEVICE_LOAD_START');
export const DEVICE_LOAD_SUCCESS = prefix('DEVICE_LOAD_SUCCESS');
export const DEVICE_LOAD_FAILED = prefix('DEVICE_LOAD_FAILED');

export const DATA_USAGE_LOAD_START = prefix('DATA_USAGE_LOAD_START');
export const DATA_USAGE_LOAD_SUCCESS = prefix('DATA_USAGE_LOAD_SUCCESS');
export const DATA_USAGE_LOAD_FAILED = prefix('DATA_USAGE_LOAD_FAILED');

export const GATEWAYS_LOAD_START = prefix('GATEWAYS_LOAD_START');
export const GATEWAYS_LOAD_SUCCESS = prefix('GATEWAYS_LOAD_SUCCESS');
export const GATEWAYS_LOAD_FAILED = prefix('GATEWAYS_LOAD_FAILED');

export const USERS_ACTIVITY_LOAD_START = prefix('USERS_ACTIVITY_LOAD_START');
export const USERS_ACTIVITY_LOAD_SUCCESS = prefix('USERS_ACTIVITY_LOAD_SUCCESS');
export const USERS_ACTIVITY_LOAD_FAILED = prefix('USERS_ACTIVITY_LOAD_FAILED');

export const WEEKLY_LOAD_START = prefix('WEEKLY_LOAD_START');
export const WEEKLY_LOAD_SUCCESS = prefix('WEEKLY_LOAD_SUCCESS');
export const WEEKLY_LOAD_FAILED = prefix('WEEKLY_LOAD_FAILED');

export const MONTHLY_LOAD_START = prefix('MONTHLY_LOAD_START');
export const MONTHLY_LOAD_SUCCESS = prefix('MONTHLY_LOAD_SUCCESS');
export const MONTHLY_LOAD_FAILED = prefix('MONTHLY_LOAD_FAILED');

export const NOTIFICATION_LOAD_START = prefix('NOTIFICATION_LOAD_START');
export const NOTIFICATION_LOAD_SUCCESS = prefix('NOTIFICATION_LOAD_SUCCESS');
export const NOTIFICATION_LOAD_FAILED = prefix('NOTIFICATION_LOAD_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const INFO_UPDATE_START = prefix('INFO_UPDATE_START');
export const INFO_UPDATE_SUCCESS = prefix('INFO_UPDATE_SUCCESS');
export const INFO_UPDATE_FAILED = prefix('INFO_UPDATE_FAILED');

export const DISPLAY_STATUS_UPDATE_START = prefix('DISPLAY_STATUS_UPDATE_START');
export const DISPLAY_STATUS_UPDATE_SUCCESS = prefix('DISPLAY_STATUS_UPDATE_SUCCESS');
export const DISPLAY_STATUS_UPDATE_FAILED = prefix('DISPLAY_STATUS_UPDATE_FAILED');

export const STATUS_LOAD_START = prefix('STATUS_LOAD_START');
export const STATUS_LOAD_SUCCESS = prefix('STATUS_LOAD_SUCCESS');
export const STATUS_LOAD_FAILED = prefix('STATUS_LOAD_FAILED');

export const STRUCTURE_LOAD_START = prefix('STRUCTURE_LOAD_START');
export const STRUCTURE_LOAD_SUCCESS = prefix('STRUCTURE_LOAD_SUCCESS');
export const STRUCTURE_LOAD_FAILED = prefix('STRUCTURE_LOAD_FAILED');

export const INFO_CLEAR = prefix('INFO_CLEAR');
export const AVAILABILITY_CLEAR = prefix('AVAILABILITY_CLEAR');
export const DATA_USAGE_CLEAR = prefix('DATA_USAGE_CLEAR');
export const CLEAR_SINGLE_STATUS = prefix('CLEAR_SINGLE_STATUS');
export const CLEAR_STRUCTURE = prefix('CLEAR_STRUCTURE');
export const CHARTS_CLEAR = prefix('CHARTS_CLEAR');
export const CLEAR = prefix('CLEAR');
export const DEVICES_CLEAR = prefix('DEVICES_CLEAR');

export const RDI_LOAD_START = prefix('RDI_LOAD_START');
export const RDI_LOAD_SUCCESS = prefix('RDI_LOAD_SUCCESS');
export const RDI_LOAD_FAILED = prefix('RDI_LOAD_FAILED');
export const RDI_CLEAR = prefix('RDI_CLEAR');

export const SITE_TEMPERATURE_HUMIDITY_LOAD_START = prefix('SITE_TEMPERATURE_HUMIDITY_LOAD_START');
export const SITE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS = prefix('SITE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS');
export const SITE_TEMPERATURE_HUMIDITY_LOAD_FAILED = prefix('SITE_TEMPERATURE_HUMIDITY_LOAD_FAILED');
export const CLEAR_SITE_TEMPERATURE_HUMIDITY = prefix('CLEAR_SITE_TEMPERATURE_HUMIDITY');

export const AIRSPACES_STATUSES_LOAD_START = prefix('AIRSPACES_STATUSES_LOAD_START');
export const AIRSPACES_STATUSES_LOAD_SUCCESS = prefix('AIRSPACES_STATUSES_LOAD_SUCCESS');
export const AIRSPACES_STATUSES_LOAD_FAILED = prefix('AIRSPACES_STATUSES_LOAD_FAILED');
export const AIRSPACES_STATUSES_CLEAR = prefix('AIRSPACES_STATUSES_CLEAR');

export const USERS_LOAD_START = prefix('USERS_LOAD_START');
export const USERS_LOAD_SUCCESS = prefix('USERS_LOAD_SUCCESS');
export const USERS_LOAD_FAILED = prefix('USERS_LOAD_FAILED');
export const USERS_CLEAR = prefix('USERS_CLEAR');

export const ACTIVATE_DEVICE_LICENSE_START = prefix('ACTIVATE_DEVICE_LICENSE_START');
export const ACTIVATE_DEVICE_LICENSE_SUCCESS = prefix('ACTIVATE_DEVICE_LICENSE_SUCCESS');
export const ACTIVATE_DEVICE_LICENSE_FAILED = prefix('ACTIVATE_DEVICE_LICENSE_FAILED');

export const STATS_LOAD_START = prefix('STATS_LOAD_START');
export const STATS_LOAD_SUCCESS = prefix('STATS_LOAD_SUCCESS');
export const STATS_LOAD_FAILED = prefix('STATS_LOAD_FAILED');

export const LIST_DOWNLOAD_START = prefix('LIST_DOWNLOAD_START');
export const LIST_DOWNLOAD_SUCCESS = prefix('LIST_DOWNLOAD_SUCCESS');
export const LIST_DOWNLOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const ALERTS_LIST_LOAD_START = prefix('ALERTS_LIST_LOAD_START');
export const ALERTS_LIST_LOAD_SUCCESS = prefix('ALERTS_LIST_LOAD_SUCCESS');
export const ALERTS_LIST_LOAD_FAILED = prefix('ALERTS_LIST_LOAD_FAILED');

export const NOTIFICATIONS_LIST_LOAD_START = prefix('NOTIFICATIONS_LIST_LOAD_START');
export const NOTIFICATIONS_LIST_LOAD_SUCCESS = prefix('NOTIFICATIONS_LIST_LOAD_SUCCESS');
export const NOTIFICATIONS_LIST_LOAD_FAILED = prefix('NOTIFICATIONS_LIST_LOAD_FAILED');

export const PUSH_NOTIFICATIONS_LIST_LOAD_START = prefix('PUSH_NOTIFICATIONS_LIST_LOAD_START');
export const PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS = prefix('PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS');
export const PUSH_NOTIFICATIONS_LIST_LOAD_FAILED = prefix('PUSH_NOTIFICATIONS_LIST_LOAD_FAILED');

export const DISEASE_HISTORY_LOAD_START = prefix('DISEASE_HISTORY_LOAD_START');
export const DISEASE_HISTORY_LOAD_SUCCESS = prefix('DISEASE_HISTORY_LOAD_SUCCESS');
export const DISEASE_HISTORY_LOAD_FAILED = prefix('DISEASE_HISTORY_LOAD_FAILED');

export const CLEAR_STATS = prefix('CLEAR_STATS');
