/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/devices';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const itemsPagination = new Pagination('adminDevices');
export const loadingProgress = new LoadingProgress('adminDevice');
export const listLoadingProgress = new LoadingProgress('adminUsersList');
export const gatewaysLoadingProgress = new LoadingProgress('adminGateways');
export const availabilityLoadingProgress = new LoadingProgress('deviceAvailability');
export const dataUsageLoadingProgress = new LoadingProgress('dataUsage');
export const connectivityLoadingProgress = new LoadingProgress('connectivityLoadingProgress');
export const registeredDevicesLoadingProgress = new LoadingProgress('adminRegisteredDevices');
export const farmsLoadingProgress = new LoadingProgress('adminFarms');
export const onlineDevicesLoadingProgress = new LoadingProgress('onlineDevices');
export const unregisteredDevicesLoadingProgress = new LoadingProgress('unregisteredDevices');
export const unregisteredDevicesPagination = new Pagination('unregisteredDevices');
export const rdiLoadingProgress = new LoadingProgress('rdi');
export const temperatureHumidityLoadingProgress = new LoadingProgress('temperatureHumidity');
export const weekKPILoadingProgress = new LoadingProgress('weekKPI');
export const monthKPILoadingProgress = new LoadingProgress('monthKPI');
export const threeMonthsKPILoadingProgress = new LoadingProgress('threeMonthsKPI');
export const coughDataLoadingProgress = new LoadingProgress('siteCoughData');
export const coughDataPagination = new Pagination('siteCoughData');
export const colorHistoryLoadingProgress = new LoadingProgress('colorHistory');
export const colorHistoryPagination = new Pagination('colorHistory');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { devices },
    } = payload;
    newState.mergeIn(['entities'], fromJS(devices));
  });

const mergeDataUsage = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { dataUsages },
    } = payload;

    newState.setIn(['dataUsage'], fromJS(dataUsages));
  });

const mergeUnregisteredDevices = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { devices },
    } = payload;

    newState.mergeIn(['unregisteredDevices'], fromJS(devices));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadGateways = (state, action) =>
  state.withMutations((newState) => {
    newState.set('gateways', action.payload);
    gatewaysLoadingProgress.setLoaded(newState);
  });

const loadAvailability = (state, action) =>
  state.withMutations((newState) => {
    newState.set('availability', fromJS(action.payload));
    availabilityLoadingProgress.setLoaded(newState);
  });

const loadConnectivity = (state, action) =>
  state.withMutations((newState) => {
    newState.set('connectivity', action.payload);
    connectivityLoadingProgress.setLoaded(newState);
  });

const loadDataUsage = (state, action) =>
  state.withMutations((newState) => {
    mergeDataUsage(newState, action.payload);
    dataUsageLoadingProgress.setLoaded(newState);
  });

const loadFarms = (state, action) =>
  state.withMutations((newState) => {
    newState.set('farms', action.payload);
    farmsLoadingProgress.setLoaded(newState);
  });

const loadOnlineDevices = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['onlineDevices'], fromJS(action.payload));
    onlineDevicesLoadingProgress.setLoaded(newState);
  });

const loadUnregisteredDevices = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    mergeUnregisteredDevices(newState, payload);
    unregisteredDevicesLoadingProgress.setLoaded(newState);
    unregisteredDevicesPagination.set(newState, null, null, fromJS(payload.result));
  });

const loadRDI = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['rdi'], fromJS(action.payload));
    rdiLoadingProgress.setLoaded(newState);
  });

const loadCoughData = (state, action) =>
  state.withMutations((newState) => {
    const { items, total, perPage } = action.payload;
    newState.setIn(['coughData'], fromJS(items));
    coughDataLoadingProgress.setLoaded(newState);
    coughDataPagination.set(newState, total, perPage, fromJS(items));
  });

const rdiClear = (state) =>
  state.withMutations((newState) => {
    rdiLoadingProgress.clear(newState);
  });

const clearAvailabilities = (state) =>
  state.withMutations((newState) => {
    availabilityLoadingProgress.clear(newState);
  });

const dataUsageClear = (state) =>
  state.withMutations((newState) => {
    dataUsageLoadingProgress.clear(newState);
  });

const loadRegisteredDevices = (state, action) =>
  state.withMutations((newState) => {
    newState.set('registeredDevices', action.payload);
    registeredDevicesLoadingProgress.setLoaded(newState);
  });

const loadWeekKPI = (state, action) =>
  state.withMutations((newState) => {
    newState.set('weekKPI', action.payload);
    weekKPILoadingProgress.setLoaded(newState);
  });

const loadMonthKPI = (state, action) =>
  state.withMutations((newState) => {
    newState.set('monthKPI', action.payload);
    monthKPILoadingProgress.setLoaded(newState);
  });

const loadThreeMonthsKPI = (state, action) =>
  state.withMutations((newState) => {
    newState.set('threeMonthsKPI', action.payload);
    threeMonthsKPILoadingProgress.setLoaded(newState);
  });

const devicesDashboardClear = (state) =>
  state.withMutations((newState) => {
    farmsLoadingProgress.clear(newState);
    gatewaysLoadingProgress.clear(newState);
    onlineDevicesLoadingProgress.clear(newState);
    unregisteredDevicesLoadingProgress.clear(newState);
    registeredDevicesLoadingProgress.clear(newState);
    connectivityLoadingProgress.clear(newState);
  });

const loadTemperatureHumidity = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['temperatureHumidity'], fromJS(action.payload));
    temperatureHumidityLoadingProgress.setLoaded(newState);
  });

const loadColorHistoryList = (state, action) =>
  state.withMutations((newState) => {
    const { items, perPage, total } = action.payload;
    newState.setIn(['colorHistory'], fromJS(items));
    colorHistoryPagination.set(newState, total, perPage, fromJS(items));
    colorHistoryLoadingProgress.setLoaded(newState);
  });

const clearTemperatureHumidity = (state) =>
  state.withMutations((newState) => {
    temperatureHumidityLoadingProgress.clear(newState);
  });

const clearCharts = (state) =>
  state.withMutations((newState) => {
    clearTemperatureHumidity(newState);
    dataUsageClear(newState);
    clearAvailabilities(newState);
    rdiClear(newState);
  });

const clearKPICharts = (state) =>
  state.withMutations((newState) => {
    weekKPILoadingProgress.clear(newState);
    monthKPILoadingProgress.clear(newState);
    threeMonthsKPILoadingProgress.clear(newState);
  });

const clearCoughData = (state) =>
  state.withMutations((newState) => {
    coughDataLoadingProgress.clear(newState);
    coughDataPagination.clear(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
    itemsPagination.clear(newState);
    gatewaysLoadingProgress.clear(newState);
    loadingProgress.clear(newState);
    clearCharts(newState);
    clearKPICharts(newState);
    clearCoughData(newState);
  });

const initialState = fromJS({
  entities: {},
  availabilities: {},
  rdi: {},
  temperatureHumidity: {},
  coughData: {},
  colorHistory: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.GATEWAYS_LOAD_START:
      return gatewaysLoadingProgress.setLoading(state);
    case constants.GATEWAYS_LOAD_FAILED:
      return gatewaysLoadingProgress.setLoadFailed(state);
    case constants.GATEWAYS_LOAD_SUCCESS:
      return loadGateways(state, action);

    case constants.AVAILABILITY_LOAD_START:
      return availabilityLoadingProgress.setLoading(state);
    case constants.AVAILABILITY_LOAD_FAILED:
      return availabilityLoadingProgress.setLoadFailed(state);
    case constants.AVAILABILITY_LOAD_SUCCESS:
      return loadAvailability(state, action);

    case constants.CONNECTIVITY_LOAD_START:
      return connectivityLoadingProgress.setLoading(state);
    case constants.CONNECTIVITY_LOAD_FAILED:
      return connectivityLoadingProgress.setLoadFailed(state);
    case constants.CONNECTIVITY_LOAD_SUCCESS:
      return loadConnectivity(state, action);

    case constants.DATA_USAGE_LOAD_START:
      return dataUsageLoadingProgress.setLoading(state);
    case constants.DATA_USAGE_LOAD_FAILED:
      return dataUsageLoadingProgress.setLoadFailed(state);
    case constants.DATA_USAGE_LOAD_SUCCESS:
      return loadDataUsage(state, action);

    case constants.FARMS_LOAD_START:
      return farmsLoadingProgress.setLoading(state);
    case constants.FARMS_LOAD_FAILED:
      return farmsLoadingProgress.setLoadFailed(state);
    case constants.FARMS_LOAD_SUCCESS:
      return loadFarms(state, action);

    case constants.ONLINE_DEVICES_LOAD_START:
      return onlineDevicesLoadingProgress.setLoading(state);
    case constants.ONLINE_DEVICES_LOAD_FAILED:
      return onlineDevicesLoadingProgress.setLoadFailed(state);
    case constants.ONLINE_DEVICES_LOAD_SUCCESS:
      return loadOnlineDevices(state, action);

    case constants.UNREGISTERED_DEVICES_LOAD_START:
      return unregisteredDevicesLoadingProgress.setLoading(state);
    case constants.UNREGISTERED_DEVICES_LOAD_FAILED:
      return unregisteredDevicesLoadingProgress.setLoadFailed(state);
    case constants.UNREGISTERED_DEVICES_LOAD_SUCCESS:
      return loadUnregisteredDevices(state, action);

    case constants.RDI_LOAD_START:
      return rdiLoadingProgress.setLoading(state);
    case constants.RDI_LOAD_FAILED:
      return rdiLoadingProgress.setLoadFailed(state);
    case constants.RDI_LOAD_SUCCESS:
      return loadRDI(state, action);

    case constants.AVAILABILITY_CLEAR:
      return clearAvailabilities(state);

    case constants.DATA_USAGE_CLEAR:
      return dataUsageClear(state);

    case constants.REGISTERED_DEVICES_LOAD_START:
      return registeredDevicesLoadingProgress.setLoading(state);
    case constants.REGISTERED_DEVICES_LOAD_SUCCESS:
      return loadRegisteredDevices(state, action);
    case constants.REGISTERED_DEVICES_LOAD_FAILED:
      return registeredDevicesLoadingProgress.setLoadFailed(state);

    case constants.DEVICE_TEMPERATURE_HUMIDITY_LOAD_START:
      return temperatureHumidityLoadingProgress.setLoading(state);
    case constants.DEVICE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS:
      return loadTemperatureHumidity(state, action);
    case constants.DEVICE_TEMPERATURE_HUMIDITY_LOAD_FAILED:
      return temperatureHumidityLoadingProgress.setLoadFailed(state);

    case constants.WEEK_KPI_LOAD_START:
      return weekKPILoadingProgress.setLoading(state);
    case constants.WEEK_KPI_LOAD_SUCCESS:
      return loadWeekKPI(state, action);
    case constants.WEEK_KPI_LOAD_FAILED:
      return weekKPILoadingProgress.setLoadFailed(state);

    case constants.MONTH_KPI_LOAD_START:
      return monthKPILoadingProgress.setLoading(state);
    case constants.MONTH_KPI_LOAD_SUCCESS:
      return loadMonthKPI(state, action);
    case constants.MONTH_KPI_LOAD_FAILED:
      return monthKPILoadingProgress.setLoadFailed(state);

    case constants.THREE_MONTH_KPI_LOAD_START:
      return threeMonthsKPILoadingProgress.setLoading(state);
    case constants.THREE_MONTH_KPI_LOAD_SUCCESS:
      return loadThreeMonthsKPI(state, action);
    case constants.THREE_MONTH_KPI_LOAD_FAILED:
      return threeMonthsKPILoadingProgress.setLoadFailed(state);

    case constants.COUGH_DATA_LOAD_START:
      return coughDataLoadingProgress.setLoading(state);
    case constants.COUGH_DATA_LOAD_FAILED:
      return coughDataLoadingProgress.setLoadFailed(state);
    case constants.COUGH_DATA_LOAD_SUCCESS:
      return loadCoughData(state, action);

    case constants.COLOR_HISTORY_LOAD_START:
      return colorHistoryLoadingProgress.setLoading(state);
    case constants.COLOR_HISTORY_LOAD_FAILED:
      return colorHistoryLoadingProgress.setLoadFailed(state);
    case constants.COLOR_HISTORY_LOAD_SUCCESS:
      return loadColorHistoryList(state, action);

    case constants.CLEAR:
      return clear(state);
    case constants.DEVICES_DASHBOARD_CLEAR:
      return devicesDashboardClear(state);
    case constants.RDI_CLEAR:
      return rdiClear(state);
    case constants.CLEAR_DEVICES_TEMPERATURE_HUMIDITY:
      return clearTemperatureHumidity(state);
    case constants.CLEAR_CHARTS:
      return clearCharts(state);
    case constants.CLEAR_KPI:
      return clearKPICharts(state);
    default:
      return state;
  }
};
