/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { format, isSameDay, isSameYear, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getLanguage, locales } from 'utils/language-helpers';
import { localeToFormat } from 'utils/language-helpers';

export const formatDate = (incomeDate, formatPattern = 'full_day_with_time', extraFormat) => {
  if (!incomeDate) return null;

  const date = incomeDate instanceof Date ? incomeDate : parseISO(incomeDate);
  const locale = locales[getLanguage()] || locales.enUS;

  if (extraFormat) return format(date, extraFormat, { locale });

  const localizedFormat = localeToFormat(getLanguage())[formatPattern];
  return format(date, localizedFormat, { locale });
};

export const formatDateScaling = (incomeDate, timeZone, formatPattern) => {
  if (!incomeDate) {
    return null;
  }
  const date = incomeDate instanceof Date ? incomeDate : parseISO(incomeDate);
  const zonedDate = timeZone ? utcToZonedTime(date, timeZone) : date;

  if (format(zonedDate, 'HH:mm') === 'Invalid Date') return zonedDate;

  if (formatPattern) {
    return formatDate(zonedDate, formatPattern);
  }

  return isSameDay(zonedDate, new Date()) ? format(zonedDate, 'HH:mm') : formatDate(zonedDate, 'full_with_time');
};

export const formatDateScalingDay = (incomeDate) => {
  const date = incomeDate instanceof Date ? incomeDate : parseISO(incomeDate);

  if (isSameYear(date, new Date())) {
    return formatDate(date, 'short');
  }
  return format(date, 'dd/MM/yyyy');
};

const suffixes = {
  'en-US': new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]),
  enUS: new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]),
  de: new Map([
    ['one', '.'],
    ['two', '.'],
    ['few', '.'],
    ['other', '.'],
  ]),
  pl: new Map([
    ['one', ''],
    ['two', ''],
    ['few', ''],
    ['other', ''],
  ]),
  es: new Map([
    ['one', 'º'],
    ['two', 'º'],
    ['few', 'º'],
    ['other', 'º'],
  ]),
  th: new Map([
    ['one', ''],
    ['two', ''],
    ['few', ''],
    ['other', ''],
  ]),
  hu: new Map([
    ['one', '.'],
    ['two', '.'],
    ['few', '.'],
    ['other', '.'],
  ]),
  pt: new Map([
    ['one', 'º'],
    ['two', 'º'],
    ['few', 'º'],
    ['other', 'º'],
  ]),
  nl: new Map([
    ['one', 'e'],
    ['two', 'e'],
    ['few', 'e'],
    ['other', 'e'],
  ]),
  fr: new Map([
    ['one', 'ère'],
    ['two', 'ème'],
    ['few', 'ème'],
    ['other', 'ème'],
  ]),
  vi: new Map([
    ['one', ''],
    ['two', ''],
    ['few', ''],
    ['other', ''],
  ]),
  ja: new Map([
    ['one', ''],
    ['two', ''],
    ['few', ''],
    ['other', ''],
  ]),
  it: new Map([
    ['one', 'º'],
    ['two', 'º'],
    ['few', 'º'],
    ['other', 'º'],
  ]),
};

const localesWithReversedLabels = ['vi'];

export const getOrdinalNumber = (n, label) => {
  if (!n) {
    return null;
  }
  const language = getLanguage() || 'en-US';
  const locale = locales[getLanguage()] || locales.enUS;

  if (localesWithReversedLabels.includes(locale.code)) return `${label} ${n}`;

  const pr = new Intl.PluralRules(locale.code, {
    type: 'ordinal',
  });

  let suffix = '';
  const rule = pr.select(n);
  if (suffixes[language]) {
    suffix = suffixes[language].get(rule);
  }

  return label ? `${n}${suffix} ${label}` : `${n}${suffix}`;
};
