/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/session';
import * as selectors from 'data/selectors/session';
import * as Sentry from '@sentry/react';

export const fetchSession =
  (credentials, formik) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.LOGIN });
    formik.setSubmitting(true);
    try {
      const res = await api.post('/api/token/', credentials);
      api.setAuthorization(res.data.access);
      formik.setSubmitting(false);
      dispatch({
        type: constants.LOGIN_SUCCESS,
        payload: {
          ...res.data,
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LOGIN_FAILED });
      formik.setSubmitting(false);
      formik.setErrors({ message: e.detail });
      if (e.response && e.response.data) {
        formik.setErrors({ message: e.response.data.detail || e.response.data.password, code: e.response.data.code });
      }
      return false;
    }
  };

export const restorePassword =
  (credentials, formik) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.RECOVER });
    formik.setSubmitting(true);
    try {
      const res = await api.post('/password/recover/', credentials);
      formik.setSubmitting(false);
      dispatch({
        type: constants.RECOVER_SUCCESS,
      });
      if (res.data.status === 'OK') {
        formik.setStatus(true);
      } else formik.setErrors(res.data.status);
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.RECOVER_FAILED });
      Sentry.captureException(e);
      formik.setSubmitting(false);
      if (e.response && e.response.data) {
        formik.setErrors(e.response.data);
      }
    }
  };

export const recoverPassword =
  (credentials, formik) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.RECOVER_CONFIRM });
    formik.setSubmitting(true);
    try {
      const res = await api.post('/password/recover/confirm/', credentials);
      formik.setSubmitting(false);
      dispatch({
        type: constants.RECOVER_CONFIRM_SUCCESS,
      });
      if (res.data.status === 'OK') {
        formik.setStatus(true);
      } else formik.setErrors(res.data.status);
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.RECOVER_CONFIRM_FAILED });
      Sentry.captureException(e);
      formik.setSubmitting(false);
      if (e.response && e.response.data) {
        formik.setErrors(e.response.data);
      }
    }
  };

export const contactUs =
  (credentials, formik) =>
  async (dispatch, getState, { api }) => {
    dispatch({ type: constants.CALLBACK });
    formik.setSubmitting(true);
    try {
      const res = await api.post('/contact/', credentials);
      formik.setSubmitting(false);
      dispatch({
        type: constants.CALLBACK_SUCCESS,
      });
      if (res.status === 204) formik.setStatus(true);
      else throw new Error(res.statusText);
    } catch (e) {
      console.log(e);
      formik.setErrors(e);
      dispatch({ type: constants.CALLBACK_FAILED });
      Sentry.captureException(e);
      formik.setSubmitting(false);
      if (e.response && e.response.data) {
        formik.setErrors(e.response.data);
      }
    }
  };

export const fetchPrivacy =
  (country) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.FETCH_START });
    try {
      const res = await api.get('/privacy/', { params: { country } });

      dispatch({
        type: constants.FETCH_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.FETCH_FAILED });
      return false;
    }
  };

export const acceptPrivacy =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.post(`/privacy/${id}/accept/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LOAD_FAILED });
      return e.response;
    }
    return null;
  };

export const fetchDataPrivacy =
  (country) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.FETCH_DATA_PRIVACY_START });
    try {
      const res = await api.get(`/data-privacy/`, { params: { country } });
      dispatch({
        type: constants.FETCH_DATA_PRIVACY_SUCCESS,
        payload: { ...res.data, country },
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.FETCH_DATA_PRIVACY_FAILED });
      return false;
    }
  };

export const acceptDataPrivacy =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.ACCEPT_DATA_PRIVACY_START });
    try {
      await api.post(`/data-privacy/${id}/accept/`);
      dispatch({ type: constants.ACCEPT_DATA_PRIVACY_SUCCESS });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.ACCEPT_DATA_PRIVACY_FAILED });
      return e.response;
    }
    return null;
  };

export const logout =
  () =>
  (dispatch, getState, { api }) => {
    const refresh = selectors.getRefresh(getState());
    api.post(`/logout/`, { refresh_token: refresh });

    api.removeAuthorization();
    return dispatch({ type: constants.LOGOUT });
  };

export const refreshSession =
  () =>
  async (dispatch, getState, { api }) => {
    const refresh = selectors.getRefresh(getState());
    if (!refresh) return false;

    dispatch({ type: constants.REFRESH });
    try {
      const res = await api.post('/api/token/refresh/', { refresh });
      api.setAuthorization(res.data.access);
      dispatch({
        type: constants.REFRESH_SUCCESS,
        payload: {
          ...res.data,
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.REFRESH_FAILED });
      dispatch(logout());
      return false;
    }
  };

export const fetchFiles =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.FETCH_FILES_START });
    try {
      const res = await api.get('/static-files/');
      dispatch({
        type: constants.FETCH_FILES_SUCCESS,
        payload: res.data.items,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.FETCH_FILES_FAILED });
    }
  };

export const fetchPage =
  (slug) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.FETCH_HELP_FILES_START });
    try {
      const res = await api.get(`/pages/${slug}`);
      dispatch({
        type: constants.FETCH_HELP_FILES_SUCCESS,
        payload: { ...res.data, slug },
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.FETCH_HELP_FILES_FAILED });
    }
  };
