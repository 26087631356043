/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { refreshSession, logout } from 'data/actions/session';
import { getRefreshFailed, getRefreshLoading, getSessionActive } from 'data/selectors/session';
import { clear } from 'data/actions/api';
import { getUnauthorized, getAccountLocked, getBackendError } from 'data/selectors/api';
import { RouterPropTypes } from 'data/utils/prop-types';

const mapStateToProps = (state) => ({
  refreshFailed: getRefreshFailed(state),
  refreshLoading: getRefreshLoading(state),
  unauthorized: getUnauthorized(state),
  backendError: getBackendError(state),
  accountLocked: getAccountLocked(state),
  sessionIsActive: getSessionActive(state),
});

const mapDispatchToProps = {
  refreshSession,
  logout,
  clear,
};

class ApiInterceptor extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    refreshSession: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    refreshFailed: PropTypes.bool,
    refreshLoading: PropTypes.bool,
    unauthorized: PropTypes.bool.isRequired,
    history: RouterPropTypes.history.isRequired,
    placeholder: PropTypes.node,
    clear: PropTypes.func.isRequired,
    backendError: PropTypes.string,
    accountLocked: PropTypes.bool.isRequired,
    sessionIsActive: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    refreshFailed: false,
    refreshLoading: false,
    placeholder: null,
    backendError: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.refreshFailed) {
      this.props.logout();
    } else if (this.props.unauthorized && !prevProps.unauthorized) {
      this.props.refreshSession();
    } else if (this.props.backendError) {
      this.props.history.replace(this.getPrefixedPath(this.props.backendError));
      this.props.clear();
    }
  }

  getPrefixedPath = (path) => {
    if (this.props.accountLocked) return '/too-many-requests';

    if (this.props.sessionIsActive) {
      const prefix = this.props.history.location.pathname.includes('admin') ? '/admin' : '/app';
      return prefix.concat('/', path);
    }
    return path;
  };

  render() {
    return this.props.refreshLoading ? this.props.placeholder : this.props.children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiInterceptor);
