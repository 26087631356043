/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ImmutablePropTypes } from 'data';
import { RouterPropTypes } from 'utils/prop-types';
import { useOutsideClick } from 'utils/hooks';
import { transformCountryOptions } from 'utils/format-data';
import { termsConditionsPath, dataPrivacyPath } from 'constants/urls';
import Icon from 'components/core/icon';

import s from './styles.scss';

const HeaderSelector = ({ options, selectedOption, handleSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div className={s.headerSelector}>
      <button type="button" className={s.headerSelectorBtn} onClick={() => setIsOpen(!isOpen)} ref={ref}>
        <span className={s.headerSelectorBtnLabel}>{selectedOption?.label?.toUpperCase()}</span>
        <Icon icon={isOpen ? Icon.icons.arrowUp : Icon.icons.arrowDown} className={s.headerSelectorBtnIcon} />
      </button>
      {isOpen && (
        <div className={s.headerSelectorOptions}>
          {options.map((option) => (
            <button
              type="button"
              key={option.value}
              className={s.headerSelectorOptionsBtn}
              onClick={() => {
                setIsOpen(false);
                handleSelect(option.value);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

HeaderSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedOption: PropTypes.objectOf(PropTypes.string),
};

HeaderSelector.defaultProps = {
  selectedOption: null,
};

const Header = ({
  countries,
  selectedCountry,
  handleSelectCountry,
  languages,
  selectedLanguage,
  handleSelectLanguage,
  location,
  countriesLoaded,
  languagesLoaded,
}) => {
  const countryOptions = countries && useMemo(() => transformCountryOptions(countries.toJS()), [countries]);
  const isCountrySelectorShown = location.pathname === termsConditionsPath || location.pathname === dataPrivacyPath;
  return (
    <div className={s.header}>
      {countriesLoaded && isCountrySelectorShown && (
        <HeaderSelector
          key="country"
          options={countryOptions}
          selectedOption={selectedCountry}
          handleSelect={(value) => handleSelectCountry(countryOptions.find((option) => option.value === value))}
        />
      )}
      {languagesLoaded && (
        <HeaderSelector
          key="language"
          options={languages.toJS()}
          selectedOption={selectedLanguage}
          handleSelect={handleSelectLanguage}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  countries: ImmutablePropTypes.list.isRequired,
  countriesLoaded: PropTypes.bool,
  selectedCountry: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSelectCountry: PropTypes.func.isRequired,
  languages: ImmutablePropTypes.list.isRequired,
  languagesLoaded: PropTypes.bool,
  selectedLanguage: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSelectLanguage: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

Header.defaultProps = {
  countriesLoaded: false,
  languagesLoaded: false,
};

export default Header;
