/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const user = {
  id: PropTypes.number,
  url: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  user_role: PropTypes.string, // ENUM
  profile_picture: PropTypes.string,
};

export const notification = {
  id: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  airspace: PropTypes.object,
  type: PropTypes.oneOf(['NORMAL', 'ALARM']),
  sensors: PropTypes.array,
  priority: PropTypes.object,
  devices: PropTypes.array,
  status: PropTypes.oneOf(['read', 'unread']),
  created_at: PropTypes.string,
};

export const device = {
  id: PropTypes.number,
  url: PropTypes.string,
  name: PropTypes.string,
  last_update: PropTypes.string,
  algo_type: PropTypes.string,
  software_version: PropTypes.string,
  installation_date: PropTypes.string,
  airspace: ImmutablePropTypes.mapContains({
    id: PropTypes.number,
    online: PropTypes.bool,
    decks: ImmutablePropTypes.listOf(PropTypes.number),
  }),
  ip_address: PropTypes.string,
  delivery_status: PropTypes.string, // RECEIVED
  cough_status: PropTypes.string, // SAFE
  temperature_status: PropTypes.string, // SAFE
  humidity_status: PropTypes.string, // WARNING
};

export const alert = {
  id: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  airspace: PropTypes.string,
  type: PropTypes.string,
  sensors: ImmutablePropTypes.listOf(PropTypes.oneOf(['Humidity', 'Temperature', 'Coughs'])),
  priority: PropTypes.oneOf(['Warning', 'Alarm']),
  devices: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(device)),
  status: PropTypes.string,
  created_at: PropTypes.string,
};

export const message = {
  id: PropTypes.number,
  user: PropTypes.number,
  note: PropTypes.number,
  message: PropTypes.string,
  created_at: PropTypes.string,
  username: PropTypes.string,
};

export const note = {
  id: PropTypes.number,
  site: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  author: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  status: PropTypes.string,
  last_message: PropTypes.string,
  last_message_time: PropTypes.string,
  author_last_message: ImmutablePropTypes.mapContains(user),
  messages: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(message)),
  profile_pictures: ImmutablePropTypes.map,
  recipients: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(user)),
  last_read: PropTypes.string,
};

export const site = {
  name: PropTypes.string,
  longitude: PropTypes.string,
  latitude: PropTypes.string,
  last_update: PropTypes.string,
  street: PropTypes.string,
  house_number: PropTypes.number,
  postal_code: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  current_user_role: PropTypes.string,
  status: PropTypes.string,
  timezone: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  company: PropTypes.number,
  latest_notifications: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(notification)),
  unread_notifications: PropTypes.number,
  latest_alerts: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(alert)),
  unread_alerts: PropTypes.number,
  unread_notes: PropTypes.number,
  offline_count: PropTypes.number,
};

export const gateway = {
  code: PropTypes.string,
  country: PropTypes.string,
  device_mode: PropTypes.string,
  device_mode_parameter: PropTypes.string,
  last_update: PropTypes.string,
  device_mode: PropTypes.string,
  name: PropTypes.string,
  software_version: PropTypes.string,
  registration: PropTypes.string,
  mac_address: PropTypes.string,
  site: ImmutablePropTypes.mapContains(site),
  devices_amount: PropTypes.number,
  disk_space: PropTypes.number,
  online: PropTypes.bool,
};

export const siteStatus = {
  id: PropTypes.number,
  name: PropTypes.string,
  current_weather_information: ImmutablePropTypes.mapContains({
    temperature: PropTypes.string,
    wind: PropTypes.string,
    humidity: PropTypes.string,
  }),
  count_airspaces: PropTypes.number,
  count_airspaces_offline: PropTypes.number,
  count_devices: PropTypes.number,
  count_devices_offline: PropTypes.number,
  cough_alarm: PropTypes.number,
  cough_warning: PropTypes.number,
  temperature_alarm: PropTypes.number,
  temperature_warning: PropTypes.number,
  humidity_alarm: PropTypes.number,
  humidity_warning: PropTypes.number,
  status_url: PropTypes.string,
};

export const group = {
  name: PropTypes.string,
  linked_sites: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(site)),
};

export const airspace = {
  id: PropTypes.number,
  site_pk: PropTypes.number,
  name: PropTypes.string,
  last_update: PropTypes.string,
  product_type: PropTypes.string,
  day_in_episode: PropTypes.number,
  outbreak_start: PropTypes.date,
  recommendations: PropTypes.number,
  coughs: PropTypes.shape({
    warning: PropTypes.number,
    alarm: PropTypes.number,
  }),
  humidity: PropTypes.shape({
    warning: PropTypes.number,
    alarm: PropTypes.number,
  }),
  temperature: PropTypes.shape({
    warning: PropTypes.number,
    alarm: PropTypes.number,
  }),
  days_without_issues: PropTypes.number,
  status: PropTypes.string,
  color: PropTypes.string,
  outbreak_interrupted: PropTypes.bool,
};

export const airspaceStatus = {
  id: PropTypes.number,
  name: PropTypes.string,
  sensor: PropTypes.oneOf([0, 1, 2]),
  last_update: PropTypes.string,
  status: PropTypes.oneOf(['Unknown', 'Warning', 'Success', 'Alarm']),
};

export const airspaceHistory = {
  id: PropTypes.number,
  name: PropTypes.string,
  sensor: PropTypes.oneOf([0, 1, 2]),
  history: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      datetime: PropTypes.string,
      status: PropTypes.number,
    }),
  ),
};

export const profile = {
  id: PropTypes.number,
  usersettings_id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  language: PropTypes.string,
  timezone: PropTypes.string,
  temperature: PropTypes.string,
  profile_picture: PropTypes.string,
  country: PropTypes.string,
  paging_size: PropTypes.number,
  note_switch: PropTypes.bool,
  notification_switch: PropTypes.bool,
  alerts_switch: PropTypes.bool,
  accepted_last_data_privacy: PropTypes.bool,
  accepted_last_privacy_policy: PropTypes.bool,
  consent_newsletter: PropTypes.bool,
  consent_personal_data_usage: PropTypes.bool,
};

const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string, // only in createBrowserHistory and createMemoryHistory
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.number, PropTypes.object, PropTypes.string]), // only in createBrowserHistory and createMemoryHistory
});

const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func, // only in createMemoryHistory
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(location), // only in createMemoryHistory
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number, // only in createMemoryHistory
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const rdi = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.shape),
  id: PropTypes.number,
  name: PropTypes.string,
});

export const temperatureHumidity = {
  id: PropTypes.number,
  data: PropTypes.shape({
    humidity: PropTypes.arrayOf(PropTypes.shape),
    temperature: PropTypes.arrayOf(PropTypes.shape),
  }),
  name: PropTypes.string,
};

export const airspacesStatuses = {
  id: PropTypes.number,
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape),
  devices: PropTypes.arrayOf(PropTypes.shape),
};

export const RouterPropTypes = {
  location,
  history,
  match,
};

export const rhs = PropTypes.shape({
  id: PropTypes.number,
  fullname: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape),
});

export const vetHistory = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  sensor: PropTypes.oneOf([0, 1, 2]),
  devices: PropTypes.arrayOf(PropTypes.shape),
});

export const logbookEvent = PropTypes.shape({
  datetime: PropTypes.date,
  type: PropTypes.string,
  value: PropTypes.shape(),
});

export const logbookItem = PropTypes.shape({
  date: PropTypes.date,
  events: PropTypes.arrayOf(logbookEvent),
});

export const colorHistory = PropTypes.shape({
  date: PropTypes.date,
  color: PropTypes.string,
  real_color: PropTypes.string,
  status: PropTypes.string,
  real_status: PropTypes.string,
});
