/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/app/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const CLEAR = prefix('CLEAR');
