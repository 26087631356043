/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS, Set } from 'immutable';
import * as constants from 'data/constants/sites';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('site');
export const listLoadingProgress = new LoadingProgress('siteList');
export const itemsPagination = new Pagination('siteList');
export const statusLoadingProgress = new LoadingProgress('siteStatus');
export const structureLoadingProgress = new LoadingProgress('structureStatus');
export const latestInfoLoadingProgress = new LoadingProgress('latestInfo');
export const invitationsLoadingProgress = new LoadingProgress('invitations');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { sites },
    } = payload;
    newState.mergeIn(['entities'], fromJS(sites));
  });

const mergeStatusData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { siteStatuses },
    } = payload;
    newState.mergeIn(['statuses'], fromJS(siteStatuses));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    const { result, total, perPage } = action.payload;
    newState.set('list', fromJS(result));
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadLatestInfo = (state, action) =>
  state.withMutations((newState) => {
    const { type, data } = action.payload;
    latestInfoLoadingProgress.setLoaded(newState);
    return newState.setIn(['latestInfo', type], fromJS(data));
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadStatus = (state, action) =>
  state.withMutations((newState) => {
    mergeStatusData(newState, action.payload);
    statusLoadingProgress.setLoaded(newState);
  });

const filterGroup = (state, action) => state.setIn(['filter', 'group'], action.payload);

const filterStatus = (state, action) => state.setIn(['filter', 'status'], action.payload);

const filterType = (state, action) => state.setIn(['filter', 'type'], Set(action.payload));

const filterSearch = (state, action) => state.setIn(['filter', 'search'], action.payload);

const clearSingle = (state) =>
  state.withMutations((newState) => {
    statusLoadingProgress.clear(newState);
  });

const clearSingleStatus = (state) =>
  state.withMutations((newState) => {
    statusLoadingProgress.clear(newState);
  });

const clearStructure = (state) =>
  state.withMutations((newState) => {
    structureLoadingProgress.clear(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    clearSingle(newState);
    clearSingleStatus(newState);
    listLoadingProgress.clear(newState);
  });

const loadStructure = (state, action) =>
  state.withMutations((newState) => {
    newState.set('structure', action.payload);
    structureLoadingProgress.setLoaded(newState);
  });

const creationSuccess = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    newState.update('list', (list) => list.push(String(action.payload.result)));
    listLoadingProgress.setLoaded(newState);
  });

const updateEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    listLoadingProgress.setLoaded(newState);
  });

const setSiteRecommendationsCount = (state, action) =>
  state.withMutations((newState) => {
    const { siteId, count } = action.payload;
    newState.setIn(['entities', siteId, 'recommendations'], count);
  });

const loadInvitations = (state, action) =>
  state.withMutations((newState) => {
    newState.set('invitations', fromJS(action.payload));
    invitationsLoadingProgress.setLoaded(newState);
  });

const initialState = fromJS({
  list: [],
  entities: {},
  statuses: {},
  filter: {
    group: null,
    status: Set([]),
    type: Set([]),
    search: null,
  },
  structure: {},
  invitations: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.STATUS_LOAD_START:
      return statusLoadingProgress.setLoading(state);
    case constants.STATUS_LOAD_FAILED:
      return statusLoadingProgress.setLoadFailed(state);
    case constants.STATUS_LOAD_SUCCESS:
      return loadStatus(state, action);
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.FILTER_TYPE:
      return filterType(state, action);
    case constants.FILTER_GROUP:
      return filterGroup(state, action);
    case constants.FILTER_STATUS:
      return filterStatus(state, action);
    case constants.FILTER_SEARCH:
      return filterSearch(state, action);
    case constants.CLEAR:
      return clear(state);
    case constants.CLEAR_SINGLE:
      return clearSingle(state);
    case constants.CLEAR_SINGLE_STATUS:
      return clearSingleStatus(state);
    case constants.CLEAR_STRUCTURE:
      return clearStructure(state);
    case constants.STRUCTURE_LOAD_START:
      return structureLoadingProgress.setLoading(state);
    case constants.STRUCTURE_LOAD_SUCCESS:
      return loadStructure(state, action);
    case constants.STRUCTURE_LOAD_FAILED:
      return structureLoadingProgress.setLoadFailed(state);

    case constants.LATEST_INFO_LOAD_START:
      return latestInfoLoadingProgress.setLoading(state);

    case constants.LATEST_INFO_LOAD_SUCCESS:
      return loadLatestInfo(state, action);

    case constants.LATEST_INFO_LOAD_FAILED:
      return latestInfoLoadingProgress.setLoadFailed(state);

    case constants.CREATION_START:
    case constants.UPDATE_START:
      return listLoadingProgress.setLoading(state);
    case constants.CREATION_SUCCESS:
      return creationSuccess(state, action);
    case constants.CREATION_FAILED:
    case constants.UPDATE_FAILED:
      return listLoadingProgress.setLoadFailed(state);

    case constants.UPDATE_SUCCESS:
      return updateEntity(state, action);

    case constants.UPDATE_SITE_RECOMMENDATIONS_COUNT:
      return setSiteRecommendationsCount(state, action);

    case constants.INVITATIONS_LOAD_START:
      return invitationsLoadingProgress.setLoading(state);
    case constants.INVITATIONS_LOAD_FAILED:
      return invitationsLoadingProgress.setLoadFailed(state);
    case constants.INVITATIONS_LOAD_SUCCESS:
      return loadInvitations(state, action);

    default:
      return state;
  }
};
