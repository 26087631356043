/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/profile/${type}`;

export const COUNTRIES_LOAD_START = prefix('COUNTRIES_LOAD_START');
export const COUNTRIES_LOAD_SUCCESS = prefix('COUNTRIES_LOAD_SUCCESS');
export const COUNTRIES_LOAD_FAILED = prefix('COUNTRIES_LOAD_FAILED');
export const COUNTRIES_CLEAR = prefix('COUNTRIES_CLEAR');
