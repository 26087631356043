/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/recommendations/${type}`;

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const SITE_EPISODES_LOAD_START = prefix('SITE_EPISODES_LOAD_START');
export const SITE_EPISODES_LOAD_SUCCESS = prefix('SITE_EPISODES_LOAD_SUCCESS');
export const SITE_EPISODES_LOAD_FAILED = prefix('SITE_EPISODES_LOAD_FAILED');

export const RECOMMENDATION_STATUS_PATCH_START = prefix('RECOMMENDATION_STATUS_PATCH_START');
export const RECOMMENDATION_STATUS_PATCH_SUCCESS = prefix('RECOMMENDATION_STATUS_PATCH_SUCCESS');
export const RECOMMENDATION_STATUS_PATCH_FAILED = prefix('RECOMMENDATION_STATUS_PATCH_FAILED');

export const RECOMMENDATIONS_LIST_STATUS_PATCH_START = prefix('RECOMMENDATIONS_LIST_STATUS_PATCH_START');
export const RECOMMENDATIONS_LIST_STATUS_PATCH_SUCCESS = prefix('RECOMMENDATIONS_LIST_STATUS_PATCH_SUCCESS');
export const RECOMMENDATIONS_LIST_STATUS_PATCH_FAILED = prefix('RECOMMENDATIONS_LIST_STATUS_PATCH_FAILED');

export const EPISODE_DAYS_LOAD_START = prefix('EPISODE_DAYS_LOAD_START');
export const EPISODE_DAYS_LOAD_SUCCESS = prefix('EPISODE_DAYS_LOAD_SUCCESS');
export const EPISODE_DAYS_LOAD_FAILED = prefix('EPISODE_DAYS_LOAD_FAILED');

export const EPISODE_TREATMENTS_START = prefix('EPISODE_TREATMENTS_START');
export const EPISODE_TREATMENTS_SUCCESS = prefix('EPISODE_TREATMENTS_SUCCESS');
export const EPISODE_TREATMENTS_FAILED = prefix('EPISODE_TREATMENTS_FAILED');

export const CLEAR = prefix('CLEAR');

export const UPDATE_EPISODE_RECOMMENDATIONS_COUNT = prefix('UPDATE_EPISODE_RECOMMENDATIONS_COUNT');
