/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/core/icon';

import s from './styles.scss';

class AlertIconErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e) {
    console.log(e);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Icon className={s.icon} icon={Icon.icons.close} />;
    }

    return this.props.children;
  }
}

export default AlertIconErrorBoundary;
