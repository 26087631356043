/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { appStoreLink } from 'constants/urls';

import cn from 'public/app-store/cn.svg';
import de from 'public/app-store/de.svg';
import enUS from 'public/app-store/en-us.svg';
import es from 'public/app-store/es.svg';
import fr from 'public/app-store/fr.svg';
import pl from 'public/app-store/pl.svg';
import pt from 'public/app-store/pt.svg';
import th from 'public/app-store/th.svg';
import nl from 'public/app-store/nl.svg';
import vi from 'public/app-store/vi.svg';
import ja from 'public/app-store/ja.svg';
import it from 'public/app-store/it.svg';

import s from './styles.scss';

const icons = {
  cn,
  de,
  'en-us': enUS,
  es,
  fr,
  pl,
  pt,
  th,
  nl,
  vi,
  ja,
  it,
};

const AppStoreBadge = ({ language }) => (
  <a className={s.badge} href={appStoreLink} target="_blank" rel="noopener noreferrer">
    <img className={s.badgeImage} src={icons[language] || enUS} alt="App store" />
  </a>
);

AppStoreBadge.propTypes = {
  language: PropTypes.string,
};

AppStoreBadge.defaultProps = {
  language: 'en-us',
};

export default AppStoreBadge;
