/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/alerts';
import * as siteConstants from 'data/constants/sites';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('alert');
export const listLoadingProgress = new LoadingProgress('alertList');
export const itemsPagination = new Pagination('alert');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { alerts },
    } = payload;
    newState.mergeIn(['entities'], fromJS(alerts));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total, lazy } = action.payload;
    mergeData(newState, action.payload);
    if (lazy) {
      newState.set('alertPageItems', state.get('alertPageItems').concat(fromJS(result)));
    } else {
      itemsPagination.set(newState, total, perPage, fromJS(result));
    }
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    loadingProgress.clear(newState);
    listLoadingProgress.clear(newState);
    itemsPagination.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.CLEAR:
      return clear(state);
    // Other entities loaded
    case siteConstants.LIST_LOAD_SUCCESS:
      return mergeData(state, action.payload);

    default:
      return state;
  }
};
