/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/licenses/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LICENSE_TEMPLATES_LIST_LOAD_START = prefix('LICENSE_TEMPLATES_LIST_LOAD_START');
export const LICENSE_TEMPLATES_LIST_LOAD_SUCCESS = prefix('LICENSE_TEMPLATES_LIST_LOAD_SUCCESS');
export const LICENSE_TEMPLATES_LIST_LOAD_FAILED = prefix('LICENSE_TEMPLATES_LIST_LOAD_FAILED');

export const ORDERS_LIST_LOAD_START = prefix('ORDERS_LIST_LOAD_START');
export const ORDERS_LIST_LOAD_SUCCESS = prefix('ORDERS_LIST_LOAD_SUCCESS');
export const ORDERS_LIST_LOAD_FAILED = prefix('ORDERS_LIST_LOAD_FAILED');

export const ORDER_ADD_START = prefix('ORDER_ADD_START');
export const ORDER_ADD_SUCCESS = prefix('ORDER_ADD_SUCCESS');
export const ORDER_ADD_FAILED = prefix('ORDER_ADD_FAILED');

export const ORDER_LOAD_START = prefix('ORDER_LOAD_START');
export const ORDER_LOAD_SUCCESS = prefix('ORDER_LOAD_SUCCESS');
export const ORDER_LOAD_FAILED = prefix('ORDER_LOAD_FAILED');

export const ADD_START = prefix('ADD_START');
export const ADD_SUCCESS = prefix('ADD_SUCCESS');
export const ADD_FAILED = prefix('ADD_FAILED');

export const CLEAR = prefix('CLEAR');

export const ORDERS_CLEAR = prefix('ORDERS_CLEAR');

export const ORDER_CLEAR = prefix('ORDER_CLEAR');

export const LICENSE_TEMPALTES_CLEAR = prefix('LICENSE_TEMPALTES_CLEAR');

export const ORDER_UPDATE_START = prefix('ORDER_UPDATE_START');
export const ORDER_UPDATE_SUCCESS = prefix('ORDER_UPDATE_SUCCESS');
export const ORDER_UPDATE_FAILED = prefix('ORDER_UPDATE_FAILED');

export const LICENSE_ADD_START = prefix('LICENSE_ADD_START');
export const LICENSE_ADD_SUCCESS = prefix('LICENSE_ADD_SUCCESS');
export const LICENSE_ADD_FAILED = prefix('LICENSE_ADD_FAILED');
