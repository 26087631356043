/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/users/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');
export const CREATION_CLEAR = prefix('CREATION_CLEAR');

export const REGISTER_START = prefix('REGISTER_START');
export const REGISTER_SUCCESS = prefix('REGISTER_SUCCESS');
export const REGISTER_FAILED = prefix('REGISTER_FAILED');
export const REGISTER_CLEAR = prefix('REGISTER_CLEAR');

export const REGISTER_VALIDATION_START = prefix('REGISTER_VALIDATION_START');
export const REGISTER_VALIDATION_SUCCESS = prefix('REGISTER_VALIDATION_SUCCESS');
export const REGISTER_VALIDATION_FAILED = prefix('REGISTER_VALIDATION_FAILED');

export const RESEND_EMAIL_START = prefix('RESEND_EMAIL_START');
export const RESEND_EMAIL_SUCCESS = prefix('RESEND_EMAIL_SUCCESS');
export const RESEND_EMAIL_FAILED = prefix('RESEND_EMAIL_FAILED');

export const DELETION_START = prefix('DELETION_START');
export const DELETION_SUCCESS = prefix('DELETION_SUCCESS');
export const DELETION_FAILED = prefix('DELETION_FAILED');
export const DELETION_CLEAR = prefix('DELETION_CLEAR');

export const EDIT_START = prefix('EDIT_START');
export const EDIT_SUCCESS = prefix('EDIT_SUCCESS');
export const EDIT_FAILED = prefix('EDIT_FAILED');
export const EDIT_CLEAR = prefix('EDIT_CLEAR');

export const CLEAR = prefix('CLEAR');
