/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import css from 'classnames';
import PropTypes from 'prop-types';

import s from './styles.scss';

export const FluidPreloader = ({ className, style, light }) => (
  <div className={css(s.preloader, light && s.preloaderLight, className)} style={style} />
);

FluidPreloader.defaultProps = {
  style: null,
  className: null,
  light: null,
};

FluidPreloader.propTypes = {
  /* eslint-disable-next-line */
  style: PropTypes.object,
  className: PropTypes.string,
  light: PropTypes.bool,
};
