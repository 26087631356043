/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/users';
import * as Sentry from '@sentry/react';

export const fetchList =
  (page = 1, perPage = 10, orderingField, order) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/users/`, {
        params: { page, perPage, ordering_field: orderingField, order },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.user]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchUsersKpi =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.KPI_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/users/kpi/`);

      dispatch({
        type: constants.KPI_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.KPI_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const add =
  (email, siteId, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CREATION_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`/api/v2/admin-dashboard/sites/${siteId}/users/`, {
        email,
      });
      if (res.status !== 200) throw new Error(res.statusText);
      actions.setSubmitting(false);
      actions.resetForm();
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.CREATION_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ email: e.response.data.message });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const clearDashboard = () => ({ type: constants.CLEAR_DASHBOARD });

export const clear = () => ({ type: constants.CLEAR });
