/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import { listLoadingProgress } from 'data/reducers/airspaces';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = (state) => state.airspaces;

export const getEntities = (state) => getState(state).get('entities');
export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (airspaces, state) =>
  denormalize(state.get('list'), [schemas.airspace], new Map({ airspaces })),
);

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));
