/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export const basePath = '/';

const applyBasePathTo = (path) => `${basePath}${path}`;

/*
 * Usage:
 * paginatedPath(siteNotificationsPath(':siteName'))(':pageNum')
 */

export const paginatedPath = (path) => (page) => `${path}/page/${page}`;

export const doublePaginatedPath = (path) => (page1) => (page2) => `${path}/page/${page1}/${page2}`;

export const reverseDoublePaginatedPath = (path) => (page2) => (page1) => `${path}/page/${page1}/${page2}`;

// Shared paths

export const signInPath = applyBasePathTo('sign-in');

export const signOutPath = applyBasePathTo('sign-out');

export const contactUsPath = applyBasePathTo('contact-us');

export const contactUsSuccessPath = applyBasePathTo('contact-us-success');

export const restorePasswordPath = applyBasePathTo('restore-password');

export const recoverPasswordPath = applyBasePathTo('recover-password');

export const restorePasswordSuccessPath = applyBasePathTo('restore-password-success');

export const registrationPath = applyBasePathTo('registration');

export const registrationSuccessPath = applyBasePathTo('registration-success');

export const notFoundPath = applyBasePathTo('not-found');

export const tooManyRequestsErrorPath = applyBasePathTo('too-many-requests');

export const installationPath = applyBasePathTo('installation');

export const helpPath = applyBasePathTo('help');

export const baseErrorPath = applyBasePathTo('error');

export const termsConditionsPath = applyBasePathTo('terms-of-use');

export const dataPrivacyPath = applyBasePathTo('data-privacy');

export const imprintPath = applyBasePathTo('imprint');

// Logged in paths

export const baseAppPath = applyBasePathTo('app');

export const profilePath = `${baseAppPath}/profile`;

export const errorPath = `${baseAppPath}/error`;

export const profilePreferencesPath = `${profilePath}/preferences`;

export const profilePushNotificationsPath = `${profilePath}/push-notifications`;

export const profilePermissionsPath = `${profilePath}/permissions`;

export const profileChangePasswordPath = `${profilePath}/change-password`;

export const profileSecurityAndPrivacyPath = `${profilePath}/terms-of-use`;

export const profileHelpCenterPath = `${profilePath}/help-center`;

export const profileDataPrivacyPath = `${profilePath}/data-privacy`;

export const profileImprintPath = `${profilePath}/imprint`;

export const profileAboutPath = `${profilePath}/about`;

export const sitesPath = `${baseAppPath}/sites`;

export const siteNotFound = `${sitesPath}/notFound`;

export const siteBasePath = (site) => `${sitesPath}/${site}`;

export const siteCreatingPath = (id) => `${siteBasePath(id)}/new`;

export const siteDashboardPath = (site) => `${siteBasePath(site)}/dashboard`;

export const siteEditPath = (site) => `${siteBasePath(site)}/edit`;

export const siteAlertsPath = (site) => `${siteBasePath(site)}/alerts`;

export const siteNotificationsPath = (site) => `${siteBasePath(site)}/notifications`;

export const siteDevicesPath = (site) => `${siteBasePath(site)}/monitors`;

export const siteDevicesHourPath = (site, timestamp) => `${siteDevicesPath(site)}/hour/${timestamp}`;

export const siteDevicesDayPath = (site, timestamp) => `${siteDevicesPath(site)}/day/${timestamp}`;

export const siteDevicesWeekPath = (site, timestamp) => `${siteDevicesPath(site)}/week/${timestamp}`;

export const siteDevicesMonthPath = (site, timestamp) => `${siteDevicesPath(site)}/month/${timestamp}`;

export const siteUsersPath = (site) => `${siteBasePath(site)}/users`;

export const siteExportsPath = (site) => `${siteBasePath(site)}/exports`;

export const siteSettingsPath = (site) => `${siteBasePath(site)}/settings`;

export const siteAirspacePath = (site, airspace) => `${siteBasePath(site)}/airspaces/${airspace}`;

export const siteAirspaceDashboardPath = (site, airspace) => `${siteAirspacePath(site, airspace)}/dashboard`;

export const siteAirspaceRecommendationsPath = (site, airspace) =>
  `${siteAirspacePath(site, airspace)}/recommendations`;

export const siteAirspaceAlertsPath = (site, airspace) => `${siteAirspacePath(site, airspace)}/alerts`;

export const siteAirspaceDevicesPath = (site, airspace) => `${siteAirspacePath(site, airspace)}/devices`;

export const siteAirspaceGraphsPath = (site, airspace) => `${siteAirspacePath(site, airspace)}/graphs`;

export const siteAirspaceLogbookPath = (site, airspace) => `${siteAirspacePath(site, airspace)}/logbook`;

export const siteAirspaceExportsPath = (site, airspace) => `${siteAirspacePath(site, airspace)}/exports`;

export const appNotFoundPath = `${baseAppPath}/not-found`;

export const appForbiddenPath = `${baseAppPath}/forbidden`;

export const appServerErrorPath = `${baseAppPath}/server-error`;

// Markets

export const appStoreLink = 'https://itunes.apple.com/us/app/soundtalks/id1437633894';

export const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.soundtalks';

// Admin dashboard

export const baseAdminPath = applyBasePathTo('admin');

export const adminSitesPath = `${baseAdminPath}/sites`;

export const adminSitesDashboardPath = `${adminSitesPath}/dashboard`;

export const adminSitesListPath = `${adminSitesPath}/list`;

export const adminSitePath = (site) => `${adminSitesPath}/${site}`;

export const adminSiteEditPath = (site) => `${adminSitesPath}/${site}/edit`;

export const adminSiteDashboardPath = (site) => `${adminSitePath(site)}/dashboard`;

export const adminSiteInfoPath = (site) => `${adminSitePath(site)}/info`;

export const adminSiteRecommendationsPath = (site) => `${adminSitePath(site)}/recommendations`;

export const adminSiteInfoDevicesPath = (site) => `${adminSiteInfoPath(site)}/devices`;

export const adminSiteAlertsNotificationsPath = (site) => `${adminSitePath(site)}/alerts-notifications`;

export const adminSiteAlertsListPath = (site) => `${adminSiteAlertsNotificationsPath(site)}/alerts`;

export const adminSiteNotificationsListPath = (site) => `${adminSiteAlertsNotificationsPath(site)}/notifications`;

export const adminSitePushNotificationsListPath = (site) =>
  `${adminSiteAlertsNotificationsPath(site)}/push-notifications`;

export const adminSiteLocationsPath = (site) => `${adminSitePath(site)}/locations`;

export const adminSiteGraphsPath = (site) => `${adminSitePath(site)}/graphs`;

export const adminSiteUsersPath = (site) => `${adminSitePath(site)}/users`;

export const adminSiteGroupsPath = (site) => `${adminSitePath(site)}/groups`;

export const adminSiteGraphsDataAvailabilityPath = (site) => `${adminSiteGraphsPath(site)}/data-availability`;

export const adminSiteGraphsDataUsagePath = (site) => `${adminSiteGraphsPath(site)}/data-usage`;

export const adminSiteGraphsMonitorStatusPath = (site) => `${adminSiteGraphsPath(site)}/monitor-status`;

export const adminSiteGraphsRdiPath = (site) => `${adminSiteGraphsPath(site)}/rdi`;

export const adminSiteGraphsRdiTemperatureHumidityPath = (site) =>
  `${adminSiteGraphsPath(site)}/rdi-temperature-humidity`;

export const adminSiteGraphsBandwidthPath = (site) => `${adminSiteGraphsPath(site)}/bandwidth`;

export const adminSiteGraphsDownloadUploadPath = (site) => `${adminSiteGraphsPath(site)}/download-upload`;

export const adminDevicesPath = `${baseAdminPath}/devices`;

export const adminDevicesDashboardPath = `${adminDevicesPath}/dashboard`;

export const adminDevicesGatewaysPath = `${adminDevicesPath}/gateways`;

export const adminDevicesListPath = `${adminDevicesPath}/list`;

export const adminDevicePath = (device) => `${adminDevicesPath}/${device}`;

export const adminDeviceDashboardPath = (device) => `${adminDevicePath(device)}/dashboard`;

export const adminDeviceInfoPath = (device) => `${adminDevicePath(device)}/info`;

export const adminDeviceGraphsPath = (device) => `${adminDevicePath(device)}/graphs`;

export const adminDeviceHeatmapsPath = (device) => `${adminDevicePath(device)}/heatmaps`;

export const adminDeviceCoughsPath = (device) => `${adminDevicePath(device)}/coughs`;

export const adminDeviceAudioPath = (device) => `${adminDevicePath(device)}/audio`;

export const adminDeviceUnprocessedPath = (device) => `${adminDevicePath(device)}/unprocessed`;

export const adminDeviceGraphsDataAvailabilityPath = (device) => `${adminDeviceGraphsPath(device)}/data-availability`;

export const adminDeviceGraphsDataUsagePath = (device) => `${adminDeviceGraphsPath(device)}/data-usage`;

export const adminDeviceGraphsMonitorStatusPath = (device) => `${adminDeviceGraphsPath(device)}/monitor-status`;

export const adminDeviceGraphsRdiPath = (device) => `${adminDeviceGraphsPath(device)}/rdi`;

export const adminDeviceGraphsRdiTemperatureHumidityPath = (device) =>
  `${adminDeviceGraphsPath(device)}/rdi-temperature-humidity`;

export const adminDeviceGraphsBandwidthPath = (device) => `${adminDeviceGraphsPath(device)}/bandwidth`;

export const adminDeviceGraphsDownloadUploadPath = (device) => `${adminDeviceGraphsPath(device)}/download-upload`;

export const adminUsersPath = `${baseAdminPath}/users`;

export const adminSuppliersPath = `${baseAdminPath}/suppliers`;

export const adminSuppliersListPath = `${adminSuppliersPath}/list`;

export const adminSupplierDetailsPath = (suppler) => `${adminSuppliersPath}/supplier-details/${suppler}`;

export const adminSitesSupplierDetailsListPath = (suppler) => `${adminSupplierDetailsPath(suppler)}/list`;

export const adminUsersDashboardPath = `${adminUsersPath}/dashboard`;

export const adminUsersListPath = `${adminUsersPath}/list`;

export const adminProfilePath = `${baseAdminPath}/profile`;

export const adminErrorPath = `${baseAdminPath}/error`;

export const adminProfilePrivacyPath = `${adminProfilePath}/privacy`;

export const adminLicensesPath = `${baseAdminPath}/licenses`;

export const adminLicensesListPath = `${adminLicensesPath}/list`;

export const adminLicensesOrdersPath = `${adminLicensesPath}/orders`;

export const adminLicensesOrdersAddPath = `${adminLicensesOrdersPath}/add`;

export const adminLicensesOrderPath = (order) => `${adminLicensesOrdersPath}/${order}`;

export const adminLicensesEditOrderPath = (order) => `${adminLicensesOrderPath(order)}/edit`;

export const adminLicensesAddPath = `${adminLicensesPath}/add`;

export const adminGatewaysPath = `${baseAdminPath}/gateways`;

export const adminGatewayPath = (gateway) => `${adminGatewaysPath}/${gateway}`;

export const adminGatewaysDashboardPath = `${adminGatewaysPath}/dashboard`;

export const adminGatewaysListPath = `${adminGatewaysPath}/list`;

export const adminToolboxPath = `${baseAdminPath}/toolbox`;

export const adminToolboxDashboardPath = `${adminToolboxPath}/dashboard`;

export const adminBulkStatusChangerPath = `${adminToolboxPath}/balk-status-changer`;

export const adminPcbUploadPath = `${adminToolboxPath}/pcb-upload`;

export const adminNotFoundPath = `${baseAdminPath}/not-found`;

export const adminForbiddenPath = `${baseAdminPath}/forbidden`;

export const adminServerErrorPath = `${baseAdminPath}/server-error`;
