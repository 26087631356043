/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { useEffect } from 'react';

export const useScroll = (ref, callback) => {
  const handleScroll = (e) => {
    if (ref.current) {
      const { offsetHeight, scrollTop, scrollHeight } = ref.current;
      if (offsetHeight + scrollTop === scrollHeight) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });
};
