/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/suppliers/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const ADD_START = prefix('ADD_START');
export const ADD_SUCCESS = prefix('ADD_SUCCESS');
export const ADD_FAILED = prefix('ADD_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const DELETION_START = prefix('DELETION_START');
export const DELETION_SUCCESS = prefix('DELETION_SUCCESS');
export const DELETION_FAILED = prefix('DELETION_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');

export const SITES_CLEAR = prefix('SITES_CLEAR');

export const CHILD_LICENSES_CLEAR = prefix('CHILD_LICENSES_CLEAR');
