/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/gateways/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const BULK_STATUS_CHANGE_START = prefix('BULK_STATUS_CHANGE_START');
export const BULK_STATUS_CHANGE_SUCCESS = prefix('BULK_STATUS_CHANGE_SUCCESS');
export const BULK_STATUS_CHANGE_FAILED = prefix('BULK_STATUS_CHANGE_FAILED');

export const PCB_UPLOAD_START = prefix('PCB_UPLOAD_START');
export const PCB_UPLOAD_SUCCESS = prefix('PCB_UPLOAD_SUCCESS');
export const PCB_UPLOAD_FAILED = prefix('PCB_UPLOAD_FAILED');
export const CLEAR_PCB_UPLOAD = prefix('CLEAR_PCB_UPLOAD');

export const CLEAR = prefix('CLEAR');
