/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  itemsPagination,
  listLoadingProgress,
  loadingProgress,
  pcbFileLoadingProgress,
} from 'data/reducers/admin/gateways';

import createImmutableEqualSelector from '../../utils/immutable-selector';

const getState = (state) => state.adminGateways;

export const getEntities = (state) => getState(state).get('entities');
export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (gateways, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.gateway], new Map({ gateways })),
);

export const getItemsTotal = (state) => itemsPagination.getTotalItems(getState(state));
export const getItemsPerPage = (state) => itemsPagination.getItemsPerPage(getState(state));

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const getPcbLoadResult = (state) => getState(state).get('pcbLoadResult');
export const getPcbFileLoaded = (state) => pcbFileLoadingProgress.getLoaded(getState(state));
export const getPcbFileLoading = (state) => pcbFileLoadingProgress.getLoading(getState(state));
export const getPcbFileLoadFailed = (state) => pcbFileLoadingProgress.getLoadFailed(getState(state));
