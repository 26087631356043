/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/users';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const itemsPagination = new Pagination('user');
export const loadingProgress = new LoadingProgress('user');
export const listLoadingProgress = new LoadingProgress('userList');
export const resendingEmailProgress = new LoadingProgress('resendEmail');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { users },
    } = payload;
    newState.mergeIn(['entities'], fromJS(users));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total, lazy } = action.payload;
    mergeData(newState, action.payload);
    if (lazy) {
      newState.set('userPageItems', state.get('userPageItems').concat(fromJS(result)));
    } else {
      itemsPagination.set(newState, total, perPage, fromJS(result));
    }
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    loadingProgress.clear(newState);
    listLoadingProgress.clear(newState);
    itemsPagination.clear(newState);
    resendingEmailProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.RESEND_EMAIL_START:
      return resendingEmailProgress.setLoading(state);
    case constants.RESEND_EMAIL_FAILED:
      return resendingEmailProgress.setLoadFailed(state);
    case constants.RESEND_EMAIL_SUCCESS:
      return resendingEmailProgress.setLoaded(state);

    case constants.CLEAR:
      return clear(state);

    default:
      return state;
  }
};
