/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import objectFitImages from 'object-fit-images';
import Soundtalks from 'containers';
import { Provider, createStore, PersistGate, actions, ApiInterceptor, Sentry } from 'data';
import storage from 'utils/storage';
import AuthProvider from 'utils/authorization-provider';
import { FluidPreloader } from 'components/core/preloader';
import { GlobalErrorBoundary } from 'utils/error-boundaries';
import { BrowserTracing } from '@sentry/tracing';

import 'ui/styles/global.scss';

import { getLanguage } from 'utils/language-helpers';

import history from './history';

import './i18n';

const rootElement = document.getElementById('root');

const { store, persistor } = createStore({ storage, language: getLanguage() });

if (process.env.PROJECT_ENV !== 'development' && process.env.PROJECT_ENV) {
  Sentry.init({
    dsn: 'https://6aec399129a64cf4b9b6ede94537ce08@o238345.ingest.sentry.io/5219133',
    environment: process.env.PROJECT_ENV,
    // environment: 'development',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.05,
    normalizeDepth: 5,
    beforeSend: (event, hint) => {
      if (hint.originalException?.response?.status === 400) return null;
      return event;
    },
  });
  Sentry.setContext('language', navigator.language);
}

const onBeforeLift = async () => {
  await store.dispatch(actions.session.refreshSession());
  return true;
};

class Application extends React.Component {
  componentDidMount() {
    objectFitImages();
  }

  componentDidCatch(error, errorInfo) {
    // Sentry.captureException(error, {
    //   extra: {
    //     info: errorInfo,
    //   },
    // });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
          <ApiInterceptor history={history}>
            <Router history={history}>
              <AuthProvider>
                <GlobalErrorBoundary history={history}>
                  <Suspense fallback={<FluidPreloader style={{ height: '100vh' }} />}>
                    <Soundtalks />
                  </Suspense>
                </GlobalErrorBoundary>
              </AuthProvider>
            </Router>
          </ApiInterceptor>
        </PersistGate>
      </Provider>
    );
  }
}

render(<Application />, rootElement);
