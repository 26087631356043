/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';
import { Redirect } from 'react-router-dom';
import { actions, connect, selectors, ImmutablePropTypes } from 'data';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { registrationSuccessPath } from 'constants/urls';

import qs from 'qs';

import { WithTranslateFormErrors } from 'utils/translation-validation';
import { WithTurnstileCaptcha } from 'utils/turnstile-captcha';
import { RegistrationSchema } from 'utils/validation';
import { FluidPreloader } from 'components/core/preloader';

import * as Steps from 'components/shared/registration';

const mapStateToProps = (state) => ({
  countries: selectors.profile.getCountries(state),
  countriesLoaded: selectors.profile.getCountriesLoaded(state),
  languages: selectors.profile.getLanguages(state),
  languagesLoaded: selectors.profile.getLanguagesLoaded(state),
});

const mapDispatchToProps = {
  validate: actions.users.validate,
  register: actions.users.invite,
};

class RegistrationForm extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    validate: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    countries: PropTypes.instanceOf(Array),
    countriesLoaded: PropTypes.bool,
    handleSelectCountry: PropTypes.func.isRequired,
    languages: ImmutablePropTypes.list,
    languagesLoaded: PropTypes.bool,
  };

  static defaultProps = {
    countries: null,
    languages: null,
    countriesLoaded: false,
    languagesLoaded: false,
  };

  static pages = [Steps.Step1, Steps.Step2, Steps.Step3];

  state = {
    page: 0,
  };

  validateForm = async (values, formik) => {
    if (await this.props.validate(values, formik)) {
      this.next();
    }
  };

  next = () =>
    this.setState((state) => ({
      page: Math.min(state.page + 1, RegistrationForm.pages.length - 1),
    }));

  handleSubmit = async (values, formik) => {
    if (!(await this.props.register(values, formik))) {
      this.setState(() => ({ page: 0 }));
    }
  };

  render() {
    const { t } = this.props;
    const ActivePage = RegistrationForm.pages[this.state.page];

    return this.props.languagesLoaded && this.props.countriesLoaded ? (
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: qs.parse(this.props.location.search).email,
          password: '',
          telephone: '',
          language: '',
          country: '',
          timezone: null,
          token: qs.parse(this.props.location.search)['?token'],
          policy_accepted: false,
          consent_personal_data_usage: false,
          consent_newsletter: false,
        }}
        validationSchema={RegistrationSchema(t)}
        onSubmit={this.handleSubmit}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect
                to={this.props.location.state ? this.props.location.state.location.pathname : registrationSuccessPath}
              />
            );
          }
          return (
            <WithTurnstileCaptcha formName='registration' formikProps={formikProps}>
              <WithTranslateFormErrors {...formikProps}>
                <ActivePage
                  {...formikProps}
                  countries={this.props.countries.toJS()}
                  handleSelectCountry={this.props.handleSelectCountry}
                  languages={this.props.languages}
                  validateForm={this.validateForm}
                  next={this.next}
                />
              </WithTranslateFormErrors>
            </WithTurnstileCaptcha>
          );
        }}
      />
    ) : (
      <FluidPreloader />
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RegistrationForm));
