/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { schema } from 'normalizr';

export const user = new schema.Entity('users');

export const adminSite = new schema.Entity('adminSites');

export const availability = new schema.Entity('availabilities');

export const dataUsage = new schema.Entity('dataUsages');

export const notification = new schema.Entity('notifications');

export const pushNotification = new schema.Entity('pushNotifications');

export const alert = new schema.Entity('alerts');

export const message = new schema.Entity('messages');

export const loginActivity = new schema.Entity('loginActivities');

export const airspaceStatus = new schema.Entity('airspaceStatuses');

export const airspaceHistory = new schema.Entity(
  'airspaceHistories',
  {},
  {
    keyProperty: 'airspace_pk',
  },
);

export const veterinariansData = new schema.Entity('vetData');

export const airspace = new schema.Entity('airspaces');

export const device = new schema.Entity('devices');

export const gateway = new schema.Entity('gateways');

export const profile = new schema.Entity('profile');

export const currentSiteStatus = new schema.Entity('currentSiteStatuses');

export const site = new schema.Entity('sites', {
  status: currentSiteStatus,
  // NOTIFICATION: [notification],
  // ALERT: [alert],
  // CONVERSATION: [note],
});

export const siteStatus = new schema.Entity('siteStatuses');

export const group = new schema.Entity('groups');

export const adminGroup = new schema.Entity('adminGroups');

export const cough = new schema.Entity('coughs');

export const supplier = new schema.Entity('suppliers');

export const order = new schema.Entity('orders');

export const license = new schema.Entity('licenses');

export const licenseTemplate = new schema.Entity('licenseTemplates');

export const childLicense = new schema.Entity('childLicenses');

export const episode = new schema.Entity('episodes');

export const episodeDay = new schema.Entity('episodeDays');

export const logbookItem = new schema.Entity(
  'logbookItems',
  {},
  {
    idAttribute: 'date',
  },
);
