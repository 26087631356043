/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

const createButton = (buttonStyling) => {
  const Button = ({ className, children, onClick, isDisabled, ...props }) => (
    <button {...props} onClick={onClick} className={cx(s.button, buttonStyling, className)} disabled={isDisabled}>
      <span className={s.text}>{children}</span>
    </button>
  );

  Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['submit', 'button']),
    isDisabled: PropTypes.bool,
  };

  Button.defaultProps = {
    className: null,
    onClick: null,
    type: 'button',
    isDisabled: false,
  };

  return Button;
};

export const PrimaryButton = createButton(s.buttonPrimary);
export const Button = createButton(s.buttonSecondary);
export const FlatButton = createButton(s.buttonFlat);
