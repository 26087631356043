/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  listLoadingProgress,
  listPagination,
  ordersListLoadingProgress,
  ordersPagination,
  orderLoadingProgress,
  licenseTemplatesListLoadingProgress,
  licenseTemplatesPagination,
  licenseCreationLoadingProgress,
} from 'data/reducers/admin/licenses';

import createImmutableEqualSelector from '../../utils/immutable-selector';

const getState = (state) => state.adminLicenses;

// Licenses
export const getEntities = (state) => getState(state).get('entities');
export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (licenses, state) =>
  denormalize(listPagination.getPageItems(state), [schemas.license], new Map({ licenses })),
);

export const getItemsTotal = (state) => listPagination.getTotalItems(getState(state));
export const getItemsPerPage = (state) => listPagination.getItemsPerPage(getState(state));

export const getListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

// Orders
export const getOrdersEntities = (state) => getState(state).get('ordersEntities');

export const getOrdersItemsList = createImmutableEqualSelector(getOrdersEntities, getState, (orders, state) =>
  denormalize(ordersPagination.getPageItems(state), [schemas.order], new Map({ orders })),
);

export const getOrdersItemsTotal = (state) => ordersPagination.getTotalItems(getState(state));
export const getOrdersItemsPerPage = (state) => ordersPagination.getItemsPerPage(getState(state));

export const getOrdersListLoaded = (state) => ordersListLoadingProgress.getLoaded(getState(state));
export const getOrdersListLoading = (state) => ordersListLoadingProgress.getLoading(getState(state));
export const getOrdersListLoadFailed = (state) => ordersListLoadingProgress.getLoadFailed(getState(state));

// License templates

export const getLicenseTemplatesEntities = (state) => getState(state).get('licenseTemplateEntities');

export const getLicenseTemplatesItemsList = createImmutableEqualSelector(
  getLicenseTemplatesEntities,
  getState,
  (licenseTemplates, state) =>
    denormalize(
      licenseTemplatesPagination.getPageItems(state),
      [schemas.licenseTemplate],
      new Map({ licenseTemplates }),
    ),
);

export const getLicenseTemplatesItemsTotal = (state) => licenseTemplatesPagination.getTotalItems(getState(state));
export const getLicenseTemplatesItemsPerPage = (state) => licenseTemplatesPagination.getItemsPerPage(getState(state));

export const getLicenseTemplatesListLoaded = (state) => licenseTemplatesListLoadingProgress.getLoaded(getState(state));
export const getLicenseTemplatesListLoading = (state) =>
  licenseTemplatesListLoadingProgress.getLoading(getState(state));
export const getLicenseTemplatesListLoadFailed = (state) =>
  licenseTemplatesListLoadingProgress.getLoadFailed(getState(state));

// Single order

export const getOrder = (state, id) => getOrdersEntities(state).get(id);

export const getOrderLoaded = (state) => orderLoadingProgress.getLoaded(getState(state));
export const getOrderLoading = (state) => orderLoadingProgress.getLoading(getState(state));
export const getOrderLoadFailed = (state) => orderLoadingProgress.getLoadFailed(getState(state));

// License creation

export const getLicenseCreationLoading = (state) => licenseCreationLoadingProgress.getLoading(getState(state));
