/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { apiInstance as api } from 'data/store';
import saveBlob from './save-blob';

export const downloadFile = (file) => async () => {
  try {
    const { data: blob } = await api.get(file.source, {
      responseType: 'blob',
    });

    saveBlob(blob, file.caption);
  } catch (e) {
    console.error(e);
  }
};
