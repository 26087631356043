/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import FieldControl from './field-control';

class FormField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    form: PropTypes.shape({
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      setFieldValue: PropTypes.func,
      setFieldTouched: PropTypes.func,
      touched: PropTypes.object,
      errors: PropTypes.object,
    }).isRequired,
    useRawInput: PropTypes.bool,
    // onBlurAdapter: PropTypes.func,
    onChangeAdapter: PropTypes.func,
    valueAdapter: PropTypes.func,
    inputComponent: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isMulti: PropTypes.bool,
  };

  static defaultProps = {
    // onBlurAdapter: null,
    onChangeAdapter: null,
    valueAdapter: (value) => value,
    useRawInput: false,
    error: null,
    isMulti: false,
  };

  onChange = (...args) => {
    if (this.props.isMulti) {
      if (args[1].action === 'pop-value') return;
    }
    this.props.form.setFieldValue(this.props.field.name, this.props.onChangeAdapter(...args));
  };

  onBlur = () => {
    this.props.form.setFieldTouched(this.props.field.name, { touched: true });
  };

  extractFieldProps = () => {
    const {
      field,
      // eslint-disable-next-line
      form: { touched, errors },
      onChangeAdapter,
      inputComponent,
      useRawInput,
      error: propsError,
      ...props
    } = this.props;

    let error = null;

    if (propsError) {
      error = propsError;
    } else if (errors[field.name] && touched[field.name]) {
      error = errors[field.name];
    }

    const fieldProps = {
      ...field,
      error,
      value: this.props.valueAdapter(field.value),
      onBlur: this.onBlur,
      ...props,
    };

    if (this.props.onChangeAdapter) {
      if (process.env.PLATFORM === 'native') {
        fieldProps.onChangeText = this.onChange;
      } else {
        fieldProps.onChange = this.onChange;
      }
    }

    if (!this.props.useRawInput) {
      fieldProps.inputComponent = this.props.inputComponent;
    }

    return fieldProps;
  };

  render() {
    const FieldComponent = this.props.useRawInput ? this.props.inputComponent : FieldControl;
    return <FieldComponent {...this.extractFieldProps()} />;
  }
}

export default FormField;
