/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';

import PropTypes from 'prop-types';
import { connect, actions, selectors } from 'data';
import { withTranslation } from 'react-i18next';

import HelpPage from 'components/shared/help';
import { FluidPreloader } from 'components/core/preloader';

const mapStateToProps = (state) => ({
  helpInfo: selectors.session.getStaticPageContent(state),
  helpInfoLoaded: selectors.session.getStaticPageContentLoaded(state),
});

const mapDispatchToProps = {
  login: actions.session.fetchSession,
  fetchHelpInfo: actions.session.fetchPage,
};

class Help extends React.Component {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    fetchHelpInfo: PropTypes.func.isRequired,
    helpInfoLoaded: PropTypes.bool,
    helpInfo: PropTypes.shape({}),
  };

  static defaultProps = { helpInfoLoaded: false, helpInfo: null };

  componentDidMount() {
    this.props.fetchHelpInfo('help');
  }

  render() {
    return this.props.helpInfoLoaded ? <HelpPage data={this.props.helpInfo} /> : <FluidPreloader />;
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Help));
