/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { hot } from 'react-hot-loader/root';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isIE } from 'react-device-detect';

import loadable from '@loadable/component';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNetworkStatus } from 'utils/hooks';

import {
  basePath,
  baseAppPath,
  baseAdminPath,
  signInPath,
  notFoundPath,
  tooManyRequestsErrorPath,
} from 'constants/urls';

import { adminAccessRoles } from 'constants/app';

import { withAuth, Sentry } from 'data';
import { colors } from 'ui';
import AppShared from 'containers/shared';
import Modal from 'components/core/modal';
import ApiError from 'components/core/api-error';
import { FluidPreloader } from 'components/core/preloader';

const AppRoot = loadable(() => import('containers/app'), {
  fallback: <FluidPreloader style={{ height: '100vh' }} />,
});

const AdminRoot = loadable(() => import('containers/admin'), {
  fallback: <FluidPreloader style={{ height: '100vh' }} />,
});

const AuthorizedAppRoute = withAuth({ redirectTo: signInPath })(AppRoot);
const AuthorizedAdminRoute = withAuth({ redirectTo: signInPath, accessRoles: adminAccessRoles })(AdminRoot);

const Soundtalks = () => {
  const [isModalOpen, setModalOpen] = useState(window.navigator.userAgent.includes('MSIE') || isIE);

  const closeModal = () => {
    setModalOpen(false);
  };

  useNetworkStatus();

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | My Soundtalks">
        <meta name="theme-color" content={colors.primary} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      </Helmet>
      <Modal
        show={isModalOpen}
        closeButtonText="Ok"
        title={<span>Attention, the use of Internet Explorer is not supported</span>}
        onClose={closeModal}
        content={
          <div>
            <span>
              Sorry, the use of the application in Internet Explorer is no longer supported and therefore it is possible
              that the user experience is not optimal. We recommend to use another browser like Google Chrome or Edge.
              SoundTalks took the decision to abandon the support of Internet Explorer after Microsoft launched
              Microsoft Edge as new browser and decided to stop support for Internet Explorer in 2016.
            </span>
          </div>
        }
      />
      <ToastContainer />

      <Switch>
        <Route path={baseAppPath} component={AuthorizedAppRoute} />
        <Route path={baseAdminPath} component={AuthorizedAdminRoute} />
        <Route path={tooManyRequestsErrorPath} component={ApiError} />
        <Route path={notFoundPath} component={ApiError} />
        <Route path={basePath} component={AppShared} />
      </Switch>
    </React.Fragment>
  );
};

export default hot(Sentry.withProfiler(Soundtalks));
