/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/constants';

export const fetchSingle =
  () =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await Promise.all([api.get(`/sites/airspaces/product-types/`), api.get(`/sites/status/`)]);
      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: {
          types: normalize(res[0].data, [schema.siteType]),
          statuses: normalize(res[1].data, [schema.currentSiteStatus]),
        },
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const clear = () => ({ type: constants.CLEAR });
