/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { RouterPropTypes } from 'utils/prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseAppPath, baseAdminPath } from 'constants/urls';
import s from './styles.scss';

const getCode = (path) => {
  if (path.includes('forbidden')) return 403;
  if (path.includes('not-found')) return 404;
  if (path.includes('too-many-requests')) return 429;
  return 500;
};

const title = {
  403: 'error_forbidden_title',
  404: 'error_not_found_title',
  429: 'error_too_many_requests_title',
  500: 'error_server_title',
};

const description = {
  403: 'error_forbidden_description',
  404: 'error_not_found_description',
  429: 'error_too_many_requests_description',
  500: 'error_server_description',
};

const actions = {
  403: ['error_forbidden_action'],
  404: ['go_home_with_link'],
  500: ['try_again_in_5_min'],
};

const ApiError = ({ history }) => {
  const { t } = useTranslation();
  const errorCode = getCode(history.location.pathname);
  const homePath = history.location.pathname.includes('admin') ? baseAdminPath : baseAppPath;

  return (
    <div className={s.page}>
      <span className={s.pageHeader}>{t(title[errorCode])}</span>
      <span className={s.pageSubheader}>{t('error_with_code', { code: errorCode })}</span>

      <span className={s.pageDescription}>{t(description[errorCode])}</span>

      {actions[errorCode] && (
        <div className={s.pageActions}>
          <span className={s.pageActionsTitle}>{t('what_you_can_do')}:</span>
          {actions[errorCode].map((action) =>
            action === 'go_home_with_link' ? (
              <div className={s.pageActionsOption}>
                <Trans i18nKey="go_home_with_link">
                  <Link to={homePath} className={s.pageActionsOptionLink} />
                </Trans>
              </div>
            ) : (
              <span className={s.pageActionsOption}>{t(action)}</span>
            ),
          )}
        </div>
      )}

      <div className={s.pageHelp}>
        <Trans i18nKey="contact_us_if_you_need_help">
          <a href="mailto:info@soundtalks.com" className={s.pageHelpLink} />
        </Trans>
      </div>
    </div>
  );
};

ApiError.propTypes = {
  history: RouterPropTypes.history.isRequired,
};

export default ApiError;
