/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { colors, sizes, DarkInput } from 'ui';

import cx from 'classnames';

import flag from 'public/flags.png';

import s from './styles.scss';

const customStyles = (error = false) => ({
  container: (styles, { selectProps }) => ({
    ...styles,
    zIndex: selectProps.menuIsOpen ? 999 : 2,
    marginBottom: sizes.base / 4,
    marginTop: sizes.base / 4,
  }),
  control: (base, { selectProps, isFocused }) => ({
    ...base,
    backgroundColor: colors.background,
    borderColor: error ? colors.error : 'transparent',
    borderRightColor: error ? colors.error : colors.primary,
    borderRadius: '8px 0 0 8px',
    boxShadow: isFocused && !error ? `0 0 0 1px ${colors.white}` : 'none',
    minHeight: 50,
    outline: 'none',
    zIndex: selectProps.menuIsOpen ? 999 : 2,
    cursor: 'pointer',
    width: 80,

    '&:hover': {
      borderColor: error ? colors.error : 'transparent',
      borderRightColor: error ? colors.error : colors.primary,
    },
  }),
  dropdownIndicator: (base, { selectProps }) => ({
    ...base,
    color: selectProps.menuIsOpen ? colors.primary : colors.primary,
    padding: 0,
    marginRight: sizes.base / 4,
    width: 12,

    '&:hover': {
      color: colors.primary,
    },
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    cursor: 'pointer',
    zIndex: 100,
    backgroundColor: isSelected ? colors.primary : colors.background,
    color: isSelected ? colors.background : colors.primary,
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.background,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
    width: 288,
  }),
  input: (styles) => ({
    ...styles,
    color: colors.primary,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.primary,
    display: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors.primary,
    lineHeight: 1.2,
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
});

const Option = (props) => {
  const { data, getStyles } = props;

  return (
    <components.Option {...props} className={cx(getStyles('option', props))}>
      <div className={s.option} id={`option_${data.country_code}`}>
        <div
          className={cx(s.optionFlag, s[data.country_code.toLowerCase()])}
          style={{ backgroundImage: `url(${flag})` }}
        />
        <div className={s.optionLabel}>{`${data.name} +${data.phone_code}`}</div>
      </div>
    </components.Option>
  );
};

Option.propTypes = {
  getStyles: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

const SingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <div className={s.option}>
        <div
          className={cx(s.optionFlag, s[data.country_code.toLowerCase()])}
          style={{ backgroundImage: `url(${flag})` }}
        />
        <div className={s.optionLabel}>{`+${data.phone_code}`}</div>
      </div>
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

class PhoneInput extends React.Component {
  static type = {
    name: 'dark',
    style: {
      errorText: {
        color: colors.error,
      },
    },
  };

  static propTypes = {
    error: PropTypes.string,
    styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    placeholder: PropTypes.string,
    handleOnChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    styles: null,
    placeholder: null,
  };

  constructor(props) {
    super(props);
    this.state = { country: null, phoneInput: '' };
  }

  handleSelectCountry = (country) => {
    this.setState({ country, phoneInput: '' });
    this.props.handleOnChange('');
  };

  handleInputChange = (event) => {
    const re = /^[0-9\b]+$/;
    const { value } = event.target;
    if (value === '' || re.test(value)) {
      this.setState({ phoneInput: value });
      this.props.handleOnChange(`+${this.state.country.phone_code}${value}`);
    }
  };

  render() {
    const { error, styles, placeholder, ...props } = this.props;
    const { country, phoneInput } = this.state;

    return (
      <div className={s.phoneInput}>
        <Select
          {...props}
          styles={customStyles(error)}
          isSearchable={false}
          components={{ Option, SingleValue }}
          onChange={(v) => this.handleSelectCountry(v)}
          value={country}
        />
        <DarkInput
          disabled={!country}
          type="text"
          placeholder={placeholder}
          value={phoneInput}
          onChange={(v) => this.handleInputChange(v)}
          className={cx(s.phoneInputInput, error && s.phoneInputError)}
        />
      </div>
    );
  }
}

export default PhoneInput;
