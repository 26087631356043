/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Button, PrimaryButton } from 'ui';
import HeaderShared from 'components/shared/layout/header';
import { dataPrivacyPath } from 'constants/urls';
import logo from 'public/logo.png';
import Carousel from '../carousel';

import s from './styles.scss';

const Step2 = ({ next, setFieldValue }) => {
  const { t } = useTranslation();

  const handleClick = async (value) => {
    await setFieldValue('consent_personal_data_usage', value);
    next();
  };

  return (
    <div className={s.permissions}>
      <HeaderShared>
        <div className={s.permissionsLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <Carousel length={2} index={0}>
        <>
          <h1 className={s.permissionsTitle}>{t('consent_personal_data_usage_title')}</h1>
          <div className={s.permissionsBody}>
            <Trans i18nKey="consent_personal_data_usage_shared">
              <Link to={dataPrivacyPath} className={s.permissionLabelLink} />
            </Trans>
          </div>
          <div className={s.permissionsButtons}>
            <Button type="button" className={s.permissionsButtonsBtn} onClick={() => handleClick(false)}>
              {t('no_disagree')}
            </Button>
            <PrimaryButton type="button" className={s.permissionsButtonsBtn} onClick={() => handleClick(true)}>
              {t('yes_agree')}
            </PrimaryButton>
          </div>
        </>
      </Carousel>
    </div>
  );
};

Step2.propTypes = {
  next: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Step2;
