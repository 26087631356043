/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { useEffect } from 'react';

export const useKeyUp = (callback) => {
  useEffect(() => {
    document.addEventListener('keyup', callback);

    return () => {
      document.removeEventListener('keyup', callback);
    };
  });
};
