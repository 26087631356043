/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/airspaces';
import LoadingProgress from 'data/utils/reducers/loading';

export const listLoadingProgress = new LoadingProgress('airspaceList');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { airspaces },
    } = payload;
    newState.mergeIn(['entities'], fromJS(airspaces));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    newState.set('list', fromJS(action.payload.result));
    listLoadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
  list: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.CLEAR:
      return clear(state);

    default:
      return state;
  }
};
