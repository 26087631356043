/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/session/${type}`;

export const LOGIN = prefix('LOGIN');
export const LOGIN_SUCCESS = prefix('LOGIN_SUCCESS');
export const LOGIN_FAILED = prefix('LOGIN_FAILED');

export const LOGOUT = prefix('LOGOUT');

export const REFRESH = prefix('REFRESH');
export const REFRESH_SUCCESS = prefix('REFRESH_SUCCESS');
export const REFRESH_FAILED = prefix('REFRESH_FAILED');

export const RECOVER = prefix('RECOVER');
export const RECOVER_SUCCESS = prefix('RECOVER_SUCCESS');
export const RECOVER_FAILED = prefix('RECOVER_FAILED');

export const RECOVER_CONFIRM = prefix('RECOVER_CONFIRM');
export const RECOVER_CONFIRM_SUCCESS = prefix('RECOVER_CONFIRM_SUCCESS');
export const RECOVER_CONFIRM_FAILED = prefix('RECOVER_CONFIRM_FAILED');

export const CALLBACK = prefix('CALLBACK');
export const CALLBACK_SUCCESS = prefix('CALLBACK_SUCCESS');
export const CALLBACK_FAILED = prefix('CALLBACK_FAILED');

export const FETCH_START = prefix('FETCH_START');
export const FETCH_SUCCESS = prefix('FETCH_SUCCESS');
export const FETCH_FAILED = prefix('FETCH_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const FETCH_FILES_START = prefix('FETCH_FILES_START');
export const FETCH_FILES_SUCCESS = prefix('FETCH_FILES_SUCCESS');
export const FETCH_FILES_FAILED = prefix('FETCH_FILES_FAILED');

export const FETCH_HELP_FILES_START = prefix('FETCH_HELP_FILES_START');
export const FETCH_HELP_FILES_SUCCESS = prefix('FETCH_HELP_FILES_SUCCESS');
export const FETCH_HELP_FILES_FAILED = prefix('FETCH_HELP_FILES_FAILED');

export const FETCH_DATA_PRIVACY_START = prefix('FETCH_DATA_PRIVACY_START');
export const FETCH_DATA_PRIVACY_SUCCESS = prefix('FETCH_DATA_PRIVACY_SUCCESS');
export const FETCH_DATA_PRIVACY_FAILED = prefix('FETCH_DATA_PRIVACY_FAILED');

export const ACCEPT_DATA_PRIVACY_START = prefix('ACCEPT_DATA_PRIVACY_START');
export const ACCEPT_DATA_PRIVACY_SUCCESS = prefix('ACCEPT_DATA_PRIVACY_SUCCESS');
export const ACCEPT_DATA_PRIVACY_FAILED = prefix('ACCEPT_DATA_PRIVACY_FAILED');
