/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ContentBox from 'components/core/content-box';

import s from './styles.scss';

const Carousel = ({ children, length, index }) => (
  <div className={s.carousel}>
    <ContentBox>{children}</ContentBox>
    <div className={s.carouselProgress}>
      {[...Array(length).keys()].map((i) => (
        <div
          key={`carousel-item-${i}`}
          className={cx(
            s.carouselProgressDot,
            i < index && s.carouselProgressDotPrev,
            i > index && s.carouselProgressDotNext,
          )}
        />
      ))}
    </div>
  </div>
);

Carousel.propTypes = {
  length: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Carousel;
