/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as Yup from 'yup';

export const supportedImageFormats = ['image/jpg', 'image/jpeg', 'image/png'];
const nameRegEx = /^[\p{Letter}\p{Mark}\s-]+$/u;

export const LoginSchema = (t) =>
  Yup.object().shape({
    username: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .required(t('error_required', { what: t('email') })),
    password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .required(t('error_required', { what: t('password') })),
  });

export const ContactUsSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().max(180, t('error_too_long')),
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('email') })),
    message: Yup.string()
      .min(25, t('error_longer', { what: 'Message', count: '25' }))
      .required(t('error_required', { what: 'Message' })),
  });

export const RestorePasswordSchema = (t) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .required(t('error_required', { what: t('email') })),
  });

export const RecoverPasswordSchema = (t) =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('password') })),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], t('error_match'))
      .required(t('error_required', { what: t('password_confirm') })),
  });

export const ProfileGeneralSchema = (t) =>
  Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('error_longer', { what: t('first_name'), count: '2' }))
      .max(30, t('error_too_long'))
      .required(t('error_required', { what: t('first_name') }))
      .matches(nameRegEx, t('error_wrong_characters')),
    last_name: Yup.string()
      .min(2, t('error_longer', { what: t('last_name'), count: '2' }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('last_name') }))
      .matches(nameRegEx, t('error_wrong_characters')),
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .required(t('error_required', { what: t('email') })),
    telephone: Yup.string()
      .min(2, t('error_longer', { what: t('telephone'), count: '2' }))
      .max(20, t('error_too_long'))
      .required(t('error_required', { what: t('telephone') })),
    language: Yup.string().min(2, t('error_longer', { what: t('Language'), count: '4' })),
    timezone: Yup.string().min(2, t('error_longer', { what: t('timezone'), count: '2' })),
    temperature: Yup.string().min(2, t('error_longer', { what: t('temperature'), count: '2' })),
    profile_picture: Yup.mixed()
      .test('fileFormat', t('error_format'), (value) =>
        value && typeof value !== 'string' ? supportedImageFormats.includes(value.type) : true,
      )
      .test(
        'fileSize',
        t('error_too_large', { what: t('profile_picture') }),
        (value) => !value || typeof value === 'string' || value.size <= 5000000,
      ),
  });

export const GroupCreationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, t('error_longer', { what: t('name'), count: '2' }))
      .max(250, t('error_too_long'))
      .required(t('error_required', { what: t('name') })),
    linked_sites: Yup.array().of(Yup.number()).min(1, t('error_add_site')).required(t('error_link_site')),
  });

export const SiteUsersSchema = (t) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('error_invalid', { what: t('email_address') }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('email') })),
  });

export const NotesEditSchema = (t) =>
  Yup.object().shape({
    title: Yup.string()
      .min(2, t('error_longer', { what: t('name'), count: '2' }))
      .max(50, t('error_too_long'))
      .required(t('error_required', { what: t('name') })),
    description: Yup.string()
      .min(2, t('error_longer', { what: t('description'), count: '2' }))
      .max(2000, t('error_too_long'))
      .required(t('error_required', { what: t('description') })),
  });

// export const SiteCreationSchemaFirst = t =>
//   Yup.object().shape({
//     name: Yup.string()
//       .min(2, t('error_longer', { what: t('name'), count: '2' }))
//       .max(250, t('error_too_long'))
//       .required(t('error_required', { what: t('name') })),
//     type: Yup.string().required(t('error_required_simple')),
//     animals_quantity: Yup.string()
//       .matches(/^[0-9]*$/, t('error_wrong_characters'))
//       .max(250, t('error_too_long')),
//   });

export const SiteCreationSchemaFirst = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, t('error_longer', { what: t('name'), count: '2' }))
      .max(250, t('error_too_long'))
      .required(t('error_required', { what: t('name') })),
    country: Yup.string().nullable().required(t('error_required_simple')),
    // postal_code: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, t('error_wrong_characters')),
    // city: Yup.string(),
    // street: Yup.string(),
    // house_number: Yup.string().matches(/^[0-9]*$/, t('error_wrong_characters')),
    latitude: Yup.number().required(t('error_required_simple')),

    longitude: Yup.number().required(t('error_required_simple')),

    premise_id: Yup.string().max(50, t('error_too_long')),
  });

const NameSchema = (t) =>
  Yup.mixed().test('name', t('name_required'), (value) => !value || (typeof value === 'string' && value.trim()));

export const SiteCreationSchemaSecond = (t) =>
  Yup.object({
    houses: Yup.array()
      .of(
        Yup.object({
          name: NameSchema(t),
          children: Yup.array().of(
            Yup.object({
              name: NameSchema(t),
              children: Yup.array().of(
                Yup.object({
                  name: NameSchema(t),
                  children: Yup.array().of(
                    Yup.object({
                      name: NameSchema(t),
                    }),
                  ),
                }),
              ),
            }),
          ),
        }),
      )
      .required(t('error_required_simple')),
  });

export const SiteCreationSchemaThird = (t) =>
  Yup.object().shape({
    airspaces: Yup.array().of(
      Yup.object().shape({
        product_type: Yup.string().required(t('error_required_simple')),
      }),
    ),
  });

export const SiteCreationSchemaFourth = (t) =>
  Yup.object().shape({
    gateways: Yup.array().of(Yup.object()).required(t('error_required_simple')),
    houses: Yup.array()
      .of(
        Yup.object({
          children: Yup.array().of(
            Yup.object({
              children: Yup.array().of(
                Yup.object({
                  children: Yup.array().of(
                    Yup.object({
                      devices: Yup.array().of(Yup.object()).nullable().required(t('error_required_device')),
                    }),
                  ),
                }),
              ),
            }),
          ),
        }),
      )
      .required(t('error_required_simple')),
  });

export const RegistrationSchema = (t) =>
  Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .min(1, t('error_longer', { what: t('first_name'), count: '1' }))
      .max(30, t('error_too_long'))
      .required(t('error_required', { what: t('first_name') }))
      .matches(nameRegEx, t('error_wrong_characters')),
    last_name: Yup.string()
      .trim()
      .min(1, t('error_longer', { what: t('last_name'), count: '1' }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('last_name') }))
      .matches(nameRegEx, t('error_wrong_characters')),
    email: Yup.string()
      .email(t('error_invalid', { what: t('email_address') }))
      .max(150, t('error_too_long'))
      .required(t('error_required', { what: t('email') })),
    password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('password') })),
    telephone: Yup.string()
      .min(9, t('error_longer', { what: t('telephone'), count: '5' }))
      .max(20, t('error_too_long'))
      .required(t('error_required', { what: t('telephone') })),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], t('error_match'))
      .required(t('error_required', { what: t('password_confirm') })),
    timezone: Yup.string()
      .nullable()
      .required(t('error_required', { what: t('timezone') })),
    language: Yup.string()
      .nullable()
      .required(t('error_required', { what: t('language') })),
    country: Yup.string()
      .nullable()
      .required(t('error_required', { what: t('country') })),
  });

export const PasswordChangingSchema = (t) =>
  Yup.object().shape({
    old_password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('old_password') })),
    new_password: Yup.string()
      .min(8, t('error_longer', { what: t('password'), count: '8' }))
      .max(100, t('error_too_long'))
      .required(t('error_required', { what: t('new_password') })),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t('error_match'))
      .required(t('error_required', { what: t('new_password_confirm') })),
  });

export const SiteSuppliersSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t('error_longer', { what: t('name'), count: '1' }))
      .required(t('error_required', { what: t('name') })),
  });

export const OrderSchema = (t) =>
  Yup.object().shape({
    supplier: Yup.string()
      .required(t('error_required', { what: t('supplier') }))
      .nullable(),

    order_items: Yup.array()
      .of(
        Yup.object().shape({
          license_template_id: Yup.string()
            .required(t('error_required', { what: t('license_template_id') }))
            .nullable(),
        }),
      )
      .required(t('order_items')),
  });

export const BulkStatusSchema = (t) =>
  Yup.object().shape({
    delivery_status: Yup.string()
      .required(t('error_required', { what: t('target_status') }))
      .nullable(),

    deviceCodes: Yup.string()
      .required(t('error_required', { what: t('device_codes') }))
      .nullable(),
  });

export const TreatmentSchema = (t) =>
  Yup.object().shape({
    description: Yup.string()
      .required(t('error_required', { what: t('description') }))
      .trim(),
  });

export const StartBatchFirstSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .max(40, t('error_too_long'))
      .required(t('error_required', { what: t('name') }))
      .trim(),
    start_age_in_days: Yup.string()
      .required(t('error_required', { what: t('age') }))
      .matches(/^[1-9][0-9]*$/, t('error_only_numbers_positive')),
  });

export const StartBatchSecondSchema = (t) =>
  Yup.object().shape({
    airspaces: Yup.array()
      .of(Yup.number())
      .min(1, t('error_add_airspace'))
      .required(t('error_required', { what: t('airspace') })),
  });

export const BatchEditSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .max(40, t('error_too_long'))
      .required(t('error_required', { what: t('batch_name') }))
      .trim(),
  });
