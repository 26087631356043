/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/alerts';
import * as Sentry from '@sentry/react';

export const fetchList =
  (site, page = 1, perPage = 10, lazy = false, filter = '', sensorFilter, orderingField, order, airspace) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/alerts/`, {
        timeout: 2000,
        params: { page, perPage, filter, sensor_filter: sensorFilter, ordering_field: orderingField, order, airspace },
      });

      const payload = {
        lazy,
        ...res.data,
        ...normalize(res.data.items, [schema.alert]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
      return true;
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
      return e;
    }
  };

export const fetchSingle =
  (site, id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/alerts/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.alert),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
    }
  };

export const clear = () => ({ type: constants.CLEAR });
