/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { actions, connect } from 'data';
import { Formik } from 'formik';
import { contactUsSuccessPath } from 'constants/urls';
import ContactUsForm from 'components/shared/contact-us';

import { WithTranslateFormErrors } from 'utils/translation-validation';
import { WithTurnstileCaptcha } from 'utils/turnstile-captcha';
import { ContactUsSchema } from 'utils/validation';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  callBack: actions.session.contactUs,
};

/* eslint-disable react/prefer-stateless-function */
class ContactUs extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    callBack: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        validationSchema={ContactUsSchema(this.props.t)}
        onSubmit={this.props.callBack}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect
                to={this.props.location.state ? this.props.location.state.location.pathname : contactUsSuccessPath}
              />
            );
          }
          return (
            <WithTurnstileCaptcha formName="contact-us" formikProps={formikProps}>
              <WithTranslateFormErrors {...formikProps}>
                <ContactUsForm {...formikProps} />
              </WithTranslateFormErrors>
            </WithTurnstileCaptcha>
          );
        }}
      />
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUs));
