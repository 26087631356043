/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/devices/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const GATEWAYS_LOAD_START = prefix('GATEWAYS_LOAD_START');
export const GATEWAYS_LOAD_SUCCESS = prefix('GATEWAYS_LOAD_SUCCESS');
export const GATEWAYS_LOAD_FAILED = prefix('GATEWAYS_LOAD_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const COLOR_HISTORY_LOAD_START = prefix('COLOR_HISTORY_LOAD_START');
export const COLOR_HISTORY_LOAD_SUCCESS = prefix('COLOR_HISTORY_LOAD_SUCCESS');
export const COLOR_HISTORY_LOAD_FAILED = prefix('COLOR_HISTORY_LOAD_FAILED');

export const AVAILABILITY_LOAD_START = prefix('AVAILABILITY_LOAD_START');
export const AVAILABILITY_LOAD_SUCCESS = prefix('AVAILABILITY_LOAD_SUCCESS');
export const AVAILABILITY_LOAD_FAILED = prefix('AVAILABILITY_LOAD_FAILED');

export const DATA_USAGE_LOAD_START = prefix('DATA_USAGE_LOAD_START');
export const DATA_USAGE_LOAD_SUCCESS = prefix('DATA_USAGE_LOAD_SUCCESS');
export const DATA_USAGE_LOAD_FAILED = prefix('DATA_USAGE_LOAD_FAILED');

export const CONNECTIVITY_LOAD_START = prefix('CONNECTIVITY_LOAD_START');
export const CONNECTIVITY_LOAD_SUCCESS = prefix('CONNECTIVITY_LOAD_SUCCESS');
export const CONNECTIVITY_LOAD_FAILED = prefix('CONNECTIVITY_LOAD_FAILED');

export const REGISTERED_DEVICES_LOAD_START = prefix('REGISTERED_DEVICES_LOAD_START');
export const REGISTERED_DEVICES_LOAD_SUCCESS = prefix('REGISTERED_DEVICES_LOAD_SUCCESS');
export const REGISTERED_DEVICES_LOAD_FAILED = prefix('REGISTERED_DEVICES_LOAD_FAILED');

export const FARMS_LOAD_START = prefix('FARMS_LOAD_START');
export const FARMS_LOAD_SUCCESS = prefix('FARMS_LOAD_SUCCESS');
export const FARMS_LOAD_FAILED = prefix('FARMS_LOAD_FAILED');

export const ONLINE_DEVICES_LOAD_START = prefix('ONLINE_DEVICES_LOAD_START');
export const ONLINE_DEVICES_LOAD_SUCCESS = prefix('ONLINE_DEVICES_LOAD_SUCCESS');
export const ONLINE_DEVICES_LOAD_FAILED = prefix('ONLINE_DEVICES_LOAD_FAILED');

export const UNREGISTERED_DEVICES_LOAD_START = prefix('UNREGISTERED_DEVICES_LOAD_START');
export const UNREGISTERED_DEVICES_LOAD_SUCCESS = prefix('UNREGISTERED_DEVICES_LOAD_SUCCESS');
export const UNREGISTERED_DEVICES_LOAD_FAILED = prefix('UNREGISTERED_DEVICES_LOAD_FAILED');

export const RDI_LOAD_START = prefix('RDI_LOAD_START');
export const RDI_LOAD_SUCCESS = prefix('RDI_LOAD_SUCCESS');
export const RDI_LOAD_FAILED = prefix('RDI_LOAD_FAILED');

export const DEVICE_TEMPERATURE_HUMIDITY_LOAD_START = prefix('DEVICE_TEMPERATURE_HUMIDITY_LOAD_START');
export const DEVICE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS = prefix('DEVICE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS');
export const DEVICE_TEMPERATURE_HUMIDITY_LOAD_FAILED = prefix('DEVICE_TEMPERATURE_HUMIDITY_LOAD_FAILED');
export const CLEAR_DEVICES_TEMPERATURE_HUMIDITY = prefix('CLEAR_DEVICES_TEMPERATURE_HUMIDITY');

export const WEEK_KPI_LOAD_START = prefix('WEEK_KPI_LOAD_START');
export const WEEK_KPI_LOAD_SUCCESS = prefix('WEEK_KPI_LOAD_SUCCESS');
export const WEEK_KPI_LOAD_FAILED = prefix('WEEK_KPI_LOAD_FAILED');

export const MONTH_KPI_LOAD_START = prefix('MONTH_KPI_LOAD_START');
export const MONTH_KPI_LOAD_SUCCESS = prefix('MONTH_KPI_LOAD_SUCCESS');
export const MONTH_KPI_LOAD_FAILED = prefix('MONTH_KPI_LOAD_FAILED');

export const THREE_MONTH_KPI_LOAD_START = prefix('THREE_MONTH_KPI_LOAD_START');
export const THREE_MONTH_KPI_LOAD_SUCCESS = prefix('THREE_MONTH_KPI_LOAD_SUCCESS');
export const THREE_MONTH_KPI_LOAD_FAILED = prefix('THREE_MONTH_KPI_LOAD_FAILED');

export const COUGH_DATA_LOAD_START = prefix('COUGH_DATA_LOAD_START');
export const COUGH_DATA_LOAD_SUCCESS = prefix('COUGH_DATA_LOAD_SUCCESS');
export const COUGH_DATA_LOAD_FAILED = prefix('COUGH_DATA_LOAD_FAILED');

export const BULK_STATUS_CHANGE_START = prefix('BULK_STATUS_CHANGE_START');
export const BULK_STATUS_CHANGE_SUCCESS = prefix('BULK_STATUS_CHANGE_SUCCESS');
export const BULK_STATUS_CHANGE_FAILED = prefix('BULK_STATUS_CHANGE_FAILED');

export const CLEAR = prefix('CLEAR');
export const DATA_USAGE_CLEAR = prefix('DATA_USAGE_CLEAR');
export const AVAILABILITY_CLEAR = prefix('AVAILABILITY_CLEAR');
export const DEVICES_DASHBOARD_CLEAR = prefix('DEVICES_DASHBOARD_CLEAR');
export const RDI_CLEAR = prefix('RDI_CLEAR');
export const CLEAR_CHARTS = prefix('CLEAR_CHARTS');
export const CLEAR_KPI = prefix('CLEAR_KPI');
export const COUGH_DATA_CLEAR = prefix('COUGH_DATA_CLEAR');
