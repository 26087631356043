/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';

import PropTypes from 'prop-types';
import { connect, actions, selectors } from 'data';
import { withTranslation } from 'react-i18next';

import TermsAndConditionsPage from 'components/shared/terms-conditions';
import { FluidPreloader } from 'components/core/preloader';

const mapStateToProps = (state) => ({
  privacyLoaded: selectors.session.getPrivacyLoaded(state),
  privacy: selectors.session.getPrivacy(state),
});

const mapDispatchToProps = {
  fetchPrivacy: actions.session.fetchPrivacy,
};

class TermsAndConditions extends React.Component {
  static propTypes = {
    fetchPrivacy: PropTypes.func.isRequired,
    privacyLoaded: PropTypes.bool,
    privacy: PropTypes.shape({}),
    country: PropTypes.string.isRequired,
  };

  static defaultProps = { privacyLoaded: false, privacy: null };

  componentDidMount() {
    this.props.fetchPrivacy(this.props.country);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country !== this.props.country) {
      this.props.fetchPrivacy(this.props.country);
    }
  }

  render() {
    return this.props.privacyLoaded ? <TermsAndConditionsPage privacy={this.props.privacy} /> : <FluidPreloader />;
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions));
