/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/users/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');

export const KPI_LOAD_START = prefix('KPI_LOAD_START');
export const KPI_LOAD_SUCCESS = prefix('KPI_LOAD_SUCCESS');
export const KPI_LOAD_FAILED = prefix('KPI_LOAD_FAILED');

export const CLEAR_DASHBOARD = prefix('CLEAR_DASHBOARD');

export const CLEAR = prefix('CLEAR');
