/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const getState = (state) => state.siteCreation;
const getEntities = (state) => getState(state).get('entities');

export const getPage = (state) => getEntities(state).get('page');

export const getValues = (state) => getEntities(state).get('values');

export const getEditPage = (state) => getEntities(state).get('editPage');
