/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import * as constants from 'data/constants/session';
import LoadingProgress from 'data/utils/reducers/loading';

export const tokenLoading = new LoadingProgress('token');
export const refreshLoading = new LoadingProgress('refresh');
export const privacyLoading = new LoadingProgress('privacy');
export const dataPrivacyLoading = new LoadingProgress('dataPrivacy');
export const filesLoading = new LoadingProgress('files');
export const helpInfoLoading = new LoadingProgress('helpInfo');

const login = (state, action) =>
  state.withMutations((newState) => {
    const { access, refresh } = action.payload;
    newState.set('access', access);
    newState.set('refresh', refresh);
    tokenLoading.setLoaded(newState);
  });

const refresh = (state, action) =>
  state.withMutations((newState) => {
    const { access } = action.payload;
    newState.set('access', access);
    refreshLoading.setLoaded(newState);
  });

const privacyPolicy = (state, action) =>
  state.withMutations((newState) => {
    newState.set('privacy', action.payload);
    privacyLoading.setLoaded(newState);
  });

const dataPrivacyPolicy = (state, action) =>
  state.withMutations((newState) => {
    newState.set('dataPrivacy', action.payload);
    dataPrivacyLoading.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    tokenLoading.clear(newState);
    refreshLoading.clear(newState);
    newState.delete('access');
    newState.delete('refresh');
  });

const filesLoaded = (state, action) =>
  state.withMutations((newState) => {
    newState.set('files', action.payload);
    filesLoading.setLoaded(newState);
  });

const helpInfoLoaded = (state, action) =>
  state.withMutations((newState) => {
    newState.set('helpInfo', action.payload);
    helpInfoLoading.setLoaded(newState);
  });

const initialState = new Map({});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_START:
      return privacyLoading.setLoading(state);
    case constants.FETCH_FAILED:
      return privacyLoading.setLoadFailed(state);
    case constants.LOGIN:
      return tokenLoading.setLoading(state);
    case constants.LOGIN_FAILED:
      return tokenLoading.setLoadFailed(state);
    case constants.REFRESH:
      return refreshLoading.setLoading(state);
    case constants.REFRESH_FAILED:
      return refreshLoading.setLoadFailed(state);
    case constants.FETCH_DATA_PRIVACY_START:
      return dataPrivacyLoading.setLoading(state);
    case constants.FETCH_DATA_PRIVACY_FAILED:
      return dataPrivacyLoading.setLoadFailed(state);
    case constants.FETCH_SUCCESS:
      return privacyPolicy(state, action);
    case constants.FETCH_DATA_PRIVACY_SUCCESS:
      return dataPrivacyPolicy(state, action);
    case constants.LOGIN_SUCCESS:
      return login(state, action);
    case constants.REFRESH_SUCCESS:
      return refresh(state, action);
    case constants.LOGOUT:
      return clear(state);

    case constants.FETCH_FILES_START:
      return filesLoading.setLoading(state);
    case constants.FETCH_FILES_FAILED:
      return filesLoading.setLoadFailed(state);
    case constants.FETCH_FILES_SUCCESS:
      return filesLoaded(state, action);

    case constants.FETCH_HELP_FILES_START:
      return helpInfoLoading.setLoading(state);
    case constants.FETCH_HELP_FILES_FAILED:
      return helpInfoLoading.setLoadFailed(state);
    case constants.FETCH_HELP_FILES_SUCCESS:
      return helpInfoLoaded(state, action);

    default:
      return state;
  }
};
