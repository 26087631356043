/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { format, parseISO, differenceInCalendarDays } from 'date-fns';

export const formatLastConnection = (date) => {
  if (!date) {
    return '-';
  }

  let time = (new Date() - parseISO(date)) / 60000;
  if (time < 60) {
    const floorTime = Math.max(0, Math.floor(time));
    return floorTime === 1 ? `${floorTime} min` : `${floorTime} mins`;
  }
  time /= 60;
  if (time < 24) {
    const floorTime = Math.floor(time);
    return floorTime === 1 ? `${floorTime} hour` : `${floorTime} hours`;
  }
  time /= 24;
  const floorTime = Math.floor(time);
  return floorTime === 1 ? `${floorTime} day` : `${floorTime} days`;
};

export const chooseColor = (date) => {
  const time = (new Date() - parseISO(date)) / 60000;
  if (time < 60) return 'regular';
  if (time < 1440) return 'warning';
  return `alarm`;
};

export const getLicense = (date, t) => {
  if (!date) {
    return null;
  }
  const daysLeft = differenceInCalendarDays(parseISO(date), new Date()) + 1;
  return daysLeft > 0 ? daysLeft : t('expired_subscription');
};

export const getLicenseColor = (date) => {
  const daysLeft = differenceInCalendarDays(parseISO(date), new Date()) + 1;
  return daysLeft > 0 ? 'regular' : 'alarm';
};

const algoToProductType = {
  pig: ['fattening', 'wean to finish', 'sow', 'broilers'],
  piglet: ['nursery', 'wean to finish'],
};
export const getAlgoTypeColor = (productType, algoType) => {
  return (algoToProductType[(algoType ?? '').toLowerCase()] ?? []).includes((productType ?? '').toLowerCase())
    ? 'regular'
    : 'alarm';
};
