/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { set } from 'lodash-es';

const depths = ['floors', 'airspaces', 'decks'];
let depth = 0;

const transformDevices = (nodes, currentRootName, values) => {
  if (depth === -1) depth = 0;
  for (let i = 0; i < nodes.length; i += 1) {
    if (!nodes[i][depths[depth]]) {
      const formatedDevices = nodes[i].devices.map((device) => ({
        value: device.value || device.id,
        label: device.code || device.label,
        g_id: device.gateway_id || device.g_id,
        name: device.name,
      }));
      set(values, `${currentRootName}[${i}].devices`, formatedDevices);
    } else {
      // eslint-disable-next-line
      transformDevices(nodes[i][depths[depth++]], `${currentRootName}[${i}].${depths[depth - 1]}`, values);
    }
    if (i === nodes.length - 1) depth -= 1;
  }
};

export default transformDevices;
