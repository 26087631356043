/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/suppliers';
import * as Sentry from '@sentry/react';

export const fetchList =
  () =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/supplier/`);

      const payload = {
        ...res.data,
        ...normalize(res.data, [schema.supplier]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/supplier/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const add =
  (values, actions) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.ADD_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`/api/v2/admin-dashboard/supplier/`, values);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.ADD_SUCCESS, payload: normalize(res.data, schema.supplier) });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      // dispatch({ type: constants.ADD_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data });
      }
      actions.setSubmitting(false);
      if (e.response && e.response.data.name) {
        actions.setErrors({ name: e.response.data.name[0] });
      }

      return false;
    }
  };

export const remove =
  (supplierId, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DELETION_START });
    actions.setSubmitting(true);
    try {
      const res = await api.delete(`/api/v2/admin-dashboard/supplier/${supplierId}/`);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.DELETION_SUCCESS, payload: res.data });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      dispatch({ type: constants.DELETION_FAILED });
      if (e.response?.data?.message) {
        actions.setErrors({ deletionMessage: e.response.data.message });
      }
      actions.setSubmitting(false);
      if (e.response?.data?.name) {
        actions.setErrors({ name: e.response.data.name[0] });
      }
      return false;
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });

export const clearSites = () => ({ type: constants.SITES_CLEAR });

export const clearChildLicenses = () => ({ type: constants.CHILD_LICENSES_CLEAR });
