/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Roles } from 'data/constants/app';

import { getSessionActive } from 'data/selectors/session';
import { getItem, getItemLoaded, getItemLoading } from 'data/selectors/profile';
import { fetchSingle as fetchProfile, clear as clearProfile } from 'data/actions/profile';

import * as EntityPropType from 'data/utils/prop-types';

import AuthContext from './context';

const mapStateToProps = (state) => ({
  sessionActive: getSessionActive(state),
  profile: getItem(state),
  profileLoaded: getItemLoaded(state),
  profileLoading: getItemLoading(state),
});

const mapDispatchToProps = {
  clearProfile,
  fetchProfile,
};

class AuthorizedComponent extends React.Component {
  static contextType = AuthContext;

  static propTypes = {
    children: PropTypes.node.isRequired,
    role: PropTypes.string,
    redirectTo: PropTypes.string,
    preloader: PropTypes.node,
    sessionActive: PropTypes.bool,
    profile: ImmutablePropTypes.contains(EntityPropType.profile),
    profileLoaded: PropTypes.bool,
    profileLoading: PropTypes.bool,
    fetchProfile: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    accessRoles: PropTypes.shape([]),

    // clearProfile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sessionActive: null,
    profile: null,
    profileLoaded: false,
    profileLoading: false,
    role: null,
    redirectTo: null,
    preloader: null,
    exact: false,
    accessRoles: null,
  };

  static Roles = Roles;

  // static extractChildProps = props => {
  //   const componentProps = {};
  //   Object.keys(props).forEach(key => {
  //     if (!Object.prototype.hasOwnProperty.call(AuthorizedComponent.propTypes, key)) {
  //       componentProps[key] = props[key];
  //     }
  //   });
  //   return componentProps;
  // };

  componentDidMount() {
    if (this.props.sessionActive && !this.props.profileLoaded && !this.props.profileLoading) {
      this.props.fetchProfile();
    }
  }

  userHasAccessToSite() {
    if (!this.props.role) {
      return this.props.sessionActive;
    }
    const rolesHierarhy = Object.values(Roles);
    const userRoleVal = rolesHierarhy.indexOf(this.context.currentRole);
    const requiredRole = rolesHierarhy.indexOf(this.props.role);

    return this.props.exact ? userRoleVal === requiredRole : userRoleVal >= requiredRole;
  }

  userHasAccessToAdminPart() {
    return this.props.profile.get('roles').some((role) => this.props.accessRoles.includes(role));
  }

  render() {
    if (this.props.profile && this.props.accessRoles && !this.userHasAccessToAdminPart()) {
      if (this.props.redirectTo) {
        this.context.redirect(this.props.redirectTo);
      }
      return null;
    }

    if (this.props.sessionActive && this.userHasAccessToSite()) {
      return this.props.children;
    } else if (!this.props.sessionActive) {
      if (this.props.redirectTo) {
        this.context.redirect(this.props.redirectTo, { location: this.context.getLocation() });
      }
      return null;
    }

    return this.props.preloader;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedComponent);
