/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { colors } from 'ui';

import s from './styles.scss';

const createInput = (type) => {
  const Input = ({ className, error, ...props }) => {
    return (
      <input
        {...props}
        className={cx(s.input, className, s[`${type.name}Input`], error && s[`${type.name}InputError`])}
      />
    );
  };

  Input.type = type;

  Input.propTypes = {
    error: PropTypes.string,
    className: PropTypes.string,
  };

  Input.defaultProps = {
    error: null,
    className: null,
  };

  return Input;
};

const dark = {
  name: 'dark',
  style: {
    errorText: {
      color: colors.white,
    },
  },
};

const light = {
  name: 'light',
  style: {
    errorText: {
      color: colors.error,
    },
  },
};

export const DarkInput = createInput(dark);
export const LightInput = createInput(light);
