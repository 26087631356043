/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { DarkInput, PrimaryButton, FieldWrapper } from 'ui';
import { FluidPreloader } from 'components/core/preloader';
import { Form, Field } from 'formik';
import TextArea from 'components/core/text-area';
import Icon from 'components/core/icon';
import logo from 'public/logo.png';

import FooterShared from './layout/footer';
import HeaderShared from './layout/header';
import s from './styles.scss';

const ContactUs = ({ className, children, isSubmitting, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(s.sharedForm, className)}>
      <HeaderShared>
        <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <Form>
        <Field
          id='name'
          name='name'
          placeholder={t('name')}
          component={FieldWrapper}
          inputComponent={DarkInput}
          className={s.sharedFormField}
        />
        <Field
          id='email'
          name='email'
          placeholder={t('email')}
          component={FieldWrapper}
          inputComponent={DarkInput}
          className={s.sharedFormField}
        />
        <Field
          id='message'
          name='message'
          placeholder={t('your_message')}
          component={TextArea}
          className={s.sharedFormTextField}
          wrapperClassName={s.sharedFormField}
          error={(props.errors && (props.errors.detail || props.errors.message || null)) || null}
          errorColor={s.errorColor}
        />
        <PrimaryButton
          type='submit'
          className={s.sharedFormSubmit}
          onClick={props.handleSubmit}
          isDisabled={isSubmitting}
        >
          {isSubmitting ? <FluidPreloader light /> : <>{t('send')}</>}
        </PrimaryButton>
      </Form>
      <FooterShared className='singleLink'>
        <Link to='/sign-in' className={s.sharedFormFooterLink}>
          <Icon className={s.sharedFormFooterLinkIcon} icon={Icon.icons.arrowLeft} />
          <div className={s.sharedFormFooterLinkText}>{t('back')}</div>
        </Link>
      </FooterShared>
    </div>
  );
};

ContactUs.defaultProps = {
  className: null,
  children: null,
  errors: null,
};

ContactUs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  errors: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default ContactUs;
