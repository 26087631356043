/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/users';
import * as Sentry from '@sentry/react';

export const fetchList =
  (site, page = 1, perPage = 10, lazy = false, q = '', orderingField, order) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/users/`, {
        params: { page, perPage, q, ordering_field: orderingField, order },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.user]),
        lazy,
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (site, id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/users/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.user),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const add =
  (email, siteId, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CREATION_START });
    actions?.setSubmitting(true);
    try {
      const res = await api.post(`api/v1/sites/${siteId}/users/`, {
        email,
      });
      if (res.status !== 200) throw new Error(res.statusText);
      actions?.setSubmitting(false);
      actions?.resetForm();
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.CREATION_FAILED });
      if (e.response && e.response.data) {
        actions?.setErrors({ email: e.response.data.message });
      }
      actions?.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const validate =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.REGISTER_VALIDATION_START });
    try {
      const res = await api.post(`/users/validate/`, values);
      dispatch({ type: constants.REGISTER_VALIDATION_SUCCESS });
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.REGISTER_VALIDATION_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors(e.response.data);
      }
      Sentry.captureException(e);
      return false;
    }
  };

export const invite =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.REGISTER_START });
    actions.setSubmitting(true);
    try {
      await api.post(`/users/`, values);
      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.REGISTER_SUCCESS });
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.REGISTER_FAILED });
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const resendEmail =
  (email) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RESEND_EMAIL_START });
    try {
      await api.post(`/users/resend_activation_mail/`, { email });
      dispatch({ type: constants.RESEND_EMAIL_SUCCESS });
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.RESEND_EMAIL_FAILED });
      Sentry.captureException(e);
      return false;
    }
  };

export const remove =
  (siteId, userId, onErrorCallback) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DELETION_START });
    try {
      const res = await api.delete(`/sites/${siteId}/users/${userId}/`);
      if (res.status !== 200) throw new Error(res.statusText);
      dispatch({ type: constants.DELETION_SUCCESS });
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.DELETION_FAILED });
      onErrorCallback(e.response.data.message);
      Sentry.captureException(e);
      return false;
    }
  };

export const edit =
  (siteId, userId, values) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.EDIT_START });
    try {
      const res = await api.patch(`/sites/${siteId}/users/${userId}/`, values);
      if (res.status !== 200) throw new Error(res.statusText);
      dispatch({ type: constants.EDIT_SUCCESS });
      return true;
    } catch (e) {
      console.error(e.response.data);
      dispatch({ type: constants.EDIT_FAILED });
      Sentry.captureException(e);
      return false;
    }
  };

export const clear = () => ({ type: constants.CLEAR });
