/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/groups';
import LoadingProgress from 'data/utils/reducers/loading';

export const loadingProgress = new LoadingProgress('group');
export const listLoadingProgress = new LoadingProgress('groupList');

export const createProgress = new LoadingProgress('group', {
  loading: 'Creating',
  loaded: 'Created',
  loadFailed: 'CreationFailed',
});
export const updateProgress = new LoadingProgress('group', {
  loading: 'Updating',
  loaded: 'Updated',
  loadFailed: 'UpdateFailed',
});
export const deleteProgress = new LoadingProgress('group', {
  loading: 'Deleting',
  loaded: 'Deleted',
  loadFailed: 'DeleteFailed',
});

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { groups },
    } = payload;
    newState.mergeIn(['entities'], fromJS(groups));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    newState.set('list', fromJS(action.payload.result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(state, action.payload);
    loadingProgress.setLoaded(newState);
  });

const createSuccess = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    newState.update('list', (list) => list.push(String(action.payload.result)));
    createProgress.setLoaded(newState);
  });

const updateSuccess = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    updateProgress.setLoaded(newState);
  });

const deleteSuccess = (state, action) =>
  state.withMutations((newState) => {
    const { id } = action.payload;
    newState.deleteIn(['entities', id]);
    newState.set(
      'list',
      newState.get('list').filter((value) => value !== id),
    );

    deleteProgress.setLoaded(newState);
  });

const initialState = fromJS({
  list: [],
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.CREATION_START:
      return createProgress.setLoading(state);
    case constants.CREATION_FAILED:
      return createProgress.setLoadFailed(state);
    case constants.CREATION_SUCCESS:
      return createSuccess(state, action);
    case constants.CREATION_CLEAR:
      return createProgress.clear(state);
    case constants.EDIT_START:
      return updateProgress.setLoading(state);
    case constants.EDIT_FAILED:
      return updateProgress.setLoadFailed(state);
    case constants.EDIT_SUCCESS:
      return updateSuccess(state, action);
    case constants.EDIT_CLEAR:
      return updateProgress.clear(state);
    case constants.DELETION_START:
      return deleteProgress.setLoading(state);
    case constants.DELETION_FAILED:
      return deleteProgress.setLoadFailed(state);
    case constants.DELETION_SUCCESS:
      return deleteSuccess(state, action);
    case constants.DELETION_CLEAR:
      return deleteProgress.clear(state);
    default:
      return state;
  }
};
