/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './styles.scss';

const ReadIndicator = ({ read }) => {
  return <div className={cx(s.round, read ? s.read : s.unread)} />;
};

ReadIndicator.propTypes = {
  read: PropTypes.bool,
};

ReadIndicator.defaultProps = {
  read: false,
};

export { ReadIndicator };
