/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/airspaces';
import * as Sentry from '@sentry/react';

export const fetchList =
  (site) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/sites/${site.id}/airspaces/`, {
        params: site.simple ? { simple: site.simple } : {},
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.airspace]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });
