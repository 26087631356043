/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  itemsPagination,
  gatewaysItemsPagination,
  gatewaysListLoadingProgress,
} from 'data/reducers/devices';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = (state) => state.devices;

export const getEntities = (state) => getState(state).get('entities');
export const getGatewaysEntities = (state) => getState(state).get('gateways');

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (devices, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.device], new Map({ devices })),
);

export const getGatewaysItemsList = createImmutableEqualSelector(getGatewaysEntities, getState, (gateways, state) =>
  denormalize(gatewaysItemsPagination.getPageItems(state), [schemas.gateway], new Map({ gateways })),
);

export const getAirspaceItems = (state, airspaceId) =>
  getItemsList(state)?.filter((item) => item.getIn(['airspace', 'id']) === Number(airspaceId));

export const getItemsTotal = (state) => itemsPagination.getTotalItems(getState(state));
export const getItemsPerPage = (state) => itemsPagination.getItemsPerPage(getState(state));
export const getGatewaysItemsTotal = (state) => gatewaysItemsPagination.getTotalItems(getState(state));
export const getGatewaysItemsPerPage = (state) => gatewaysItemsPagination.getItemsPerPage(getState(state));

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));
export const getGatewaysItemsListLoaded = (state) => gatewaysListLoadingProgress.getLoaded(getState(state));
export const getGatewaysItemsListLoading = (state) => gatewaysListLoadingProgress.getLoading(getState(state));
export const getGatewaysItemsListLoadFailed = (state) => gatewaysListLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const getItem = (state, id) => getEntities(state).get(id);
