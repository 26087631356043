/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/admin/groups/${type}`;

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const ADD_START = prefix('ADD_START');
export const ADD_SUCCESS = prefix('ADD_SUCCESS');
export const ADD_FAILED = prefix('ADD_FAILED');

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const DELETION_START = prefix('DELETION_START');
export const DELETION_SUCCESS = prefix('DELETION_SUCCESS');
export const DELETION_FAILED = prefix('DELETION_FAILED');

export const LINK_AIRSPACES_START = prefix('LINK_AIRSPACES_START');
export const LINK_AIRSPACES_SUCCESS = prefix('LINK_AIRSPACES_SUCCESS');
export const LINK_AIRSPACES_FAILED = prefix('LINK_AIRSPACES_FAILED');

export const CLOSE_START = prefix('CLOSE_START');
export const CLOSE_SUCCESS = prefix('CLOSE_SUCCESS');
export const CLOSE_FAILED = prefix('CLOSE_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
