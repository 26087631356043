/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { signInPath } from 'constants/urls';
import { RouterPropTypes } from 'utils/prop-types';
import { PrimaryButton } from 'ui';
import logo from 'public/logo.png';

import HeaderShared from './layout/header';
import SubTitleShared from './layout/subtitle';
import s from './styles.scss';

const RestorePasswordSuccess = ({ className, history }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(s.sharedForm, className)}>
      <HeaderShared>
        <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <SubTitleShared>{t('reset_password_success')}</SubTitleShared>
      <PrimaryButton className={s.sharedFormSubmit} onClick={() => history.push(signInPath)}>
        {t('to_login')}
      </PrimaryButton>
    </div>
  );
};

RestorePasswordSuccess.defaultProps = {
  className: null,
};

RestorePasswordSuccess.propTypes = {
  className: PropTypes.string,
  history: RouterPropTypes.history.isRequired,
};

export default withRouter(RestorePasswordSuccess);
