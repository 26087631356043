/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'ui/constants';
import Text from '../text';

import s from './styles.scss';

const statusColors = {
  green: colors.success,
  blue: colors.statusBlue,
};

const GatewayStatus = ({ status, color }) => (
  <div className={s.wrapper}>
    <Text
      size={Text.size.s}
      weight={Text.weight.medium}
      className={s.status}
      style={{ backgroundColor: statusColors[color] || color }}
    >
      {status}
    </Text>
  </div>
);

GatewayStatus.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default GatewayStatus;
