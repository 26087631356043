/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/suppliers';
import LoadingProgress from 'data/utils/reducers/loading';

export const loadingProgress = new LoadingProgress('adminSuppliers');
export const listLoadingProgress = new LoadingProgress('adminSuppliersList');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { suppliers },
    } = payload;
    newState.mergeIn(['entities'], fromJS(suppliers));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    newState.set('list', fromJS(action.payload.result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    newState.set('sitesSupplier', fromJS(action.payload));
    loadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
  });

const clearSites = (state) =>
  state.withMutations((newState) => {
    sitesListLoadingProgress.clear(newState);
  });

const clearChildren = (state) =>
  state.withMutations((newState) => {
    childrenSuppliersLoadingProgress.clear(newState);
  });

const clearSingle = (state) =>
  state.withMutations((newState) => {
    newState.set('sitesSupplier', null);
    loadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
  sites: {},
  childLicenses: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);

    case constants.CLEAR:
      return clear(state);

    case constants.CLEAR_SINGLE:
      return clearSingle(state);

    case constants.SITES_CLEAR:
      return clearSites(state);

    case constants.CHILD_LICENSES_CLEAR:
      return clearChildren(state);

    default:
      return state;
  }
};
