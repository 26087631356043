/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/profile/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const SETTINGS_LOAD_START = prefix('SETTINGS_LOAD_START');
export const SETTINGS_LOAD_SUCCESS = prefix('SETTINGS_LOAD_SUCCESS');
export const SETTINGS_LOAD_FAILED = prefix('SETTINGS_LOAD_FAILED');

export const SETTINGS_UPDATE_START = prefix('SETTINGS_UPDATE_START');
export const SETTINGS_UPDATE_SUCCESS = prefix('SETTINGS_UPDATE_SUCCESS');
export const SETTINGS_UPDATE_FAILED = prefix('SETTINGS_UPDATE_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const CLEAR = prefix('CLEAR');

export const CHANGE_PASSWORD_SUCCESS = prefix('CHANGE_PASSWORD_SUCCESS');
export const CHANGE_PASSWORD_FAILED = prefix('CHANGE_PASSWORD_FAILED');

export const FAQ_LOAD_START = prefix('FAQ_LOAD_START');
export const FAQ_LOAD_SUCCESS = prefix('FAQ_LOAD_SUCCESS');
export const FAQ_LOAD_FAILED = prefix('FAQ_LOAD_FAILED');

export const CHANGE_LANGUAGE = prefix('CHANGE_LANGUAGE');

export const LANGUAGE_LOAD_START = prefix('LANGUAGE_LOAD_START');
export const LANGUAGE_LOAD_SUCCESS = prefix('LANGUAGE_LOAD_SUCCESS');
export const LANGUAGE_LOAD_FAILED = prefix('LANGUAGE_LOAD_FAILED');

export const COUNTRIES_LOAD_START = prefix('COUNTRIES_LOAD_START');
export const COUNTRIES_LOAD_SUCCESS = prefix('COUNTRIES_LOAD_SUCCESS');
export const COUNTRIES_LOAD_FAILED = prefix('COUNTRIES_LOAD_FAILED');

export const SET_SHOW_SITE_REGISTRATION_CONFIRM = prefix('SET_SHOW_SITE_REGISTRATION_CONFIRM');
