/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import AuthorizedComponent from './authorized';

const withAuth =
  ({ redirectTo = false, preloader = null, role = null, accessRoles = null }) =>
  (Component) =>
  (props) =>
    (
      <AuthorizedComponent redirectTo={redirectTo} preloader={preloader} role={role} accessRoles={accessRoles}>
        <Component {...props} />
      </AuthorizedComponent>
    );

withAuth.Roles = AuthorizedComponent.Roles;

export default withAuth;
