/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'components/core/icon';

import s from './styles.scss';

const Checkbox = ({ className, id, name, label, dark, onClick, ...props }) => (
  <label {...props} className={cx(s.checkboxWrapper, className)} htmlFor={id || name}>
    <input
      {...props}
      onClick={onClick}
      name={name}
      type="checkbox"
      id={id || name}
      className={cx(s.checkbox, s.checkboxInput)}
    />
    <div className={cx(s.checkboxFakebox, s.checkbox, dark && s.dark, props.checked && s.checkboxChecked)}>
      <Icon icon={Icon.icons.check} className={cx(s.checkboxIcon, props.checked && s.checkboxIconChecked)} />
    </div>
    <div className={s.checkboxLabelText}>{label}</div>
  </label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  dark: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: null,
  checked: false,
  id: null,
  onClick: null,
  dark: false,
};

export default Checkbox;
