/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  updateProgress,
  deleteProgress,
  createProgress,
} from 'data/reducers/groups';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = (state) => state.groups;

export const getEntities = (state) => getState(state).get('entities');

export const getItemsList = () =>
  createImmutableEqualSelector([getEntities, getState], (groups, state) =>
    denormalize(state.get('list'), [schemas.group], new Map({ groups })),
  );

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const updateItemLoaded = (state) => updateProgress.getLoaded(getState(state));
export const updateItemLoading = (state) => updateProgress.getLoading(getState(state));
export const updateItemLoadFailed = (state) => updateProgress.getLoadFailed(getState(state));

export const createItemLoaded = (state) => createProgress.getLoaded(getState(state));
export const createItemLoading = (state) => createProgress.getLoading(getState(state));
export const createItemLoadFailed = (state) => createProgress.getLoadFailed(getState(state));

export const deleteItemLoaded = (state) => deleteProgress.getLoaded(getState(state));
export const deleteItemLoading = (state) => deleteProgress.getLoading(getState(state));
export const deleteItemLoadFailed = (state) => deleteProgress.getLoadFailed(getState(state));

export const getItem = (state, id) => getEntities(state).get(id);
