/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export const googleMapsKey = 'AIzaSyAnWJSarkqgNTx5ocG3PjsFUKrCNCuAnaQ';
export const autoUpdate = true;
export const baiduMap = true;
export const i18nBackendLoadPath = '/public/locales/{{lng}}/{{ns}}.json';
export const baseURL = 'https://api.soundtalks.cn';
export const captchaKey = '0x4AAAAAAAC1jBPUWkZ704Z9';
export const isChinaConfig = true;
export const reversedFullName = true;
export const apkDownloadUrl = 'https://apk.soundtalks.cn/';
