/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { googlePlayLink } from 'constants/urls';

import cn from 'public/google-play/cn.png';
import de from 'public/google-play/de.png';
import enUS from 'public/google-play/en-us.png';
import es from 'public/google-play/es.png';
import fr from 'public/google-play/fr.png';
import pl from 'public/google-play/pl.png';
import pt from 'public/google-play/pt.png';
import th from 'public/google-play/th.png';
import nl from 'public/google-play/nl.png';
import vi from 'public/google-play/vi.png';
import ja from 'public/google-play/ja.png';
import it from 'public/google-play/it.png';

import s from './styles.scss';

const icons = {
  cn,
  de,
  'en-us': enUS,
  es,
  fr,
  pl,
  pt,
  th,
  nl,
  vi,
  ja,
  it,
};

const GooglePlayBadge = ({ language }) => (
  <a className={s.badge} href={googlePlayLink} target="_blank" rel="noopener noreferrer">
    <img className={s.badgeImage} src={icons[language] || enUS} alt="Google play" />
  </a>
);

GooglePlayBadge.propTypes = {
  language: PropTypes.string,
};

GooglePlayBadge.defaultProps = {
  language: 'en-us',
};

export default GooglePlayBadge;
