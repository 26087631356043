/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/profile';
import LoadingProgress from 'data/utils/reducers/loading';

export const loadingAdminCountriesProgress = new LoadingProgress('adminCountries');

const initialState = fromJS({
  countries: [],
});

const countriesLoaded = (state, action) =>
  state.withMutations((newState) => {
    const data = action.payload;
    loadingAdminCountriesProgress.setLoaded(newState);
    newState.set('countries', fromJS(data));
  });

const clearCountries = (state) =>
  state.withMutations((newState) => {
    loadingAdminCountriesProgress.clear(newState);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.COUNTRIES_LOAD_START:
      return loadingAdminCountriesProgress.setLoading(state);
    case constants.COUNTRIES_LOAD_SUCCESS:
      return countriesLoaded(state, action);
    case constants.COUNTRIES_LOAD_FAILED:
      return loadingAdminCountriesProgress.setLoadFailed(state);

    case constants.COUNTRIES_CLEAR:
      return clearCountries(state);
    default:
      return state;
  }
};
