/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { loadingAdminCountriesProgress } from 'data/reducers/admin/profile';

const getState = (state) => state.adminProfile;

export const getCountries = (state) => getState(state).get('countries');
export const getCountriesLoaded = (state) => loadingAdminCountriesProgress.getLoaded(getState(state));
export const getCountriesLoading = (state) => loadingAdminCountriesProgress.getLoading(getState(state));
export const getCountriesFailed = (state) => loadingAdminCountriesProgress.getLoadFailed(getState(state));
