/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/profile';
import LoadingProgress from 'data/utils/reducers/loading';

export const loadingProgress = new LoadingProgress('profile');
export const loadingFaqProgress = new LoadingProgress('faq');
export const loadingLanguagesProgress = new LoadingProgress('languages');
export const loadingCountriesProgress = new LoadingProgress('countries');
export const settingsLoadingProgress = new LoadingProgress('settings');

const initialState = fromJS({ showSiteRegistrationConfirm: false });

const clearSingle = (state) =>
  state.withMutations((newState) => {
    loadingProgress.clear(newState);
  });

const clearSingleStatus = (state) =>
  state.withMutations((newState) => {
    loadingProgress.clear(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    clearSingle(newState);
    clearSingleStatus(newState);
    loadingProgress.clear(newState);
  });

const profileLoaded = (state, action) =>
  state.withMutations((newState) => {
    const user = action.payload;
    loadingProgress.setLoaded(newState);
    newState.set('profile', fromJS(user));
  });

const profileFaqLoaded = (state, action) =>
  state.withMutations((newState) => {
    const data = action.payload;
    loadingFaqProgress.setLoaded(newState);
    newState.set('faq', fromJS(data));
  });

const profileLanguagesLoaded = (state, action) =>
  state.withMutations((newState) => {
    const data = action.payload;
    loadingLanguagesProgress.setLoaded(newState);
    newState.set('languages', fromJS(data));
  });

const countriesLoaded = (state, action) =>
  state.withMutations((newState) => {
    const data = action.payload;
    loadingCountriesProgress.setLoaded(newState);
    newState.set('countries', fromJS(data));
  });

const siteRegistrationConfirmStatus = (state, action) =>
  state.withMutations((newState) => {
    newState.set('showSiteRegistrationConfirm', action.payload);
  });

const loadSettings = (state, action) =>
  state.withMutations((newState) => {
    const settings = action.payload;
    settingsLoadingProgress.setLoaded(newState);
    newState.set('settings', fromJS(settings));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.CLEAR:
      return clear(state);
    case constants.UPDATE_SUCCESS:
    case constants.LOAD_SUCCESS:
      return profileLoaded(state, action);
    case constants.FAQ_LOAD_SUCCESS:
      return profileFaqLoaded(state, action);

    case constants.SETTINGS_LOAD_START:
    case constants.SETTINGS_UPDATE_START:
      return settingsLoadingProgress.setLoading(state);
    case constants.SETTINGS_LOAD_FAILED:
    case constants.SETTINGS_UPDATE_FAILED:
      return settingsLoadingProgress.setLoadFailed(state);
    case constants.SETTINGS_LOAD_SUCCESS:
    case constants.SETTINGS_UPDATE_SUCCESS:
      return loadSettings(state, action);

    case constants.LANGUAGE_LOAD_START:
      return loadingLanguagesProgress.setLoading(state);
    case constants.LANGUAGE_LOAD_SUCCESS:
      return profileLanguagesLoaded(state, action);
    case constants.LANGUAGE_LOAD_FAILED:
      return loadingLanguagesProgress.setLoadFailed(state);

    case constants.COUNTRIES_LOAD_START:
      return loadingCountriesProgress.setLoading(state);
    case constants.COUNTRIES_LOAD_SUCCESS:
      return countriesLoaded(state, action);
    case constants.COUNTRIES_LOAD_FAILED:
      return loadingCountriesProgress.setLoadFailed(state);

    case constants.SET_SHOW_SITE_REGISTRATION_CONFIRM:
      return siteRegistrationConfirmStatus(state, action);

    default:
      return state;
  }
};
