/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { DarkInput, PrimaryButton, FieldWrapper, GeneralFormError } from 'ui';
import { FluidPreloader } from 'components/core/preloader';
import { Form, Field } from 'formik';
import Icon from 'components/core/icon';
import logo from 'public/logo.png';

import FooterShared from './layout/footer';
import HeaderShared from './layout/header';
import SubTitleShared from './layout/subtitle';
import s from './styles.scss';

const RecoverPassword = ({ className, children, isSubmitting, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(s.sharedForm, className)}>
      <HeaderShared>
        <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <SubTitleShared>{t('enter_new_password')}</SubTitleShared>
      <Form>
        <Field
          id="password"
          name="password"
          placeholder={t('password')}
          secureTextEntry
          component={FieldWrapper}
          inputComponent={DarkInput}
          className={s.sharedFormField}
        />
        <Field
          id="confirm_password"
          name="confirm_password"
          placeholder={t('confirm_new_password')}
          secureTextEntry
          component={FieldWrapper}
          inputComponent={DarkInput}
          className={s.sharedFormField}
        />
        <PrimaryButton
          type="submit"
          className={s.sharedFormSubmit}
          onClick={props.handleSubmit}
          isDisabled={isSubmitting}
        >
          {isSubmitting ? <FluidPreloader light /> : <>{t('recover_password')}</>}
        </PrimaryButton>
        <GeneralFormError error={props.errors.detail} />
      </Form>
      <FooterShared className="singleLink">
        <Link to="/#" className={s.sharedFormFooterLink}>
          <Icon className={s.sharedFormFooterLinkIcon} icon={Icon.icons.arrowLeft} />
          {t('back')}
        </Link>
        <Link to="/contact-us" className={s.sharedFormFooterLink}>
          {t('contact_us')}
        </Link>
      </FooterShared>
    </div>
  );
};

RecoverPassword.defaultProps = {
  className: null,
  children: null,
};

RecoverPassword.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default RecoverPassword;
