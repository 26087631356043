/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from '../constants/batches';
import LoadingProgress from 'data/utils/reducers/loading';

export const activeBatchLoadingProgress = new LoadingProgress('activeBatch');
export const batchesLoadingProgress = new LoadingProgress('allBatches');

export const commentsLoadingProgress = new LoadingProgress('comments');
export const recommendationsLoadingProgress = new LoadingProgress('recommendations');
export const treatmentsLoadingProgress = new LoadingProgress('treatments');

export const eventDataLoadingProgress = new LoadingProgress('eventData');

export const rhsDataLoadingProgress = new LoadingProgress('rhsData');
export const temperatureDataLoadingProgress = new LoadingProgress('temperatureData');
export const humidityDataLoadingProgress = new LoadingProgress('humidityData');

export const rhsDeviceDataLoadingProgress = new LoadingProgress('rhsDeviceData');
export const temperatureDeviceDataLoadingProgress = new LoadingProgress('temperatureDeviceData');
export const humidityDeviceDataLoadingProgress = new LoadingProgress('humidityDeviceData');

export const logbookLoadingProgress = new LoadingProgress('logbook');

export const siteBatchesLoadingProgress = new LoadingProgress('siteBatches');
export const siteExportsLoadingProgress = new LoadingProgress('siteExports');
export const createExportsLoadingProgress = new LoadingProgress('createExports');

export const airspaceExportsLoadingProgress = new LoadingProgress('airspaceExports');

const loadActiveBatch = (state, action) =>
  state.withMutations((newState) => {
    newState.set('activeBatch', fromJS(action.payload));
    activeBatchLoadingProgress.setLoaded(newState);
  });

const loadBatches = (state, action) =>
  state.withMutations((newState) => {
    newState.set('allBatches', fromJS(action.payload));
    batchesLoadingProgress.setLoaded(newState);
  });

const loadComments = (state, action) =>
  state.withMutations((newState) => {
    newState.set('comments', fromJS(action.payload));
    commentsLoadingProgress.setLoaded(newState);
  });

const loadRecommendations = (state, action) =>
  state.withMutations((newState) => {
    newState.set('recommendations', fromJS(action.payload));
    recommendationsLoadingProgress.setLoaded(newState);
  });

const loadTreatments = (state, action) =>
  state.withMutations((newState) => {
    newState.set('treatments', fromJS(action.payload));
    treatmentsLoadingProgress.setLoaded(newState);
  });

const loadEventData = (state, action) =>
  state.withMutations((newState) => {
    newState.set('eventData', fromJS(action.payload));
    eventDataLoadingProgress.setLoaded(newState);
  });

const loadRhsData = (state, action) =>
  state.withMutations((newState) => {
    newState.set('rhsData', fromJS(action.payload));
    rhsDataLoadingProgress.setLoaded(newState);
  });

const loadTemperatureData = (state, action) =>
  state.withMutations((newState) => {
    newState.set('temperatureData', fromJS(action.payload));
    temperatureDataLoadingProgress.setLoaded(newState);
  });

const loadHumidityData = (state, action) =>
  state.withMutations((newState) => {
    newState.set('humidityData', fromJS(action.payload));
    humidityDataLoadingProgress.setLoaded(newState);
  });

const loadRhsDeviceData = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['device', 'rhsDeviceData'], fromJS(action.payload));
    rhsDeviceDataLoadingProgress.setLoaded(newState);
  });

const loadTemperatureDeviceData = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['device', 'temperatureDeviceData'], fromJS(action.payload));
    temperatureDeviceDataLoadingProgress.setLoaded(newState);
  });

const loadHumidityDeviceData = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['device', 'humidityDeviceData'], fromJS(action.payload));
    humidityDeviceDataLoadingProgress.setLoaded(newState);
  });

const loadLogbook = (state, action) =>
  state.withMutations((newState) => {
    const {
      total,
      entities: { logbookItems },
      lazy,
    } = action.payload;
    newState.setIn(['logbook', 'total'], fromJS(total));
    // eslint-disable-next-line no-unused-expressions
    lazy
      ? newState.mergeIn(['logbook', 'entities'], fromJS(logbookItems))
      : newState.setIn(['logbook', 'entities'], fromJS(logbookItems));
    logbookLoadingProgress.setLoaded(newState);
  });

const loadSiteBatches = (state, action) =>
  state.withMutations((newState) => {
    newState.set('siteBatches', fromJS(action.payload));
    siteBatchesLoadingProgress.setLoaded(newState);
  });

const loadSiteExports = (state, action) =>
  state.withMutations((newState) => {
    newState.set('siteExports', fromJS(action.payload));
    siteExportsLoadingProgress.setLoaded(newState);
  });

const loadAirspaceExports = (state, action) =>
  state.withMutations((newState) => {
    newState.set('airspaceExports', fromJS(action.payload));
    airspaceExportsLoadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    activeBatchLoadingProgress.clear(newState);
    batchesLoadingProgress.clear(newState);
    commentsLoadingProgress.clear(newState);
    recommendationsLoadingProgress.clear(newState);
    treatmentsLoadingProgress.clear(newState);
    rhsDataLoadingProgress.clear(newState);
    temperatureDataLoadingProgress.clear(newState);
    humidityDataLoadingProgress.clear(newState);
    rhsDeviceDataLoadingProgress.clear(newState);
    temperatureDeviceDataLoadingProgress.clear(newState);
    humidityDeviceDataLoadingProgress.clear(newState);
    logbookLoadingProgress.clear(newState);
    siteBatchesLoadingProgress.clear(newState);
    siteExportsLoadingProgress.clear(newState);
    createExportsLoadingProgress.clear(newState);
    airspaceExportsLoadingProgress.clear(newState);
  });

const clearLogbook = (state) =>
  state.withMutations((newState) => {
    logbookLoadingProgress.clear(newState);
    newState.set('logbook', fromJS({ total: null, entities: {} }));
  });

const clearGraphs = (state) =>
  state.withMutations((newState) => {
    commentsLoadingProgress.clear(newState);
    recommendationsLoadingProgress.clear(newState);
    treatmentsLoadingProgress.clear(newState);
    rhsDataLoadingProgress.clear(newState);
    temperatureDataLoadingProgress.clear(newState);
    humidityDataLoadingProgress.clear(newState);
    rhsDeviceDataLoadingProgress.clear(newState);
    temperatureDeviceDataLoadingProgress.clear(newState);
    humidityDeviceDataLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  activeBatch: {},
  allBatches: { total: null, items: [] },
  comments: [],
  recommendations: [],
  treatments: [],
  eventData: [],
  rhsData: [],
  temperatureData: [],
  humidityData: [],
  device: {
    rhsDeviceData: [],
    temperatureDeviceData: [],
    humidityDeviceData: [],
  },
  logbook: { total: null, entities: {} },
  siteBatches: [],
  siteExports: { total: null, items: [] },
  airspaceExports: { total: null, items: [] },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.ACTIVE_BATCH_LOAD_START:
      return activeBatchLoadingProgress.setLoading(state);
    case constants.ACTIVE_BATCH_LOAD_FAILED:
      return activeBatchLoadingProgress.setLoadFailed(state);
    case constants.ACTIVE_BATCH_LOAD_SUCCESS:
      return loadActiveBatch(state, action);

    case constants.BATCHES_LOAD_START:
      return batchesLoadingProgress.setLoading(state);
    case constants.BATCHES_LOAD_FAILED:
      return batchesLoadingProgress.setLoadFailed(state);
    case constants.BATCHES_LOAD_SUCCESS:
      return loadBatches(state, action);

    case constants.COMMENTS_LOAD_START:
      return commentsLoadingProgress.setLoading(state);
    case constants.COMMENTS_LOAD_FAILED:
      return commentsLoadingProgress.setLoadFailed(state);
    case constants.COMMENTS_LOAD_SUCCESS:
      return loadComments(state, action);

    case constants.RECOMMENDATIONS_LOAD_START:
      return recommendationsLoadingProgress.setLoading(state);
    case constants.RECOMMENDATIONS_LOAD_FAILED:
      return recommendationsLoadingProgress.setLoadFailed(state);
    case constants.RECOMMENDATIONS_LOAD_SUCCESS:
      return loadRecommendations(state, action);

    case constants.TREATMENTS_LOAD_START:
      return treatmentsLoadingProgress.setLoading(state);
    case constants.TREATMENTS_LOAD_FAILED:
      return treatmentsLoadingProgress.setLoadFailed(state);
    case constants.TREATMENTS_LOAD_SUCCESS:
      return loadTreatments(state, action);

    case constants.EVENT_DATA_LOAD_START:
      return eventDataLoadingProgress.setLoading(state);
    case constants.EVENT_DATA_LOAD_FAILED:
      return eventDataLoadingProgress.setLoadFailed(state);
    case constants.EVENT_DATA_LOAD_SUCCESS:
      return loadEventData(state, action);

    case constants.RHS_DATA_LOAD_START:
      return rhsDataLoadingProgress.setLoading(state);
    case constants.RHS_DATA_LOAD_FAILED:
      return rhsDataLoadingProgress.setLoadFailed(state);
    case constants.RHS_DATA_LOAD_SUCCESS:
      return loadRhsData(state, action);

    case constants.TEMPERATURE_DATA_LOAD_START:
      return temperatureDataLoadingProgress.setLoading(state);
    case constants.TEMPERATURE_DATA_LOAD_FAILED:
      return temperatureDataLoadingProgress.setLoadFailed(state);
    case constants.TEMPERATURE_DATA_LOAD_SUCCESS:
      return loadTemperatureData(state, action);

    case constants.HUMIDITY_DATA_LOAD_START:
      return humidityDataLoadingProgress.setLoading(state);
    case constants.HUMIDITY_DATA_LOAD_FAILED:
      return humidityDataLoadingProgress.setLoadFailed(state);
    case constants.HUMIDITY_DATA_LOAD_SUCCESS:
      return loadHumidityData(state, action);

    case constants.RHS_DEVICE_DATA_LOAD_START:
      return rhsDeviceDataLoadingProgress.setLoading(state);
    case constants.RHS_DEVICE_DATA_LOAD_FAILED:
      return rhsDeviceDataLoadingProgress.setLoadFailed(state);
    case constants.RHS_DEVICE_DATA_LOAD_SUCCESS:
      return loadRhsDeviceData(state, action);

    case constants.TEMPERATURE_DEVICE_DATA_LOAD_START:
      return temperatureDeviceDataLoadingProgress.setLoading(state);
    case constants.TEMPERATURE_DEVICE_DATA_LOAD_FAILED:
      return temperatureDeviceDataLoadingProgress.setLoadFailed(state);
    case constants.TEMPERATURE_DEVICE_DATA_LOAD_SUCCESS:
      return loadTemperatureDeviceData(state, action);

    case constants.HUMIDITY_DEVICE_DATA_LOAD_START:
      return humidityDeviceDataLoadingProgress.setLoading(state);
    case constants.HUMIDITY_DEVICE_DATA_LOAD_FAILED:
      return humidityDeviceDataLoadingProgress.setLoadFailed(state);
    case constants.HUMIDITY_DEVICE_DATA_LOAD_SUCCESS:
      return loadHumidityDeviceData(state, action);

    case constants.LOGBOOK_LOAD_START:
      return logbookLoadingProgress.setLoading(state);
    case constants.LOGBOOK_LOAD_FAILED:
      return logbookLoadingProgress.setLoadFailed(state);
    case constants.LOGBOOK_LOAD_SUCCESS:
      return loadLogbook(state, action);

    case constants.SITE_BATCHES_LOAD_START:
      return siteBatchesLoadingProgress.setLoading(state);
    case constants.SITE_BATCHES_LOAD_FAILED:
      return siteBatchesLoadingProgress.setLoadFailed(state);
    case constants.SITE_BATCHES_LOAD_SUCCESS:
      return loadSiteBatches(state, action);

    case constants.SITE_EXPORTS_LOAD_START:
      return siteExportsLoadingProgress.setLoading(state);
    case constants.SITE_EXPORTS_LOAD_FAILED:
      return siteExportsLoadingProgress.setLoadFailed(state);
    case constants.SITE_EXPORTS_LOAD_SUCCESS:
      return loadSiteExports(state, action);

    case constants.EXPORTS_CREATE_START:
      return createExportsLoadingProgress.setLoading(state);
    case constants.EXPORTS_CREATE_FAILED:
      return createExportsLoadingProgress.setLoadFailed(state);
    case constants.EXPORTS_CREATE_SUCCESS:
      return createExportsLoadingProgress.setLoaded(state);

    case constants.AIRSPACE_EXPORTS_LOAD_START:
      return airspaceExportsLoadingProgress.setLoading(state);
    case constants.AIRSPACE_EXPORTS_LOAD_FAILED:
      return airspaceExportsLoadingProgress.setLoadFailed(state);
    case constants.AIRSPACE_EXPORTS_LOAD_SUCCESS:
      return loadAirspaceExports(state, action);

    case constants.CLEAR:
      return clear(state);

    case constants.CLEAR_LOGBOOK:
      return clearLogbook(state);

    case constants.CLEAR_GRAPHS:
      return clearGraphs(state);

    default:
      return state;
  }
};
