/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { termsConditionsPath, dataPrivacyPath, imprintPath } from 'constants/urls';

import { isChinaConfig } from 'config';

import s from './styles.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={s.footer}>
      <div className={s.footerChina}>{isChinaConfig && '沪ICP备09047584号-7'}</div>
      <div className={s.footerContent}>
        <Link to={termsConditionsPath} className={s.footerLink}>
          {t('terms_of_use')} |
        </Link>
        <Link to={dataPrivacyPath} className={s.footerLink}>
          {t('data_privacy')} |
        </Link>
        <Link to={imprintPath} className={s.footerLink}>
          {t('imprint')}
        </Link>
      </div>
    </div>
  );
};

export default Footer;
