/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash-es';

import { PrimaryButton } from 'ui';
import { useTranslation } from 'react-i18next';
import sadPiggy from 'public/sadpiggy.svg';
import { basePath, baseAdminPath } from 'constants/urls';
import s from './styles.scss';

const Error = ({ history }) => {
  const { t } = useTranslation();

  const message = get(history, 'location.state.message');
  const pathname = get(history, 'location.pathname');
  const redirectPath = pathname === '/admin/error' ? baseAdminPath : basePath;
  const errorText = get(history, 'location.state.error.message');
  const errorMessage = !process.env.PROJECT_ENV && get(history, 'location.state.errorInfo');
  return (
    <div className={cx(s.error)}>
      <div className={cx(s.errorWrapper)}>
        <div className={cx(s.errorTitle)}>{t('error_title')}</div>
        <div className={cx(s.errorDescription)}>{message || t('error_description')}</div>
        <div className={cx(s.errorDescription)}>{errorText}</div>
        {errorMessage && <div className={cx(s.errorDescription)}>{errorMessage.componentStack.slice(0, 400)}</div>}
        <PrimaryButton className={cx(s.errorButton)} onClick={() => history.push(redirectPath)}>
          {t('error_button_text')}
        </PrimaryButton>
      </div>
      <img className={s.errorImage} src={sadPiggy} alt="Page not found" />
    </div>
  );
};

Error.propTypes = {
  history: PropTypes.func.isRequired,
};

export default Error;
