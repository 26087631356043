/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

/* eslint-disable camelcase */
import * as constants from 'data/constants/admin/devices';
import * as Sentry from '@sentry/react';

export const fetchList =
  (page = 1, perPage = 10, orderingField, order, search) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/`, {
        params: { page, perPage, ordering_field: orderingField, order, search },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.device]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.device),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchGateways =
  (country) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.GATEWAYS_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/gateways/`, { params: { country } });

      dispatch({
        type: constants.GATEWAYS_LOAD_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.GATEWAYS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchAvailability =
  (id, sensor, timeframe) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.AVAILABILITY_LOAD_START });
    try {
      const { from, to } = timeframe;
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/availability/`, {
        params: { sensor, start_date: from, end_date: to },
      });

      dispatch({
        type: constants.AVAILABILITY_LOAD_SUCCESS,
        payload: res.data.data[0],
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.AVAILABILITY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchDataUsage =
  (id, timeframe) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.DATA_USAGE_LOAD_START });
    try {
      const { from, to } = timeframe;
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/data-usage/`, {
        params: { start_date: from, end_date: to },
      });

      dispatch({ type: constants.DATA_USAGE_LOAD_SUCCESS, payload: normalize(res.data, schema.dataUsage) });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.DATA_USAGE_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchConnectivity =
  (timeframe) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CONNECTIVITY_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/connectivity/`, { params: { timeframe } });

      dispatch({
        type: constants.CONNECTIVITY_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CONNECTIVITY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchRegisteredDevices =
  (timeframe) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.REGISTERED_DEVICES_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/register-history/`, {
        params: {
          timeframe,
        },
      });

      dispatch({
        type: constants.REGISTERED_DEVICES_LOAD_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.REGISTERED_DEVICES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchFarms =
  (country) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.FARMS_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/sites/`, { params: { country } });

      dispatch({
        type: constants.FARMS_LOAD_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.FARMS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchOnlineDevices =
  (timeframe) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.ONLINE_DEVICES_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/online-graph`, { params: { timeframe } });

      dispatch({
        type: constants.ONLINE_DEVICES_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.ONLINE_DEVICES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchUnregisteredDevices =
  (orderingField, order) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.UNREGISTERED_DEVICES_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/unregistered/`, {
        params: { ordering_field: orderingField, order },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.device]),
      };

      dispatch({
        type: constants.UNREGISTERED_DEVICES_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.UNREGISTERED_DEVICES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchRDI =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RDI_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/rdi/`, { params: { timeframe: 3 } });

      dispatch({
        type: constants.RDI_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RDI_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchTemperatureHumidity =
  (id, sensorsArray, timeframe) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DEVICE_TEMPERATURE_HUMIDITY_LOAD_START });
    const sensors = sensorsArray.join(',');
    try {
      const { from, to } = timeframe;
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/sensor-data/`, {
        params: { sensors, start_date: from, end_date: to },
      });
      dispatch({
        type: constants.DEVICE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.DEVICE_TEMPERATURE_HUMIDITY_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchWeekKPI =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.WEEK_KPI_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/kpi/`, { params: { timeframe: 7 } });

      dispatch({
        type: constants.WEEK_KPI_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.WEEK_KPI_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchMonthKPI =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.MONTH_KPI_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/kpi/`, { params: { timeframe: 30 } });

      dispatch({
        type: constants.MONTH_KPI_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.MONTH_KPI_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchThreeMonthsKPI =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.THREE_MONTH_KPI_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/kpi/`, { params: { timeframe: 90 } });

      dispatch({
        type: constants.THREE_MONTH_KPI_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.THREE_MONTH_KPI_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchCoughData =
  (id, page = 1, perPage = 10, orderingField, order) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.COUGH_DATA_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/cough-data/`, {
        params: { page, perPage, ordering_field: orderingField, order },
      });

      dispatch({
        type: constants.COUGH_DATA_LOAD_SUCCESS,
        payload: { perPage, ...res.data },
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.COUGH_DATA_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchColorHistory =
  (id, sensor = 0, page = 1, perPage = 10) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.COLOR_HISTORY_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/${id}/color_change_history/`, {
        params: { sensor, page, perPage },
      });

      const payload = { ...res.data, perPage };

      dispatch({
        type: constants.COLOR_HISTORY_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.COLOR_HISTORY_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const bulkStatusChanger =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.BULK_STATUS_CHANGE_START });
    actions.setSubmitting(true);

    try {
      const res = await api.patch(`/api/v2/admin-dashboard/devices/bulk/status/`, values);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.BULK_STATUS_CHANGE_SUCCESS, payload: res });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      // dispatch({ type: constants.BULK_STATUS_CHANGE_FAILED });

      if (e?.response?.data?.device_codes) {
        actions.setErrors({ deviceCodes: e?.response?.data?.device_codes[0] });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const coughDataClear = () => ({ type: constants.COUGH_DATA_CLEAR });

export const rdiClear = () => ({ type: constants.RDI_CLEAR });

export const dataUsageClear = () => ({ type: constants.DATA_USAGE_CLEAR });

export const clear = () => ({ type: constants.CLEAR });

export const availabilitiesClear = () => ({ type: constants.AVAILABILITY_CLEAR });

export const dashboardClear = () => ({ type: constants.DEVICES_DASHBOARD_CLEAR });

export const clearTemperatureHumidity = () => ({ type: constants.CLEAR_DEVICES_TEMPERATURE_HUMIDITY });

export const clearCharts = () => ({ type: constants.CLEAR_CHARTS });

export const clearKPICharts = () => ({ type: constants.CLEAR_KPI });
