/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/profile';
import * as Sentry from '@sentry/react';

export const fetchCountries =
  () =>
  async (dispatch, __, { api }) => {
    dispatch({ type: constants.COUNTRIES_LOAD_START });
    try {
      const res = await api.get('/country/');

      dispatch({
        type: constants.COUNTRIES_LOAD_SUCCESS,
        payload: res.data.items || res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.COUNTRIES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const clearCountries = () => ({ type: constants.COUNTRIES_CLEAR });
