/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/alerts/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const CLEAR = prefix('CLEAR');
