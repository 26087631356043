/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!isEqual(value, ref.current)) ref.current = value;
  return ref.current;
};

export const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
