/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'ui/constants';
import cx from 'classnames';
import s from './styles.scss';

const SuccessIndicator = ({ className, children, total, amount, ...props }) => (
  <div {...props} className={cx(s.indicatorStatus, s.success, className)}>
    <svg
      height={76}
      width={54}
      viewBox="0 0 76 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Web"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="UI-kit" transform="translate(-1155.000000, -540.000000)" stroke={colors.success} strokeWidth="5">
          <polyline id="Shape" points="1228 543 1179.875 591.5 1158 569.454545" />
        </g>
      </g>
    </svg>
  </div>
);

SuccessIndicator.defaultProps = {
  children: null,
  className: null,
};

SuccessIndicator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SuccessIndicator;
