/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/site-creation/${type}`;

export const SET_PAGE = prefix('SET_PAGE');

export const SET_VALUES = prefix('SET_VALUES');

export const RESET_VALUES = prefix('RESET_VALUES');

export const SET_EDIT_PAGE = prefix('SET_EDIT_PAGE');

export const PREINSTALL_ACTIVATE_START = prefix('PREINSTALL_ACTIVATE_START');
export const PREINSTALL_ACTIVATE_SUCCESS = prefix('PREINSTALL_ACTIVATE_SUCCESS');
export const PREINSTALL_ACTIVATE_FAILED = prefix('PREINSTALL_ACTIVATE_FAILED');

export const CHECK_DEVICE_AVAILABLE_START = prefix('CHECK_DEVICE_AVAILABLE_START');
export const CHECK_DEVICE_AVAILABLE_SUCCESS = prefix('CHECK_DEVICE_AVAILABLE_SUCCESS');
export const CHECK_DEVICE_AVAILABLE_FAILED = prefix('CHECK_DEVICE_AVAILABLE_FAILED');
