/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/batches/${type}`;

export const ACTIVE_BATCH_LOAD_START = prefix('ACTIVE_BATCH_LOAD_START');
export const ACTIVE_BATCH_LOAD_SUCCESS = prefix('ACTIVE_BATCH_LOAD_SUCCESS');
export const ACTIVE_BATCH_LOAD_FAILED = prefix('ACTIVE_BATCH_LOAD_FAILED');

export const BATCHES_LOAD_START = prefix('BATCHES_LOAD_START');
export const BATCHES_LOAD_SUCCESS = prefix('BATCHES_LOAD_SUCCESS');
export const BATCHES_LOAD_FAILED = prefix('BATCHES_LOAD_FAILED');

export const START_BATCH_START = prefix('START_BATCH_START');
export const START_BATCH_SUCCESS = prefix('START_BATCH_SUCCESS');
export const START_BATCH_FAILED = prefix('START_BATCH_FAILED');

export const EDIT_BATCH_START = prefix('EDIT_BATCH_START');
export const EDIT_BATCH_SUCCESS = prefix('EDIT_BATCH_SUCCESS');
export const EDIT_BATCH_FAILED = prefix('EDIT_BATCH_FAILED');

export const CLOSE_BATCH_START = prefix('CLOSE_BATCH_START');
export const CLOSE_BATCH_SUCCESS = prefix('CLOSE_BATCH_SUCCESS');
export const CLOSE_BATCH_FAILED = prefix('CLOSE_BATCH_FAILED');

export const ADD_BATCH_COMMENT_START = prefix('ADD_BATCH_COMMENT_START');
export const ADD_BATCH_COMMENT_SUCCESS = prefix('ADD_BATCH_COMMENT_SUCCESS');
export const ADD_BATCH_COMMENT_FAILED = prefix('ADD_BATCH_COMMENT_FAILED');

export const ADD_TREATMENT_START = prefix('ADD_TREATMENT_START');
export const ADD_TREATMENT_SUCCESS = prefix('ADD_TREATMENT_SUCCESS');
export const ADD_TREATMENT_FAILED = prefix('ADD_TREATMENT_FAILED');

export const COMMENTS_LOAD_START = prefix('COMMENTS_LOAD_START');
export const COMMENTS_LOAD_SUCCESS = prefix('COMMENTS_LOAD_SUCCESS');
export const COMMENTS_LOAD_FAILED = prefix('COMMENTS_LOAD_FAILED');

export const RECOMMENDATIONS_LOAD_START = prefix('RECOMMENDATIONS_LOAD_START');
export const RECOMMENDATIONS_LOAD_SUCCESS = prefix('RECOMMENDATIONS_LOAD_SUCCESS');
export const RECOMMENDATIONS_LOAD_FAILED = prefix('RECOMMENDATIONS_LOAD_FAILED');

export const TREATMENTS_LOAD_START = prefix('TREATMENTS_LOAD_START');
export const TREATMENTS_LOAD_SUCCESS = prefix('TREATMENTS_LOAD_SUCCESS');
export const TREATMENTS_LOAD_FAILED = prefix('TREATMENTS_LOAD_FAILED');

export const RHS_DATA_LOAD_START = prefix('RHS_DATA_LOAD_START');
export const RHS_DATA_LOAD_SUCCESS = prefix('RHS_DATA_LOAD_SUCCESS');
export const RHS_DATA_LOAD_FAILED = prefix('RHS_DATA_LOAD_FAILED');

export const TEMPERATURE_DATA_LOAD_START = prefix('TEMPERATURE_DATA_LOAD_START');
export const TEMPERATURE_DATA_LOAD_SUCCESS = prefix('TEMPERATURE_DATA_LOAD_SUCCESS');
export const TEMPERATURE_DATA_LOAD_FAILED = prefix('TEMPERATURE_DATA_LOAD_FAILED');

export const HUMIDITY_DATA_LOAD_START = prefix('HUMIDITY_DATA_LOAD_START');
export const HUMIDITY_DATA_LOAD_SUCCESS = prefix('HUMIDITY_DATA_LOAD_SUCCESS');
export const HUMIDITY_DATA_LOAD_FAILED = prefix('HUMIDITY_DATA_LOAD_FAILED');

export const RHS_DEVICE_DATA_LOAD_START = prefix('RHS_DEVICE_DATA_LOAD_START');
export const RHS_DEVICE_DATA_LOAD_SUCCESS = prefix('RHS_DEVICE_DATA_LOAD_SUCCESS');
export const RHS_DEVICE_DATA_LOAD_FAILED = prefix('RHS_DEVICE_DATA_LOAD_FAILED');

export const TEMPERATURE_DEVICE_DATA_LOAD_START = prefix('TEMPERATURE_DEVICE_DATA_LOAD_START');
export const TEMPERATURE_DEVICE_DATA_LOAD_SUCCESS = prefix('TEMPERATURE_DEVICE_DATA_LOAD_SUCCESS');
export const TEMPERATURE_DEVICE_DATA_LOAD_FAILED = prefix('TEMPERATURE_DEVICE_DATA_LOAD_FAILED');

export const HUMIDITY_DEVICE_DATA_LOAD_START = prefix('HUMIDITY_DEVICE_DATA_LOAD_START');
export const HUMIDITY_DEVICE_DATA_LOAD_SUCCESS = prefix('HUMIDITY_DEVICE_DATA_LOAD_SUCCESS');
export const HUMIDITY_DEVICE_DATA_LOAD_FAILED = prefix('HUMIDITY_DEVICE_DATA_LOAD_FAILED');

export const LOGBOOK_LOAD_START = prefix('LOGBOOK_LOAD_START');
export const LOGBOOK_LOAD_SUCCESS = prefix('LOGBOOK_LOAD_SUCCESS');
export const LOGBOOK_LOAD_FAILED = prefix('LOGBOOK_LOAD_FAILED');

export const SITE_BATCHES_LOAD_START = prefix('SITE_BATCHES_LOAD_START');
export const SITE_BATCHES_LOAD_SUCCESS = prefix('SITE_BATCHES_LOAD_SUCCESS');
export const SITE_BATCHES_LOAD_FAILED = prefix('SITE_BATCHES_LOAD_FAILED');

export const SITE_EXPORTS_LOAD_START = prefix('SITE_EXPORTS_LOAD_START');
export const SITE_EXPORTS_LOAD_SUCCESS = prefix('SITE_EXPORTS_LOAD_SUCCESS');
export const SITE_EXPORTS_LOAD_FAILED = prefix('SITE_EXPORTS_LOAD_FAILED');

export const EXPORTS_CREATE_START = prefix('EXPORTS_CREATE_START');
export const EXPORTS_CREATE_SUCCESS = prefix('EXPORTS_CREATE_SUCCESS');
export const EXPORTS_CREATE_FAILED = prefix('EXPORTS_CREATE_FAILED');

export const EXPORTS_DOWNLOAD_START = prefix('EXPORTS_DOWNLOAD_START');
export const EXPORTS_DOWNLOAD_SUCCESS = prefix('EXPORTS_DOWNLOAD_SUCCESS');
export const EXPORTS_DOWNLOAD_FAILED = prefix('EXPORTS_DOWNLOAD_FAILED');

export const AIRSPACE_EXPORTS_LOAD_START = prefix('AIRSPACE_EXPORTS_LOAD_START');
export const AIRSPACE_EXPORTS_LOAD_SUCCESS = prefix('AIRSPACE_EXPORTS_LOAD_SUCCESS');
export const AIRSPACE_EXPORTS_LOAD_FAILED = prefix('AIRSPACE_EXPORTS_LOAD_FAILED');

export const EVENT_DATA_LOAD_START = prefix('EVENT_DATA_LOAD_START');
export const EVENT_DATA_LOAD_SUCCESS = prefix('EVENT_DATA_LOAD_SUCCESS');
export const EVENT_DATA_LOAD_FAILED = prefix('EVENT_DATA_LOAD_FAILED');

export const CLEAR_GRAPHS = prefix('CLEAR_GRAPHS');

export const CLEAR_LOGBOOK = prefix('CLEAR_LOGBOOK');

export const CLEAR = prefix('CLEAR');
