/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { colors, fontSize } from 'ui/constants';
import Text from '../text';
import cx from 'classnames';
import s from './styles.scss';

const AlertIndicator = ({ className, children, total, amount, label, ...props }) => (
  <div {...props} className={cx(s.indicatorStatus, amount > 0 && s.error, className)}>
    <svg
      height={48}
      width={48}
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1097.000000, -498.000000)">
          <g transform="translate(986.000000, 436.000000)">
            <g transform="translate(108.000000, 59.000000)">
              <path
                d="M25.4779778,3.81319341 L40.7972938,10.1586619 L47.1427622,25.4779778 L40.7972938,40.7972938 L25.4779778,47.1427622 L10.1586619,40.7972938 L3.81319341,25.4779778 L10.1586619,10.1586619 L25.4779778,3.81319341 Z"
                stroke="#FF1E00"
                strokeWidth="4"
                fill="none"
                transform="translate(25.477978, 25.477978) rotate(22.500000) translate(-25.477978, -25.477978) "
              />
              {amount > 0 ? (
                <text
                  fontSize={fontSize.xs}
                  fontWeight="400"
                  fill="none"
                  stroke="none"
                  className={{ fill: colors.primary }}
                >
                  <tspan x="25" y="30" textAnchor="middle">
                    {amount}/{total}
                  </tspan>
                </text>
              ) : (
                <polilyne
                  stroke="none"
                  className={{ stroke: colors.primary }}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="40 20 23.5 37 16 29.2727273"
                  transform="translate(-2, -2)"
                />
              )}
            </g>
          </g>
        </g>
      </g>
    </svg>
    <Text size={Text.size.m} className={cx(s.indicatorType, amount > 0 && s.activeColors)}>
      {label}
    </Text>
  </div>
);

AlertIndicator.defaultProps = {
  children: null,
  className: null,
  total: null,
  amount: null,
  label: 'Alarm',
};

AlertIndicator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  total: PropTypes.number,
  amount: PropTypes.number,
  label: PropTypes.string,
};

export default AlertIndicator;
