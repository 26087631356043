/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import {
  loadingProgress,
  loadingFaqProgress,
  loadingLanguagesProgress,
  loadingCountriesProgress,
  settingsLoadingProgress,
} from 'data/reducers/profile';

const getState = (state) => state.profile;

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const getItem = (state) => getState(state).get('profile');
export const getItemsPerPage = (state) => getItem(state)?.get('paging_size');

export const getSettingsLoaded = (state) => settingsLoadingProgress.getLoaded(getState(state));
export const getSettingsLoading = (state) => settingsLoadingProgress.getLoading(getState(state));
export const getSettingsLoadFailed = (state) => settingsLoadingProgress.getLoadFailed(getState(state));

export const getSettings = (state) => getState(state).get('settings');

export const getFaq = (state) => getState(state).get('faq');

export const getFaqLoaded = (state) => loadingFaqProgress.getLoaded(getState(state));
export const getFaqLoading = (state) => loadingFaqProgress.getLoading(getState(state));
export const getFaqLoadFailed = (state) => loadingFaqProgress.getLoadFailed(getState(state));

export const getLanguages = (state) => getState(state).get('languages');

export const getLanguagesLoaded = (state) => loadingLanguagesProgress.getLoaded(getState(state));
export const getLanguagesLoading = (state) => loadingLanguagesProgress.getLoading(getState(state));
export const getLanguagesFailed = (state) => loadingLanguagesProgress.getLoadFailed(getState(state));

export const getShowSiteRegistrationConfirm = (state) => getState(state).get('showSiteRegistrationConfirm');

export const getCountries = (state) => getState(state).get('countries');

export const getCountriesLoaded = (state) => loadingCountriesProgress.getLoaded(getState(state));
export const getCountriesLoading = (state) => loadingCountriesProgress.getLoading(getState(state));
export const getCountriesFailed = (state) => loadingCountriesProgress.getLoadFailed(getState(state));
