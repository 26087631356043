/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { useEffect } from 'react';

export const useMouseDown = (callback) => {
  useEffect(() => {
    window.addEventListener('mousedown', callback);

    return () => {
      window.removeEventListener('mousedown', callback);
    };
  });
};
