/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, actions, selectors } from 'data';
import { withTranslation } from 'react-i18next';

import { Formik } from 'formik';
import SignInForm from 'components/shared/sign-in';
import { FluidPreloader } from 'components/core/preloader';

import { sitesPath } from 'constants/urls';
import { RouterPropTypes } from 'utils/prop-types';

import { WithTranslateFormErrors } from 'utils/translation-validation';
import { LoginSchema } from 'utils/validation';

const mapStateToProps = (state) => ({
  sessionIsActive: selectors.session.getSessionActive(state),
  files: selectors.session.getFiles(state),
  filesLoaded: selectors.session.getFilesLoaded(state),
  isEmailSent: selectors.users.getEmailSent(state),
  isEmailSending: selectors.users.getEmailSending(state),
});

const mapDispatchToProps = {
  login: actions.session.fetchSession,
  fetchFiles: actions.session.fetchFiles,
  resendEmail: actions.users.resendEmail,
  clearUsers: actions.users.clear,
};

/* eslint-disable react/prefer-stateless-function */
class SignIn extends React.Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    sessionIsActive: PropTypes.bool.isRequired,
    location: RouterPropTypes.location.isRequired,
    t: PropTypes.func.isRequired,
    fetchFiles: PropTypes.func.isRequired,
    filesLoaded: PropTypes.bool,
    files: PropTypes.arrayOf({}),
    isEmailSent: PropTypes.bool,
    isEmailSending: PropTypes.bool,
    resendEmail: PropTypes.func.isRequired,
    clearUsers: PropTypes.func.isRequired,
  };

  static defaultProps = { filesLoaded: false, files: null, isEmailSent: false, isEmailSending: false };

  componentDidMount() {
    this.props.fetchFiles();
  }

  login = async (values, formik) => {
    if (await this.props.login(values, formik)) {
      localStorage.setItem('showMarketingOverlay', true);
    }
  };

  render() {
    if (this.props.sessionIsActive)
      return <Redirect to={this.props.location.state ? this.props.location.state.location.pathname : sitesPath} />;

    return (
      <>
        {this.props.filesLoaded ? (
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={LoginSchema(this.props.t)}
            onSubmit={this.login}
            render={(formikProps) => (
              <WithTranslateFormErrors {...formikProps}>
                <SignInForm
                  {...formikProps}
                  files={this.props.files}
                  isEmailSent={this.props.isEmailSent}
                  isEmailSending={this.props.isEmailSending}
                  resendEmail={this.props.resendEmail}
                  clearUsers={this.props.clearUsers}
                />
              </WithTranslateFormErrors>
            )}
          />
        ) : (
          <FluidPreloader />
        )}
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SignIn));
