/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

import accept from './icons/accept.svg';
import apps from './icons/apps.svg';
import appAdmin from './icons/app-admin.svg';
import appMySoundtalks from './icons/app-my-soundtalks.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowLeftWhite from './icons/arrow-left-white.svg';
import copy from './icons/copy.svg';
import alertCircle from './icons/alert-circle.svg';
import message from './icons/message.svg';
import bell from './icons/bell.svg';
import greenClose from './icons/green-close.svg';
import calendar from './icons/calendar.svg';
import home from './icons/home.svg';
import search from './icons/search.svg';
import searchLongHandle from './icons/search-long-handle.svg';
import refresh from './icons/refresh.svg';
import cough from './icons/cough.svg';
import drop from './icons/drop.svg';
import cloud from './icons/cloud.svg';
import offlineNetwork from './icons/offline-network.svg';
import alertStatus from './icons/alert-status.svg';
import warningStatus from './icons/warning-status.svg';
import warningCircle from './icons/warning-circle.svg';
import warningTriangle from './icons/warning-triangle.svg';
import successStatus from './icons/success-status.svg';
import check from './icons/check.svg';
import plusOutlined from './icons/plus-outlined.svg';
import remove from './icons/remove.svg';
import settingsOutlined from './icons/settings-outlined.svg';
import settings from './icons/settings.svg';
import weatherN from './icons/weather-n.svg';
import weatherS from './icons/weather-s.svg';
import weatherE from './icons/weather-e.svg';
import weatherW from './icons/weather-w.svg';
import weatherNE from './icons/weather-ne.svg';
import weatherNW from './icons/weather-nw.svg';
import weatherSE from './icons/weather-se.svg';
import weatherSW from './icons/weather-sw.svg';
import helpOutlined from './icons/help-o.svg';
import helpOutlinedThick from './icons/help-outlined-thick.svg';
import info from './icons/info.svg';
import infoDark from './icons/info-dark.svg';
import shield from './icons/shield.svg';
import user from './icons/user.svg';
import starOutlined from './icons/star-outlined.svg';
import edit from './icons/edit.svg';
import paperclip from './icons/paperclip.svg';
import cloudDrizzle from './icons/cloud-drizzle.svg';
import cloudLightning from './icons/cloud-lightning.svg';
import cloudRain from './icons/cloud-rain.svg';
import cloudSnow from './icons/cloud-snow.svg';
import wind from './icons/wind.svg';
import sun from './icons/sun.svg';
import moon from './icons/moon.svg';
import partlyCloudyDay from './icons/partly-cloudy-day.svg';
import partlyCloudyNight from './icons/partly-cloudy-night.svg';
import tornado from './icons/tornado.svg';
import fog from './icons/fog.svg';
import noteBlue from './icons/notes-blue.svg';
import thermalShock from './icons/thermal-shock.svg';
import heatStress from './icons/heat-stress.svg';
import closeWhite from './icons/close-white.svg';
import connectionLevel1 from './icons/connection-level-1.svg';
import connectionLevel2 from './icons/connection-level-2.svg';
import connectionLevel3 from './icons/connection-level-3.svg';
import connectionLevel4 from './icons/connection-level-4.svg';
import connectionUnknown from './icons/unknown-connection.svg';
import activity from './icons/activity.svg';
import clipboard from './icons/clipboard.svg';
import grid from './icons/grid.svg';
import list from './icons/list.svg';
import locations from './icons/locations.svg';
import play from './icons/play-circle.svg';
import download from './icons/download.svg';
import close from './icons/x.svg';
import animatedClock from './icons/clock-o.svg';
import arrowUp from './icons/chevron-up.svg';
import arrowDown from './icons/chevron-down.svg';
import chevronLeft from './icons/chevron-left.svg';
import chevronRight from './icons/chevron-right.svg';
import downloadCloud from './icons/download-cloud.svg';
import uploadCloud from './icons/upload-cloud.svg';
import database from './icons/database.svg';
import mapPin from './icons/map-pin.svg';
import filter from './icons/filter.svg';
import toolbox from './icons/toolbox.svg';
import fattening from './icons/fattening.svg';
import nursery from './icons/nursery.svg';
import sow from './icons/sow.svg';
import broiler from './icons/broiler.svg';
import weanToFinish from './icons/wean-to-finish.svg';
import recommendationDiagnostics from './icons/recommendation-diagnostics.svg';
import recommendationTreatment from './icons/recommendation-treatment.svg';
import recommendationOngoingTreatment from './icons/recommendation-ongoing-treatment.svg';
import recommendationVeterinarian from './icons/recommendation-veterinarian.svg';
import recommendationEnvironment from './icons/recommendation-environment.svg';
import episodeDayRed from './icons/episode-day-red.svg';
import episodeDayGreen from './icons/episode-day-green.svg';
import episodeDayYellow from './icons/episode-day-yellow.svg';
import episodeDayGrey from './icons/episode-day-grey.svg';
import checkCircle from './icons/check-circle.svg';
import minus from './icons/minus.svg';
import plus from './icons/plus.svg';
import monitor from './icons/monitor.svg';
import gateway from './icons/gateway.svg';
import supplier from './icons/supplier.svg';
import logo from './icons/logo.svg';
import termometer from './icons/termometer.svg';
import filterList from './icons/filter-list.svg';
import cross from './icons/cross.svg';
import pills from './icons/pills.svg';
import pigHead from './icons/pig-head.svg';
import mail from './icons/mail.svg';
import link from './icons/link.svg';

const Icon = ({ icon, className, ...props }) => (
  <svg {...props} className={cx(s.icon, className)} viewBox={icon.viewBox}>
    <use xlinkHref={`#${icon.id}`} />
  </svg>
);

Icon.icons = {
  apps,
  appAdmin,
  appMySoundtalks,
  accept,
  arrowLeft,
  arrowLeftWhite,
  calendar,
  copy,
  remove,
  alertCircle,
  message,
  bell,
  greenClose,
  home,
  search,
  searchLongHandle,
  refresh,
  cough,
  drop,
  offlineNetwork,
  alertStatus,
  warningCircle,
  warningStatus,
  warningTriangle,
  successStatus,
  check,
  plus,
  plusOutlined,
  settingsOutlined,
  settings,
  helpOutlined,
  helpOutlinedThick,
  info,
  infoDark,
  shield,
  user,
  starOutlined,
  edit,
  paperclip,
  filter,
  /* weather icons */
  weatherN,
  weatherS,
  weatherE,
  weatherW,
  weatherNE,
  weatherNW,
  weatherSE,
  weatherSW,
  cloud,
  cloudDrizzle,
  cloudLightning,
  cloudRain,
  cloudSnow,
  wind,
  sun,
  moon,
  partlyCloudyDay,
  partlyCloudyNight,
  tornado,
  fog,
  /* weather icons */
  noteBlue,
  thermalShock,
  heatStress,
  closeWhite,
  connectionLevel1,
  connectionLevel2,
  connectionLevel3,
  connectionLevel4,
  connectionUnknown,
  activity,
  clipboard,
  grid,
  list,
  locations,
  play,
  download,
  close,
  animatedClock,
  arrowUp,
  arrowDown,
  chevronLeft,
  chevronRight,
  downloadCloud,
  uploadCloud,
  database,
  mapPin,
  toolbox,
  fattening,
  nursery,
  sow,
  broiler,
  weanToFinish,
  recommendationDiagnostics,
  recommendationTreatment,
  recommendationOngoingTreatment,
  recommendationVeterinarian,
  recommendationEnvironment,
  episodeDayRed,
  episodeDayGreen,
  episodeDayYellow,
  episodeDayGrey,
  checkCircle,
  minus,
  monitor,
  gateway,
  supplier,
  logo,
  termometer,
  filterList,
  cross,
  pills,
  pigHead,
  mail,
  link,
};

Icon.defaultProps = {
  className: null,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icon.icons)).isRequired,
};

export default Icon;
