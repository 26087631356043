/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/recommendations';

import * as Sentry from '@sentry/react';

export const fetchSiteEpisodes =
  (siteId, active, sort) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.SITE_EPISODES_LOAD_START });
    try {
      const res = await api.get(`/sites/${siteId}/episodes/`, { params: { active, ...sort } });

      dispatch({
        type: constants.SITE_EPISODES_LOAD_SUCCESS,
        payload: normalize(res.data.items, [schema.episode]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.SITE_EPISODES_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const patchRecommendationStatus =
  (recommendationId, status, timeframe) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RECOMMENDATION_STATUS_PATCH_START });
    try {
      const params = timeframe ? { status, start: timeframe.from, end: timeframe.to } : { status };
      const res = await api.patch(`/recommendations/${recommendationId}/`, params);

      dispatch({
        type: constants.RECOMMENDATION_STATUS_PATCH_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RECOMMENDATION_STATUS_PATCH_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const patchRecommendationsListStatuses =
  (recommendations, status) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RECOMMENDATIONS_LIST_STATUS_PATCH_START });
    try {
      const res = await Promise.all(
        recommendations.map((recommendation) =>
          api.patch(`/recommendations/${recommendation}/`, { status }).then((response) => response.data),
        ),
      );

      dispatch({
        type: constants.RECOMMENDATIONS_LIST_STATUS_PATCH_SUCCESS,
        payload: res,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RECOMMENDATIONS_LIST_STATUS_PATCH_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchEpisodeDays =
  (episodeId) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.EPISODE_DAYS_LOAD_START });
    try {
      const res = await api.get(`/episodes/${episodeId}/days/`);

      dispatch({
        type: constants.EPISODE_DAYS_LOAD_SUCCESS,
        payload: normalize(res.data, [schema.episodeDay]),
        episodeId: episodeId.toString(),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.EPISODE_DAYS_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const startNewTreatment =
  (episodeId, timeframe, description) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.EPISODE_TREATMENTS_START });
    try {
      const params = { description, start: timeframe.from, end: timeframe.to };
      const res = await api.post(`/episodes/${episodeId}/treatments/`, params);

      dispatch({
        type: constants.EPISODE_TREATMENTS_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.EPISODE_TREATMENTS_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const setEpisodeRecommendationsCount = (episodeId, count) => (dispatch) => {
  dispatch({
    type: constants.UPDATE_EPISODE_RECOMMENDATIONS_COUNT,
    payload: { episodeId: episodeId.toString(), count },
  });
};

export const clearSingle = () => ({ type: constants.CLEAR_SINGLE });
export const clear = () => ({ type: constants.CLEAR });
