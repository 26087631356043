/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/admin/licenses';
import * as Sentry from '@sentry/react';

export const fetchOrdersList =
  (page = 1, perPage = 10) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.ORDERS_LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/licenses/orders/`, {
        params: { page, perPage },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.order]),
      };

      dispatch({
        type: constants.ORDERS_LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.ORDERS_LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchList =
  (page = 1, perPage = 10) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/licenses/`, {
        params: { page, perPage },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.license]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchLicenseTemplatesList =
  (page = 1, perPage = 25) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LICENSE_TEMPLATES_LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/licenses/license-template/`, {
        params: { page, perPage },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.licenseTemplate]),
      };

      dispatch({
        type: constants.LICENSE_TEMPLATES_LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LICENSE_TEMPLATES_LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingleOrder =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.ORDER_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/licenses/orders/${id}/`);

      dispatch({
        type: constants.ORDER_LOAD_SUCCESS,
        payload: normalize(res.data, schema.order),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.ORDER_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const createOrder =
  (values, actions) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.ORDER_ADD_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`/api/v2/admin-dashboard/licenses/orders/`, values);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.ORDER_ADD_SUCCESS, payload: normalize(res.data, schema.order) });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      // dispatch({ type: constants.ORDER_ADD_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const updateOrder =
  (values, actions) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.ORDER_ADD_START });
    actions.setSubmitting(true);
    try {
      const { id } = values;
      const res = await api.patch(`/api/v2/admin-dashboard/licenses/orders/${id}/`, values);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.ORDER_ADD_SUCCESS, payload: normalize(res.data, schema.order) });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      // dispatch({ type: constants.ORDER_ADD_FAILED });
      if (e.response && e.response.data) {
        actions.setErrors({ message: e.response.data });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const createLicense =
  (values) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.LICENSE_ADD_START });

    try {
      const res = await api.post(`/api/v2/admin-dashboard/licenses/`, values);

      dispatch({ type: constants.LICENSE_ADD_SUCCESS, payload: normalize(res.data, schema.license) });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      dispatch({ type: constants.LICENSE_ADD_FAILED });

      Sentry.captureException(e);
      return false;
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const clearOrders = () => ({ type: constants.ORDERS_CLEAR });

export const clearSingleOrder = () => ({ type: constants.ORDER_CLEAR });

export const clearLicenseTemplates = () => ({ type: constants.LICENSE_TEMPALTES_CLEAR });
