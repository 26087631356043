/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { apkDownloadUrl, baseURL } from 'data';
import { DarkInput, PrimaryButton, FieldWrapper, GeneralFormError } from 'ui';
import { getLanguage } from 'utils/language-helpers';
import AppStoreBadge from 'components/core/markets/app-store';
import GooglePlayBadge from 'components/core/markets/google-play';
import { FluidPreloader } from 'components/core/preloader';
import { restorePasswordPath, registrationPath } from 'constants/urls';

import { Form, Field } from 'formik';
import Icon from 'components/core/icon';

import logo from 'public/logo.png';
import androidApk from 'public/android.svg';

import HeaderShared from './layout/header';
import s from './styles.scss';

const SignIn = ({
  className,
  children,
  isSubmitting,
  errors,
  values,
  resetForm,
  files,
  isEmailSent,
  isEmailSending,
  resendEmail,
  clearUsers,
  ...props
}) => {
  const { t } = useTranslation();
  const filePath = `${baseURL}/static-files/${files?.find((item) => item.original_name === 'device_manual.pdf')?.guid}`;

  const handleResendEmail = async () => {
    if (await resendEmail(values.username)) {
      resetForm();
      clearUsers();
    }
  };

  const language = getLanguage()?.toLowerCase();

  return (
    <div className={cx(s.sharedForm, className, s.sharedFormSignIn)}>
      <HeaderShared className={s.sharedFormHeader}>
        <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <div className={s.sharedFormHeaderCreateLink}>
        {t('dont_have_account')}

        <Link to={registrationPath} className={s.sharedFormFooterLink}>
          {t('create_profile')}
        </Link>
      </div>
      <Form>
        <Field
          id="login"
          name="username"
          placeholder={t('email')}
          component={FieldWrapper}
          inputComponent={DarkInput}
          className={s.sharedFormField}
        />

        <Link to={restorePasswordPath} className={s.sharedFormForgot}>
          {t('forgot_password')}
        </Link>

        <Field
          id="password"
          name="password"
          type="password"
          placeholder={t('password')}
          component={FieldWrapper}
          inputComponent={DarkInput}
          className={s.sharedFormField}
          error={(errors && (errors.password || errors.message || null)) || null}
        />

        {errors?.code === 'account_not_activated' && !isEmailSent && (
          <PrimaryButton type="button" className={s.sharedFormSubmit} onClick={handleResendEmail}>
            {isEmailSending ? <FluidPreloader light /> : <>{t('resend_activation_email')}</>}
          </PrimaryButton>
        )}

        <PrimaryButton
          type="submit"
          data-cy="submit"
          className={s.sharedFormSubmit}
          onClick={props.handleSubmit}
          isDisabled={isSubmitting}
        >
          {isSubmitting ? <FluidPreloader light /> : <>{t('login')}</>}
        </PrimaryButton>

        <Link to="/contact-us" className={cx(s.sharedFormFooterLink, s.sharedFormFooterLinkContactUs)}>
          {t('contact_us')}
        </Link>

        <GeneralFormError error={errors.non_field_errors} />
      </Form>

      <a
        href={filePath}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(s.sharedFormFooterLink, s.sharedFormFooterLinkGuide)}
      >
        <Icon className={cx(s.sharedFormFooterLinkIcon, s.sharedFormFooterLinkGuideIcon)} icon={Icon.icons.arrowLeft} />
        {t('open_app_guide')}
      </a>

      <div className={s.sharedFormMarkets}>
        <h4 className={s.sharedFormMarketsTitle}>{t('check_apps')}</h4>
        <div className={s.sharedFormMarketsLinks}>
          <AppStoreBadge language={language} />
          <GooglePlayBadge language={language} />
        </div>
        {apkDownloadUrl && (
          <div className={s.sharedFormMarketsLinksApk}>
            <a href={apkDownloadUrl} target="_blank" rel="noopener noreferrer">
              <img className={s.sharedFormMarketsLinksApkLinkImg} src={androidApk} alt="Apk" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

SignIn.defaultProps = {
  className: null,
  children: null,
  errors: {},
  isEmailSent: false,
  isEmailSending: false,
};

SignIn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line
  errors: PropTypes.object,
  values: PropTypes.shape({ username: PropTypes.string, password: PropTypes.string }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf({}).isRequired,
  isEmailSent: PropTypes.bool,
  isEmailSending: PropTypes.bool,
  resendEmail: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  clearUsers: PropTypes.func.isRequired,
};

export default SignIn;
