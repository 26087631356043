/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { signInPath } from 'constants/urls';

import logo from 'public/logo.png';
import Icon from 'components/core/icon';
import FooterShared from 'components/shared/layout/footer';

import s from './styles.scss';

const Help = ({ className, data }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(s.helpPage, className)}>
      <div className={s.helpPageHeader}>
        <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
      </div>

      <Link to={signInPath} className={s.sharedFormTopLink}>
        <Icon className={s.sharedFormTopLinkIcon} icon={Icon.icons.arrowLeft} />
        <div className={s.sharedFormTopLinkText}>{t('back')}</div>
      </Link>

      <div className={s.helpPageContainer}>
        <div dangerouslySetInnerHTML={{ __html: data.body }} className={s.helpPageContent} />
      </div>

      <FooterShared className={s.sharedFormFooterRegistration}>
        {t('no_more_questions')}
        <Link to={signInPath} className={s.sharedFormFooterLink}>
          {t('sign_in')}
        </Link>
      </FooterShared>
    </div>
  );
};

Help.defaultProps = {
  className: null,
};

Help.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
};

export default Help;
