/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  loadingProgress,
  listLoadingProgress,
  statusLoadingProgress,
  structureLoadingProgress,
  latestInfoLoadingProgress,
  itemsPagination,
  invitationsLoadingProgress,
} from 'data/reducers/sites';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

import { getSiteTypes, getSiteStatuses } from './constants';

import { getEntities as getGroups } from './groups';
import { getEntities as getNotificationsEntities } from './notifications';
import { getEntities as getAlertsEntities } from './alerts';

const getState = (state) => state.sites;

export const getEntities = (state) => getState(state).get('entities');

export const getStatuses = (state) => getState(state).get('statuses');

export const getItem = (state, id) => getEntities(state).get(id);

export const getStatus = (state, id) => getStatuses(state).get(id);

export const getFilter = (state) => getState(state).get('filter');

export const getStatusFilter = (state) => getFilter(state).get('status');
export const getTypeFilter = (state) => getFilter(state).get('type');
export const getGroupFilter = (state) => getFilter(state).get('group');
export const getSearchFilter = (state) => getFilter(state).get('search');

export const getItemsList = () =>
  createImmutableEqualSelector(
    [getSiteTypes, getSiteStatuses, getEntities, getNotificationsEntities, getAlertsEntities, getState],
    (siteTypes, currentSiteStatuses, sites, notifications, alerts, state) =>
      denormalize(
        state.get('list'),
        [schemas.site],
        new Map({ siteTypes, currentSiteStatuses, sites, notifications, alerts }),
      ).sortBy((f) => -f.get('preinstalled')),
  );

export const getItemsTotal = (state) => itemsPagination.getTotalItems(getState(state));

export const getFilteredList = (state) => {
  const status = getStatusFilter(state);
  const group = getGroupFilter(state);
  const type = getTypeFilter(state);
  const search = getSearchFilter(state);

  const getItemsListSelector = getItemsList();
  if (status.size === 0 && !group && !type.size && !search) return getItemsListSelector(state);

  const filteredEntities = getItemsListSelector(state).filter((site) => {
    const statusMatches = status.size !== 0 ? status.includes(String(site.getIn(['status', 'id']))) : true;

    const groupMatches = group
      ? getGroups(state)
          .getIn([String(group), 'linked_sites'])
          .includes(site.get('id'))
      : true;

    const typeMatches = type.size ? type.has(site.get('type').toLowerCase()) : true;
    const searchMatches = search ? site.get('name').toLowerCase().includes(search.toLowerCase()) : true;
    return statusMatches && groupMatches && typeMatches && searchMatches;
  });

  return filteredEntities;
};

export const getSiteStructure = (state) => getState(state).get('structure');

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const getStatusLoaded = (state) => statusLoadingProgress.getLoaded(getState(state));
export const getStatusLoading = (state) => statusLoadingProgress.getLoading(getState(state));
export const getStatusLoadFailed = (state) => statusLoadingProgress.getLoadFailed(getState(state));

export const getStructureLoaded = (state) => structureLoadingProgress.getLoaded(getState(state));
export const getStructureLoading = (state) => structureLoadingProgress.getLoading(getState(state));
export const getStructureLoadFailed = (state) => structureLoadingProgress.getLoadFailed(getState(state));

export const getLatestInfo = (state) => getState(state).get('latestInfo');

export const getLatestInfoLoaded = (state) => latestInfoLoadingProgress.getLoaded(getState(state));
export const getLatestInfoLoading = (state) => latestInfoLoadingProgress.getLoading(getState(state));
export const getLatestInfoLoadFailed = (state) => latestInfoLoadingProgress.getLoadFailed(getState(state));

export const getSiteInvitations = (state) => getState(state).get('invitations');
export const getInvitationsLoaded = (state) => invitationsLoadingProgress.getLoaded(getState(state));
export const getInvitationsLoading = (state) => invitationsLoadingProgress.getLoading(getState(state));
export const getInvitationsLoadFailed = (state) => invitationsLoadingProgress.getLoadFailed(getState(state));
