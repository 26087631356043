/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import { listLoadingProgress } from 'data/reducers/admin/airspaces';

import createImmutableEqualSelector from '../../utils/immutable-selector';

const getState = (state) => state.adminAirspaces;

export const getEntities = (state) => getState(state).get('entities');

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (airspaces, state) =>
  denormalize(state.get('list'), [schemas.airspace], new Map({ airspaces })),
);

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));

export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

export const getSiteAirspaces = (state, siteId) =>
  getItemsList(state)?.filter((airspace) => airspace.get('site_pk') === Number(siteId));
