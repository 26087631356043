/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/sites';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const listLoadingProgress = new LoadingProgress('adminSiteList');
export const loadingProgress = new LoadingProgress('adminSite');
export const devicesPagination = new Pagination('siteDevices');
export const deviceLoadingProgress = new LoadingProgress('siteDevices');
export const dataUsageLoadingProgress = new LoadingProgress('dataUsage');
export const gatewaysLoadingProgress = new LoadingProgress('gateways');
export const loginActivityLoadingProgress = new LoadingProgress('logginActivity');
export const availabilityLoadingProgress = new LoadingProgress('availability');
export const availabilityPagination = new Pagination('availability');
export const itemsPagination = new Pagination('adminSites');
export const weeklyLoadingProgress = new LoadingProgress('dataUsageWeekly');
export const monthlyLoadingProgress = new LoadingProgress('dataUsageMonthly');
export const infoUpdateProgress = new LoadingProgress('siteInfo');
export const statusLoadingProgress = new LoadingProgress('siteStatus');
export const structureLoadingProgress = new LoadingProgress('structureStatus');
export const rdiLoadingProgress = new LoadingProgress('rdi');
export const temperatureHumidityLoadingProgress = new LoadingProgress('temperatureHumidity');
export const airspacesStatusesLoadingProgress = new LoadingProgress('temperatureHumidity');
export const usersPagination = new Pagination('siteUsers');
export const usersLoadingProgress = new LoadingProgress('siteUsers');
export const statsLoadingProgress = new LoadingProgress('sitesStats');

export const notificationsLoadingProgress = new LoadingProgress('notifications');
export const alertsLoadingProgress = new LoadingProgress('alerts');
export const pushNotificationsLoadingProgress = new LoadingProgress('pushNotifications');

export const notificationsPagination = new Pagination('notifications');
export const pushNotificationsPagination = new Pagination('pushNotifications');
export const alertsPagination = new Pagination('alerts');
export const diseaseHistoryLoadingProgress = new LoadingProgress('diseaseHistory');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { adminSites },
    } = payload;
    newState.mergeIn(['entities'], fromJS(adminSites));
  });

const mergeAvailability = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { availabilities },
    } = payload;

    newState.setIn(['availability'], fromJS(availabilities));
  });

const mergeDevices = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { devices },
    } = payload;

    newState.mergeIn(['devices'], fromJS(devices));
  });

const mergeDataUsage = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { dataUsages },
    } = payload;

    newState.mergeIn(['dataUsage'], fromJS(dataUsages));
  });

const mergeLogginActivity = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { loginActivities },
    } = payload;

    newState.mergeIn(['loginActivities'], fromJS(loginActivities));
  });

const mergeWeekly = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { dataUsages },
    } = payload;

    newState.mergeIn(['weeklyDataUsage'], fromJS(dataUsages));
  });

const mergeMonthly = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { dataUsages },
    } = payload;

    newState.mergeIn(['monthlyDataUsage'], fromJS(dataUsages));
  });

const mergeNotifications = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { notifications },
    } = payload;

    newState.mergeIn(['notifications'], fromJS(notifications));
  });

const mergePushNotifications = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { pushNotifications },
    } = payload;

    newState.mergeIn(['pushNotifications'], fromJS(pushNotifications));
  });

const mergeAlerts = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { alerts },
    } = payload;

    newState.mergeIn(['alerts'], fromJS(alerts));
  });

const mergeStatusData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { siteStatuses },
    } = payload;
    newState.mergeIn(['statuses'], fromJS(siteStatuses));
  });

const mergeUsers = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { users },
    } = payload;
    newState.mergeIn(['users'], fromJS(users));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadAvailability = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    mergeAvailability(newState, payload);
    availabilityPagination.set(newState, null, null, fromJS(payload.result));
    availabilityLoadingProgress.setLoaded(newState);
  });

const loadDevices = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeDevices(newState, action.payload);
    devicesPagination.set(newState, total, perPage, fromJS(result));
    deviceLoadingProgress.setLoaded(newState);
  });

const loadDataUsage = (state, action) =>
  state.withMutations((newState) => {
    mergeDataUsage(newState, action.payload);
    dataUsageLoadingProgress.setLoaded(newState);
  });

const loadGateways = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['gateways'], fromJS(action.payload));
    gatewaysLoadingProgress.setLoaded(newState);
  });

const loadLogginActivity = (state, action) =>
  state.withMutations((newState) => {
    mergeLogginActivity(newState, action.payload);
    loginActivityLoadingProgress.setLoaded(newState);
  });

const loadWeeklyDataUsage = (state, action) =>
  state.withMutations((newState) => {
    mergeWeekly(newState, action.payload);
    weeklyLoadingProgress.setLoaded(newState);
  });

const loadMonthlyDataUsage = (state, action) =>
  state.withMutations((newState) => {
    mergeMonthly(newState, action.payload);
    monthlyLoadingProgress.setLoaded(newState);
  });

const loadUpdatedInfo = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    infoUpdateProgress.setLoaded(newState);
  });

const loadNotifications = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeNotifications(newState, action.payload);
    notificationsPagination.set(newState, total, perPage, fromJS(result));
    notificationsLoadingProgress.setLoaded(newState);
  });

const loadPushNotifications = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergePushNotifications(newState, action.payload);
    pushNotificationsPagination.set(newState, total, perPage, fromJS(result));
    pushNotificationsLoadingProgress.setLoaded(newState);
  });

const loadAlerts = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeAlerts(newState, action.payload);
    alertsPagination.set(newState, total, perPage, fromJS(result));
    alertsLoadingProgress.setLoaded(newState);
  });

const loadStatus = (state, action) =>
  state.withMutations((newState) => {
    mergeStatusData(newState, action.payload);
    statusLoadingProgress.setLoaded(newState);
  });

const loadUsers = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeUsers(newState, action.payload);
    usersPagination.set(newState, total, perPage, fromJS(result));
    usersLoadingProgress.setLoaded(newState);
  });

const loadStructure = (state, action) =>
  state.withMutations((newState) => {
    newState.set('structure', action.payload);
    structureLoadingProgress.setLoaded(newState);
  });

const loadStats = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['sitesStats'], fromJS(action.payload));
    statsLoadingProgress.setLoaded(newState);
  });

const infoClear = (state) =>
  state.withMutations((newState) => {
    loginActivityLoadingProgress.clear(newState);
    gatewaysLoadingProgress.clear(newState);
    deviceLoadingProgress.clear(newState);
    notificationsLoadingProgress.clear(newState);
    pushNotificationsLoadingProgress.clear(newState);
    alertsLoadingProgress.clear(newState);
  });

const clearTemperatureHumidity = (state) =>
  state.withMutations((newState) => {
    temperatureHumidityLoadingProgress.clear(newState);
  });

const dataUsageClear = (state) =>
  state.withMutations((newState) => {
    dataUsageLoadingProgress.clear(newState);
    weeklyLoadingProgress.clear(newState);
    monthlyLoadingProgress.clear(newState);
  });

const clearSingleStatus = (state) =>
  state.withMutations((newState) => {
    statusLoadingProgress.clear(newState);
  });

const clearStructure = (state) =>
  state.withMutations((newState) => {
    structureLoadingProgress.clear(newState);
  });

const clearUsers = (state) =>
  state.withMutations((newState) => {
    usersLoadingProgress.clear(newState);
    usersPagination.clear(newState);
  });

const availabilityClear = (state) =>
  state.withMutations((newState) => {
    availabilityLoadingProgress.clear(newState);
  });

const loadRDI = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['rdi'], fromJS(action.payload));
    rdiLoadingProgress.setLoaded(newState);
  });

const updateEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    listLoadingProgress.setLoaded(newState);
  });

const rdiClear = (state) =>
  state.withMutations((newState) => {
    rdiLoadingProgress.clear(newState);
  });

const clearDevices = (state) =>
  state.withMutations((newState) => {
    devicesPagination.clear(newState);
    deviceLoadingProgress.clear(newState);
  });

const loadTemperatureHumidity = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['temperatureHumidity'], fromJS(action.payload));
    temperatureHumidityLoadingProgress.setLoaded(newState);
  });

const clearCharts = (state) =>
  state.withMutations((newState) => {
    rdiClear(newState);
    availabilityClear(newState);
    dataUsageClear(newState);
    clearTemperatureHumidity(newState);
  });

const loadAirspacesStatuses = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['airspacesStatuses'], fromJS(action.payload));
    airspacesStatusesLoadingProgress.setLoaded(newState);
  });

const airspacesStatusesClear = (state) =>
  state.withMutations((newState) => {
    airspacesStatusesLoadingProgress.clear(newState);
  });

const clearStats = (state) =>
  state.withMutations((newState) => {
    statsLoadingProgress.clear(newState);
  });

const loadDiseaseHistory = (state, action) =>
  state.withMutations((newState) => {
    newState.set('diseaseHistory', fromJS(action.payload));
    diseaseHistoryLoadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
    loadingProgress.clear(newState);
    itemsPagination.clear(newState);
    devicesPagination.clear(newState);
    clearCharts(newState);
    clearStructure(newState);
    infoClear(newState);
    clearSingleStatus(newState);
    rdiClear(newState);
    airspacesStatusesClear(newState);
    clearUsers(newState);
  });

const initialState = fromJS({
  entities: {},
  availability: {},
  devices: {},
  notifications: {},
  statuses: {},
  gateways: {},
  dataUsage: {},
  weeklyDataUsage: {},
  monthlyDataUsage: {},
  rdi: {},
  temperatureHumidity: {},
  airspacesStatuses: {},
  diseaseHistory: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.AVAILABILITY_LOAD_START:
      return availabilityLoadingProgress.setLoading(state);
    case constants.AVAILABILITY_LOAD_FAILED:
      return availabilityLoadingProgress.setLoadFailed(state);
    case constants.AVAILABILITY_LOAD_SUCCESS:
      return loadAvailability(state, action);
    case constants.DEVICE_LOAD_START:
      return deviceLoadingProgress.setLoading(state);
    case constants.DEVICE_LOAD_FAILED:
      return deviceLoadingProgress.setLoadFailed(state);
    case constants.DEVICE_LOAD_SUCCESS:
      return loadDevices(state, action);
    case constants.DATA_USAGE_LOAD_START:
      return dataUsageLoadingProgress.setLoading(state);
    case constants.DATA_USAGE_LOAD_FAILED:
      return dataUsageLoadingProgress.setLoadFailed(state);
    case constants.DATA_USAGE_LOAD_SUCCESS:
      return loadDataUsage(state, action);
    case constants.GATEWAYS_LOAD_START:
      return gatewaysLoadingProgress.setLoading(state);
    case constants.GATEWAYS_LOAD_FAILED:
      return gatewaysLoadingProgress.setLoadFailed(state);
    case constants.GATEWAYS_LOAD_SUCCESS:
      return loadGateways(state, action);
    case constants.USERS_ACTIVITY_LOAD_START:
      return loginActivityLoadingProgress.setLoading(state);
    case constants.USERS_ACTIVITY_LOAD_FAILED:
      return loginActivityLoadingProgress.setLoadFailed(state);
    case constants.USERS_ACTIVITY_LOAD_SUCCESS:
      return loadLogginActivity(state, action);
    case constants.WEEKLY_LOAD_START:
      return weeklyLoadingProgress.setLoading(state);
    case constants.WEEKLY_LOAD_FAILED:
      return weeklyLoadingProgress.setLoadFailed(state);
    case constants.WEEKLY_LOAD_SUCCESS:
      return loadWeeklyDataUsage(state, action);
    case constants.MONTHLY_LOAD_START:
      return monthlyLoadingProgress.setLoading(state);
    case constants.MONTHLY_LOAD_FAILED:
      return monthlyLoadingProgress.setLoadFailed(state);
    case constants.MONTHLY_LOAD_SUCCESS:
      return loadMonthlyDataUsage(state, action);
    case constants.INFO_UPDATE_START:
      return infoUpdateProgress.setLoading(state);
    case constants.INFO_UPDATE_FAILED:
      return infoUpdateProgress.setLoadFailed(state);
    case constants.INFO_UPDATE_SUCCESS:
      return loadUpdatedInfo(state, action);
    case constants.STATUS_LOAD_START:
      return statusLoadingProgress.setLoading(state);
    case constants.STATUS_LOAD_FAILED:
      return statusLoadingProgress.setLoadFailed(state);
    case constants.STATUS_LOAD_SUCCESS:
      return loadStatus(state, action);
    case constants.STRUCTURE_LOAD_START:
      return structureLoadingProgress.setLoading(state);
    case constants.STRUCTURE_LOAD_SUCCESS:
      return loadStructure(state, action);
    case constants.STRUCTURE_LOAD_FAILED:
      return structureLoadingProgress.setLoadFailed(state);
    case constants.SITE_TEMPERATURE_HUMIDITY_LOAD_START:
      return temperatureHumidityLoadingProgress.setLoading(state);
    case constants.SITE_TEMPERATURE_HUMIDITY_LOAD_SUCCESS:
      return loadTemperatureHumidity(state, action);
    case constants.SITE_TEMPERATURE_HUMIDITY_LOAD_FAILED:
      return temperatureHumidityLoadingProgress.setLoadFailed(state);
    case constants.INFO_CLEAR:
      return infoClear(state);
    case constants.AVAILABILITY_CLEAR:
      return availabilityClear(state);
    case constants.DATA_USAGE_CLEAR:
      return dataUsageClear(state);
    case constants.CLEAR_SINGLE_STATUS:
      return clearSingleStatus(state);
    case constants.CLEAR_STRUCTURE:
      return clearStructure(state);
    case constants.CLEAR_SITE_TEMPERATURE_HUMIDITY:
      return clearTemperatureHumidity(state);
    case constants.RDI_LOAD_START:
      return rdiLoadingProgress.setLoading(state);
    case constants.RDI_LOAD_FAILED:
      return rdiLoadingProgress.setLoadFailed(state);
    case constants.RDI_LOAD_SUCCESS:
      return loadRDI(state, action);
    case constants.RDI_CLEAR:
      return rdiClear(state);
    case constants.AIRSPACES_STATUSES_LOAD_START:
      return airspacesStatusesLoadingProgress.setLoading(state);
    case constants.AIRSPACES_STATUSES_LOAD_FAILED:
      return rdiLoadingProgress.setLoadFailed(state);
    case constants.AIRSPACES_STATUSES_LOAD_SUCCESS:
      return loadAirspacesStatuses(state, action);
    case constants.AIRSPACES_STATUSES_CLEAR:
      return airspacesStatusesClear(state);
    case constants.CHARTS_CLEAR:
      return clearCharts(state);

    case constants.USERS_LOAD_START:
      return usersLoadingProgress.setLoading(state);
    case constants.USERS_LOAD_FAILED:
      return usersLoadingProgress.setLoadFailed(state);
    case constants.USERS_LOAD_SUCCESS:
      return loadUsers(state, action);

    case constants.DEVICES_CLEAR:
      return clearDevices(state);

    case constants.UPDATE_START:
      return listLoadingProgress.setLoading(state);
    case constants.UPDATE_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.UPDATE_SUCCESS:
      return updateEntity(state, action);

    case constants.STATS_LOAD_START:
      return statsLoadingProgress.setLoading(state);
    case constants.STATS_LOAD_FAILED:
      return statsLoadingProgress.setLoadFailed(state);
    case constants.STATS_LOAD_SUCCESS:
      return loadStats(state, action);

    case constants.ALERTS_LIST_LOAD_START:
      return alertsLoadingProgress.setLoading(state);
    case constants.ALERTS_LIST_LOAD_FAILED:
      return alertsLoadingProgress.setLoadFailed(state);
    case constants.ALERTS_LIST_LOAD_SUCCESS:
      return loadAlerts(state, action);

    case constants.NOTIFICATIONS_LIST_LOAD_START:
      return notificationsLoadingProgress.setLoading(state);
    case constants.NOTIFICATIONS_LIST_LOAD_FAILED:
      return notificationsLoadingProgress.setLoadFailed(state);
    case constants.NOTIFICATIONS_LIST_LOAD_SUCCESS:
      return loadNotifications(state, action);

    case constants.PUSH_NOTIFICATIONS_LIST_LOAD_START:
      return pushNotificationsLoadingProgress.setLoading(state);
    case constants.PUSH_NOTIFICATIONS_LIST_LOAD_FAILED:
      return pushNotificationsLoadingProgress.setLoadFailed(state);
    case constants.PUSH_NOTIFICATIONS_LIST_LOAD_SUCCESS:
      return loadPushNotifications(state, action);

    case constants.DISEASE_HISTORY_LOAD_START:
      return diseaseHistoryLoadingProgress.setLoading(state);
    case constants.DISEASE_HISTORY_LOAD_SUCCESS:
      return loadDiseaseHistory(state, action);
    case constants.DISEASE_HISTORY_LOAD_FAILED:
      return diseaseHistoryLoadingProgress.setLoadFailed(state);

    case constants.CLEAR_STATS:
      return clearStats(state, action);

    case constants.CLEAR:
      return clear(state);
    default:
      return state;
  }
};
