/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/licenses';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('adminLicenses');
export const listLoadingProgress = new LoadingProgress('adminLicensesList');
export const listPagination = new Pagination('adminLicenses');

export const orderLoadingProgress = new LoadingProgress('adminOrder');
export const ordersListLoadingProgress = new LoadingProgress('adminOrdersList');
export const ordersPagination = new Pagination('siteOrders');

export const licenseTemplateLoadingProgress = new LoadingProgress('licenseTemplate');
export const licenseTemplatesListLoadingProgress = new LoadingProgress('licenseTemplatesList');
export const licenseTemplatesPagination = new Pagination('licenseTemplate');

export const licenseCreationLoadingProgress = new LoadingProgress('licenseCreation');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { licenses },
    } = payload;
    newState.mergeIn(['entities'], fromJS(licenses));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeData(newState, action.payload);
    newState.set('list', fromJS(action.payload.result));
    listPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const mergeOrdersData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { orders },
    } = payload;
    newState.mergeIn(['ordersEntities'], fromJS(orders));
  });

const loadOrdersList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeOrdersData(newState, action.payload);
    ordersPagination.set(newState, total, perPage, fromJS(result));
    ordersListLoadingProgress.setLoaded(newState);
  });

const mergeLicenseTemplatesData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { licenseTemplates },
    } = payload;
    newState.mergeIn(['licenseTemplateEntities'], fromJS(licenseTemplates));
  });

const loadLicenseTemplatesList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeLicenseTemplatesData(newState, action.payload);
    licenseTemplatesPagination.set(newState, total, perPage, fromJS(result));
    licenseTemplatesListLoadingProgress.setLoaded(newState);
  });

const loadOrderEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeOrdersData(newState, action.payload);
    orderLoadingProgress.setLoaded(newState);
  });

const clearOrdersList = (state) =>
  state.withMutations((newState) => {
    ordersListLoadingProgress.clear(newState);
    ordersPagination.clear(newState);
  });

const clearOrder = (state) =>
  state.withMutations((newState) => {
    orderLoadingProgress.clear(newState);
  });

const clearLicenseTemplates = (state) =>
  state.withMutations((newState) => {
    licenseTemplatesListLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
  ordersEntities: {},
  licenseTemplateEntities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.ORDERS_LIST_LOAD_START:
      return ordersListLoadingProgress.setLoading(state);
    case constants.ORDERS_LIST_LOAD_FAILED:
      return ordersListLoadingProgress.setLoadFailed(state);
    case constants.ORDERS_LIST_LOAD_SUCCESS:
      return loadOrdersList(state, action);

    case constants.LICENSE_TEMPLATES_LIST_LOAD_START:
      return licenseTemplatesListLoadingProgress.setLoading(state);
    case constants.LICENSE_TEMPLATES_LIST_LOAD_FAILED:
      return licenseTemplatesListLoadingProgress.setLoadFailed(state);
    case constants.LICENSE_TEMPLATES_LIST_LOAD_SUCCESS:
      return loadLicenseTemplatesList(state, action);

    case constants.ORDER_LOAD_START:
      return orderLoadingProgress.setLoading(state);
    case constants.ORDER_LOAD_FAILED:
      return orderLoadingProgress.setLoadFailed(state);
    case constants.ORDER_LOAD_SUCCESS:
      return loadOrderEntity(state, action);

    case constants.ORDERS_CLEAR:
      return clearOrdersList(state);

    case constants.ORDER_CLEAR:
      return clearOrder(state);

    case constants.LICENSE_TEMPALTES_CLEAR:
      return clearLicenseTemplates(state);

    case constants.LICENSE_ADD_START:
      return licenseCreationLoadingProgress.setLoading(state);
    case constants.LICENSE_ADD_FAILED:
      return licenseCreationLoadingProgress.setLoadFailed(state);
    case constants.LICENSE_ADD_SUCCESS:
      return licenseCreationLoadingProgress.setLoaded(state);

    default:
      return state;
  }
};
