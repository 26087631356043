/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nBackendLoadPath } from 'config';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format as formatDate, isDate } from 'date-fns';
import { isChinaConfig } from 'config';

import { localeToFormat } from 'utils/language-helpers';
import { getLanguage, locales } from 'utils/language-helpers';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: isChinaConfig ? 'cn' : 'en-US',

    interpolation: {
      // escapeValue: false, // not needed for react as it escapes by default
      // eslint-disable-next-line
      format: (value, format, ...props) => {
        if (isDate(value)) {
          const stringLocale = props[0] || getLanguage();
          const locale = locales[stringLocale] || locales.enUS;
          return formatDate(value, localeToFormat(stringLocale)[format], { locale });
        }
      },
    },

    backend: {
      loadPath: i18nBackendLoadPath,
    },
  });

export default i18n;
