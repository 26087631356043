/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';

class GlobalErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    history: RouterPropTypes.history.isRequired,
  };

  componentDidCatch() {
    if (process.env.NODE_ENV === 'production') this.props.history.push('/');
  }

  render() {
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
