/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import {
  tokenLoading,
  refreshLoading,
  filesLoading,
  helpInfoLoading,
  privacyLoading,
  dataPrivacyLoading,
} from 'data/reducers/session';

const getState = (store) => store.session;

export const getAccess = (store) => getState(store).get('access');

export const getPrivacy = (store) => getState(store).get('privacy');

export const getPrivacyLoaded = (store) => privacyLoading.getLoaded(getState(store));

export const getDataPrivacy = (store) => getState(store).get('dataPrivacy');

export const getDataPrivacyLoaded = (store) => dataPrivacyLoading.getLoaded(getState(store));

export const getSessionActive = (store) =>
  tokenLoading.getLoaded(getState(store)) || refreshLoading.getLoaded(getState(store));

export const getRefresh = (store) => getState(store).get('refresh');

export const getRefreshSuccess = (store) => refreshLoading.getLoaded(getState(store));

export const getRefreshLoading = (store) => refreshLoading.getLoading(getState(store));

export const getRefreshFailed = (store) => refreshLoading.getLoadFailed(getState(store));

export const getFiles = (store) => getState(store).get('files');

export const getFilesLoaded = (store) => filesLoading.getLoaded(getState(store));

export const getStaticPageContent = (store) => getState(store).get('helpInfo');

export const getStaticPageContentLoaded = (store) => helpInfoLoading.getLoaded(getState(store));
