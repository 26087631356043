/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';

import PropTypes from 'prop-types';
import { connect, actions, selectors } from 'data';
import { withTranslation } from 'react-i18next';

import ImprintPage from 'components/shared/help';
import { FluidPreloader } from 'components/core/preloader';

const mapStateToProps = (state) => ({
  imprint: selectors.session.getStaticPageContent(state),
  imprintLoaded: selectors.session.getStaticPageContentLoaded(state),
});

const mapDispatchToProps = {
  fetchImprintInfo: actions.session.fetchPage,
};

class Imprint extends React.Component {
  static propTypes = {
    fetchImprintInfo: PropTypes.func.isRequired,
    imprintLoaded: PropTypes.bool,
    imprint: PropTypes.shape({}),
  };

  static defaultProps = { imprintLoaded: false, imprint: null };

  componentDidMount() {
    this.props.fetchImprintInfo('imprint');
  }

  render() {
    return this.props.imprintLoaded ? <ImprintPage data={this.props.imprint} /> : <FluidPreloader />;
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Imprint));
