/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { colors, sizes, fontWeight } from 'ui';
import { withTranslation } from 'react-i18next';

const customStyles = (error = false) => ({
  container: (styles, { selectProps }) => ({
    ...styles,
    zIndex: selectProps.menuIsOpen ? 999 : 2,
    marginBottom: sizes.base / 4,
    marginTop: sizes.base / 4,
  }),
  control: (base, { selectProps }) => ({
    ...base,
    backgroundColor: 'white',
    borderColor: error ? colors.error : colors.border,
    borderRadius: 8,
    cursor: 'pointer',
    minHeight: 48,
    outline: 'none',
    zIndex: selectProps.menuIsOpen ? 999 : 2,

    '&:hover': {
      borderColor: error ? colors.error : colors.border,
    },
    '&:focus-within': {
      cursor: 'default',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: colors.secondary,
    '&:hover': {
      color: colors.secondary,
    },
  }),
  option: (styles, { selectProps, isDisabled }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    zIndex: selectProps.menuIsOpen ? 999 : 2,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'gray',
    display: 'none',
  }),
  input: (styles) => ({
    ...styles,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.secondary,
    fontWeight: fontWeight.regular,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors.primary,
    lineHeight: 1.2,
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 14,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 100,
  }),
});

const customDarkStyles = (error = false) => ({
  container: (styles, { selectProps }) => ({
    ...styles,
    zIndex: selectProps.menuIsOpen ? 999 : 2,
    marginBottom: sizes.base / 4,
    marginTop: sizes.base / 4,
  }),
  control: (base, { selectProps }) => ({
    ...base,
    backgroundColor: colors.background,
    borderColor: 'transparent',
    borderRadius: 8,
    minHeight: 48,
    outline: 'none',
    zIndex: selectProps.menuIsOpen ? 999 : 2,
    cursor: 'pointer',

    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: colors.secondary,
    '&:hover': {
      color: colors.secondary,
    },
  }),
  option: (styles, { selectProps, isDisabled, isSelected }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    zIndex: selectProps.menuIsOpen ? 999 : 2,
    backgroundColor: isSelected ? colors.primary : colors.background,
    color: isSelected ? colors.background : colors.primary,
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.background,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: colors.background,
  }),
  input: (styles) => ({
    ...styles,
    color: colors.primary,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.secondary,
    fontWeight: fontWeight.regular,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: colors.primary,
    lineHeight: 1.2,
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'none',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 14,
  }),
});

const Input = ({ selectProps, ...props }) => {
  return React.createElement(components.Input, Object.assign({ 'data-cy': selectProps['data-cy'] }, props));
};

Input.propTypes = {
  props: PropTypes.shape({}).isRequired,
  selectProps: PropTypes.shape({ 'data-cy': PropTypes.string }).isRequired,
};

const createInput = (type) => {
  class MySelect extends React.Component {
    static type = type;

    static propTypes = {
      core: PropTypes.node,
      error: PropTypes.string,
      styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      t: PropTypes.func.isRequired,
    };

    static defaultProps = {
      core: null,
      error: null,
      styles: null,
    };

    render() {
      const { error, core, styles, t, ...props } = this.props;
      const Component = core || Select;
      const componentStyles = Object.assign(
        {},
        type.name === 'dark' ? customDarkStyles(error) : customStyles(error),
        typeof styles === 'function' ? styles(error) : styles || {},
      );

      return (
        <Component
          {...props}
          styles={componentStyles}
          components={{ Input }}
          noOptionsMessage={() => t('no_options')}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              text: colors.primary,
              primary25: colors.border,
              primary: colors.primary,
            },
          })}
        />
      );
    }
  }
  const component = withTranslation()(MySelect);
  component.type = type;
  return component;
};

const dark = {
  name: 'dark',
  style: {
    errorText: {
      color: colors.white,
    },
  },
};

const light = {
  name: 'light',
  style: {
    errorText: {
      color: colors.error,
    },
  },
};

export const LightSelect = createInput(light);
export const DarkSelect = createInput(dark);
