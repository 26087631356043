/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useNetworkStatus = () => {
  const { t } = useTranslation();

  const handleOnline = () => toast.success(t('back_online'));
  const handleOffline = () => toast.error(t('no_internet_connection'));

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });
};

export default useNetworkStatus;
