/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/devices';
import * as Sentry from '@sentry/react';

export const fetchList =
  ({ siteId, page = 1, perPage = 10, isMobile, lazy = false, filter = '', q = '', orderingField, order, airspace }) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const params = isMobile
        ? { params: { q, filter } }
        : { params: { page, perPage, q, filter, ordering_field: orderingField, order, airspace } };
      const res = await api.get(`/sites/${siteId}/devices/`, params);

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.device]),
        lazy,
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (site, id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/devices/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.device),
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchGatewaysList =
  ({ site, filter = '', orderingField, order }) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.GATEWAYS_LIST_LOAD_START });
    try {
      const res = await api.get(`/sites/${site}/gateways/`, {
        params: { filter, ordering_field: orderingField, order },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.gateway]),
      };

      dispatch({
        type: constants.GATEWAYS_LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.GATEWAYS_LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const clear = () => ({ type: constants.CLEAR });
