/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PrimaryButton, Button } from 'ui';
import Icon from 'components/core/icon';

import { FluidPreloader } from 'components/core/preloader';

import s from './styles.scss';

const Modal = ({
  show,
  title,
  content,
  isConfirmModal,
  onClose,
  onConfirm,
  closeButtonText,
  confirmButtonText,
  dataAttribute,
  dataConfirmAttribute,
  isSubmitting,
}) => {
  const modalRef = React.createRef();
  const { t } = useTranslation();
  const handleOutsideCLick = (e) => {
    if (e.target !== modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  if (!show) {
    return null;
  }

  return createPortal(
    <button type="button" className={s.modalBack} onClick={handleOutsideCLick} data-cy={dataAttribute}>
      <div className={s.modal} ref={modalRef}>
        <div className={s.modalHeader}>
          <h2 className={s.modalHeaderTitle}>{title}</h2>
          {onClose && (
            <button type="button" className={s.modalHeaderClose} onClick={onClose}>
              <Icon icon={Icon.icons.cross} className={s.icon} />
            </button>
          )}
        </div>
        <div className={s.modalBody}>{content}</div>
        <div className={s.modalFooter}>
          {isSubmitting ? (
            <FluidPreloader />
          ) : (
            <>
              {onClose && (
                <Button type="button" onClick={onClose} className={s.modalButton}>
                  {closeButtonText || t('cancel')}
                </Button>
              )}
              {isConfirmModal && (
                <PrimaryButton onClick={onConfirm} className={s.modalButton} data-cy={dataConfirmAttribute}>
                  {confirmButtonText || t('ok')}
                </PrimaryButton>
              )}
            </>
          )}
        </div>
      </div>
    </button>,
    document.querySelector('#modal-root'),
  );
};

Modal.defaultProps = {
  show: false,
  isConfirmModal: false,
  onConfirm: null,
  confirmButtonText: null,
  closeButtonText: null,
  dataAttribute: null,
  dataConfirmAttribute: null,
  isSubmitting: false,
};

Modal.propTypes = {
  show: PropTypes.bool,
  isConfirmModal: PropTypes.bool,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  confirmButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  dataAttribute: PropTypes.string,
  dataConfirmAttribute: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default Modal;
