/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { withTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect, selectors, actions, ImmutablePropTypes } from 'data';
import { isChinaConfig } from 'config';
import { getLanguage } from 'utils/language-helpers';
import Error from 'components/core/error';

import {
  basePath,
  signInPath,
  signOutPath,
  registrationPath,
  registrationSuccessPath,
  restorePasswordPath,
  recoverPasswordPath,
  restorePasswordSuccessPath,
  contactUsPath,
  contactUsSuccessPath,
  installationPath,
  termsConditionsPath,
  dataPrivacyPath,
  imprintPath,
  baseErrorPath,
  helpPath,
  notFoundPath,
} from 'constants/urls';

import SignIn from 'containers/shared/sign-in';
import SignOut from 'containers/shared/sign-out';
import RestorePassword from 'containers/shared/restore-password';
import RecoverPassword from 'containers/shared/recover-password';
import RestorePasswordSuccess from 'components/shared/restore-password-success';
import Registration from 'containers/shared/registration';
import RegistrationSuccess from 'components/shared/registration-success';
import ContactUs from 'containers/shared/contact-us';
import ContactUsSuccess from 'components/shared/contact-us-success';
import Installation from 'components/shared/installation';
import Help from 'containers/shared/help';
import TermsAndConditions from 'containers/shared/terms-conditions';
import DataPrivacy from 'containers/shared/data-privacy';
import Imprint from 'containers/shared/imprint';
import SharedLayout from 'components/shared/layout';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';

const mapStateToProps = (state) => ({
  countries: selectors.profile.getCountries(state),
  countriesLoaded: selectors.profile.getCountriesLoaded(state),
  countriesLoading: selectors.profile.getCountriesLoading(state),
  languages: selectors.profile.getLanguages(state),
  languagesLoaded: selectors.profile.getLanguagesLoaded(state),
});

const mapDispatchToProps = {
  fetchCountries: actions.profile.fetchCountries,
  fetchLanguages: actions.profile.fetchLanguages,
  changeLanguage: actions.profile.changeLanguage,
};

class Shared extends React.Component {
  state = {
    selectedCountry: { label: 'Belgium', value: 'BE' },
  };

  async componentDidMount() {
    await this.props.fetchLanguages();
    const detectedLang = this.props.languages
      .find((lang) => lang.get('value') === getLanguage().toLowerCase())
      ?.get('value');

    if (!detectedLang) await this.handleSelectLanguage(isChinaConfig ? 'cn' : 'en-US');

    if (!this.props.countriesLoading) this.props.fetchCountries();
  }

  handleSelectLanguage = async (language) => {
    await this.props.changeLanguage(language);
    this.props.i18n.changeLanguage(language);
  };

  handleHeaderLanguageChange = async (language) => {
    if (language !== getLanguage()) {
      await this.handleSelectLanguage(language);
      await this.props.fetchCountries();

      const translatedCountry = this.props.countries
        .toJS()
        .find((item) => item.country_code === this.state.selectedCountry.value);

      this.setState({ selectedCountry: { label: translatedCountry.name, value: translatedCountry.country_code } });
    }
  };

  getSelectedLanguage = () => this.props.languages?.toJS().find((lang) => lang.value === getLanguage().toLowerCase());

  handleSelectCountry = (country) => this.setState({ selectedCountry: country });

  componentDidCatch(error, errorInfo) {
    this.props.history.push({ pathname: baseErrorPath, state: { error, errorInfo } });
  }

  render() {
    return (
      <SharedLayout
        header={
          <Header
            countries={this.props.countries}
            selectedCountry={this.state.selectedCountry}
            handleSelectCountry={this.handleSelectCountry}
            languages={this.props.languages}
            selectedLanguage={this.getSelectedLanguage()}
            handleSelectLanguage={this.handleHeaderLanguageChange}
            location={this.props.history.location}
            countriesLoaded={this.props.countriesLoaded}
            languagesLoaded={this.props.languagesLoaded}
          />
        }
        footer={<Footer />}
      >
        <Switch>
          <Route exact path={signInPath} component={SignIn} />
          <Route exact path={signOutPath} component={SignOut} />
          <Route exact path={contactUsPath} component={ContactUs} />
          <Route exact path={contactUsSuccessPath} component={ContactUsSuccess} />
          <Route exact path={restorePasswordPath} component={RestorePassword} />
          <Route exact path={recoverPasswordPath} component={RecoverPassword} />
          <Route exact path={restorePasswordSuccessPath} component={RestorePasswordSuccess} />
          <Route
            exact
            path={registrationPath}
            render={(props) => <Registration handleSelectCountry={this.handleSelectCountry} {...props} />}
          />
          <Route exact path={registrationSuccessPath} component={RegistrationSuccess} />
          <Route exact path={installationPath} component={Installation} />
          <Route exact path={helpPath} component={Help} />
          <Route
            exact
            path={termsConditionsPath}
            render={(props) => <TermsAndConditions country={this.state.selectedCountry.value} {...props} />}
          />
          <Route
            exact
            path={dataPrivacyPath}
            render={(props) => <DataPrivacy country={this.state.selectedCountry.value} {...props} />}
          />
          <Route exact path={imprintPath} component={Imprint} />

          <Route exact path={baseErrorPath} component={Error} />

          <Redirect exact from={basePath} to={signInPath} />
          <Redirect to={notFoundPath} />
        </Switch>
      </SharedLayout>
    );
  }
}

Shared.propTypes = {
  countries: ImmutablePropTypes.list,
  fetchCountries: PropTypes.func.isRequired,
  countriesLoaded: PropTypes.bool,
  countriesLoading: PropTypes.bool,
  languages: ImmutablePropTypes.list,
  fetchLanguages: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  languagesLoaded: PropTypes.bool,
  history: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};

Shared.defaultProps = {
  countries: null,
  countriesLoaded: false,
  countriesLoading: false,
  languages: null,
  languagesLoaded: false,
};

export default hot(module)(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Shared)));
