/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/constants';
import { siteStatuses as defaultStatuses, siteTypes as defaultTypes } from 'data/constants/app';

import LoadingProgress from 'data/utils/reducers/loading';

export const loadingProgress = new LoadingProgress('constants');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      statuses: {
        entities: { currentSiteStatuses },
      },
      types: {
        entities: { siteTypes },
      },
    } = payload;
    newState.mergeIn(['statuses'], fromJS(currentSiteStatuses));
    newState.mergeIn(['types'], fromJS(siteTypes));
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    loadingProgress.clear(newState);
  });

const initialState = fromJS({
  statuses: defaultStatuses,
  types: defaultTypes,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.CLEAR:
      return clear(state);
    // Other entities loaded
    default:
      return state;
  }
};
