/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './styles.scss';

const createRoundedButton = (initClassName) => {
  const Button = ({ className, children, active, ...props }) => (
    <button className={cx(s.roundedButton, initClassName, active && s.roundedButtonActive, className)} {...props}>
      {typeof children === 'string' ? <span className={cx(s.text, active && s.textActive)}>{children}</span> : children}
    </button>
  );

  Button.defaultProps = {
    className: null,
    active: false,
  };

  Button.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  return Button;
};

export const RoundedButton = createRoundedButton();
export const PrimaryRoundedButton = createRoundedButton(s.roundedButtonPrimary);
