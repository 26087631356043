/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { combineReducers } from 'redux';
import * as sessionConstants from 'data/constants/session';
import session from './session';
import sites from './sites';
import siteCreation from './site-creation';
import groups from './groups';
import devices from './devices';
import notifications from './notifications';
import users from './users';
import profile from './profile';
import api from './api';
import alerts from './alerts';
import airspaces from './airspaces';
import constants from './constants';
import recommendations from './recommendations';
import batches from './batches';
import adminSites from './admin/sites';
import adminUsers from './admin/users';
import adminDevices from './admin/devices';
import adminGateways from './admin/gateways';
import adminAirspaces from './admin/airspaces';
import adminSuppliers from './admin/suppliers';
import adminLicenses from './admin/licenses';
import adminProfile from './admin/profile';
import adminGroups from './admin/groups';

export const entities = {
  sites,
  groups,
  devices,
  notifications,
  users,
  profile,
  alerts,
  airspaces,
  constants,
  siteCreation,
  recommendations,
  batches,
};

export const adminEntities = {
  adminSites,
  adminUsers,
  adminDevices,
  adminGateways,
  adminAirspaces,
  adminSuppliers,
  adminLicenses,
  adminProfile,
  adminGroups,
};

const generalReducer = combineReducers({
  session,
  api,
  ...entities,
  ...adminEntities,
});

const rootReducer = (state, action) => {
  if (action.type === sessionConstants.LOGOUT) {
    return generalReducer(undefined, action);
  }
  return generalReducer(state, action);
};

export default rootReducer;
