/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/site-creation';
import * as sitesConstants from 'data/constants/sites';

const initialValues = {
  editPage: 0,
  page: 0,
  values: {
    name: '',
    type: '',
    animals_quantity: '',
    country: null,
    state: '',
    postal_code: '',
    city: '',
    street: '',
    house_number: '',
    longitude: '',
    latitude: '',
    premise_id: '',
    users_to_add: [],
    houses: [
      {
        name: 'House 1',
        children: [
          {
            children: [
              {
                children: [
                  {
                    devices: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    gateways: [],
    feedback: {
      score: 0,
      description: 'Some temporary description',
    },
  },
};

const setPage = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['entities', 'page'], fromJS(action.payload));
  });

const setEditPage = (state, action) =>
  state.withMutations((newState) => {
    newState.setIn(['entities', 'editPage'], fromJS(action.payload));
  });

const setValues = (state, action) =>
  state.withMutations((newState) => {
    newState.mergeIn(['entities', 'values'], fromJS(action.payload));
  });

const resetValues = (state) =>
  state.withMutations((newState) => {
    newState.setIn(['entities'], fromJS(initialValues));
  });

const initialState = fromJS({
  entities: initialValues,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_PAGE:
      return setPage(state, action);
    case constants.SET_VALUES:
      return setValues(state, action);
    case sitesConstants.CREATION_SUCCESS:
    case constants.RESET_VALUES:
      return resetValues(state);
    case constants.SET_EDIT_PAGE:
      return setEditPage(state, action);

    default:
      return state;
  }
};
