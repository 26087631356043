/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Button, PrimaryButton } from 'ui';
import HeaderShared from 'components/shared/layout/header';
import { dataPrivacyPath } from 'constants/urls';
import logo from 'public/logo.png';
import Carousel from '../carousel';

import s from './styles.scss';

const Step3 = ({ values, setFieldValue, handleSubmit, isSubmitting, ...props }) => {
  const { t } = useTranslation();

  const handleClick = async (value) => {
    await setFieldValue('consent_newsletter', value);
    handleSubmit(values, props);
  };

  return (
    <div className={s.permissions}>
      <HeaderShared>
        <div className={s.permissionsLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <Carousel length={2} index={1}>
        <>
          <h1 className={s.permissionsTitle}>{t('consent_newsletter_title')}</h1>
          <div className={s.permissionsBody}>
            <Trans i18nKey="consent_newsletter_shared">
              <Link to={dataPrivacyPath} className={s.permissionLabelLink} />
            </Trans>
          </div>
          <div className={s.permissionsButtons}>
            <Button
              type="button"
              className={s.permissionsButtonsBtn}
              onClick={() => handleClick(false)}
              isDisabled={isSubmitting}
            >
              {t('no_disagree')}
            </Button>
            <PrimaryButton
              type="button"
              className={s.permissionsButtonsBtn}
              onClick={() => handleClick(true)}
              isDisabled={isSubmitting}
            >
              {t('yes_agree')}
            </PrimaryButton>
          </div>
        </>
      </Carousel>
    </div>
  );
};

Step3.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
export default Step3;
