/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { utcToZonedTime, format } from 'date-fns-tz';
import { getLanguage, localeToFormat, locales } from 'utils/language-helpers';

export const timeZoneFormatter = (date, timeZone, formatPattern = 'full_day_with_time', extraFormat) => {
  const newTimeZone = timeZone || 'UTC';

  const zonedDate = utcToZonedTime(date, newTimeZone);

  const locale = locales[getLanguage()] || locales.enUS;
  if (extraFormat) {
    return format(zonedDate, extraFormat, { timeZone: newTimeZone, locale });
  }
  const localizedFormat = localeToFormat(getLanguage())[formatPattern];

  return format(zonedDate, localizedFormat, { timeZone: newTimeZone, locale });
};
