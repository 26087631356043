/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/devices';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const itemsPagination = new Pagination('device');
export const gatewaysItemsPagination = new Pagination('gateway');
export const loadingProgress = new LoadingProgress('device');
export const listLoadingProgress = new LoadingProgress('deviceList');
export const gatewaysListLoadingProgress = new LoadingProgress('gatewaysList');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { devices },
    } = payload;
    newState.mergeIn(['entities'], fromJS(devices));
  });

const mergeGatewaysData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { gateways },
    } = payload;
    newState.mergeIn(['gateways'], fromJS(gateways));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total, lazy } = action.payload;
    mergeData(newState, action.payload);
    if (lazy) {
      newState.set('devicePageItems', state.get('devicePageItems').concat(fromJS(result)));
    } else {
      itemsPagination.set(newState, total, perPage, fromJS(result));
    }
    listLoadingProgress.setLoaded(newState);
  });

const loadGatewaysList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeGatewaysData(newState, action.payload);
    gatewaysItemsPagination.set(newState, total, perPage, fromJS(result));
    gatewaysListLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    loadingProgress.clear(newState);
    listLoadingProgress.clear(newState);
    itemsPagination.clear(newState);
    gatewaysListLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
  gateways: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.GATEWAYS_LIST_LOAD_START:
      return gatewaysListLoadingProgress.setLoading(state);
    case constants.GATEWAYS_LIST_LOAD_FAILED:
      return gatewaysListLoadingProgress.setLoadFailed(state);
    case constants.GATEWAYS_LIST_LOAD_SUCCESS:
      return loadGatewaysList(state, action);

    case constants.CLEAR:
      return clear(state);
    // Other entities loaded
    default:
      return state;
  }
};
