/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { signInPath, registrationPath } from 'constants/urls';
import logo from 'public/logo.png';
import { getLanguage } from 'utils/language-helpers';
import AppStoreBadge from 'components/core/markets/app-store';
import GooglePlayBadge from 'components/core/markets/google-play';
import FooterShared from './layout/footer';
import HeaderShared from './layout/header';
import s from './styles.scss';

const Installation = ({ className }) => {
  const { t } = useTranslation();

  const language = getLanguage()?.toLowerCase();

  return (
    <div className={cx(s.sharedForm, className, s.sharedFormSignIn)}>
      <HeaderShared className={s.sharedFormHeader}>
        <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
      </HeaderShared>
      <div className={s.sharedFormContent}>
        <Link to="#" className={s.sharedFormContentLink}>
          {t('quick_start_guide')}
        </Link>
        <Link to={signInPath} className={s.sharedFormContentLink}>
          {t('login')}
        </Link>
      </div>
      <FooterShared className={s.sharedFormFooterSignIn}>
        {t('dont_have_account')}
        <Link to={registrationPath} className={s.sharedFormFooterLink}>
          {t('create_profile')}
        </Link>
      </FooterShared>
      <div className={s.sharedFormMarkets}>
        <h4 className={s.sharedFormMarketsTitle}>{t('check_apps')}</h4>
        <div className={s.sharedFormMarketsLinks}>
          <AppStoreBadge language={language} />
          <GooglePlayBadge language={language} />
        </div>
      </div>
    </div>
  );
};

Installation.defaultProps = {
  className: null,
};

Installation.propTypes = {
  className: PropTypes.string,
};

export default Installation;
