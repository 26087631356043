/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { createContext } from 'react';

const AuthContext = createContext({
  redirect: () => {},
  getLocation: () => {},
  setCurrentRole: () => {},
  currentRole: null,
  setGlobalRole: () => {},
  globalRole: null,
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = AuthContext;

export default AuthContext;
