/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/groups';
import * as Sentry from '@sentry/react';

export const fetchList =
  () =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get('/groups/');
      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload: normalize(res.data, [schema.group]),
      });
    } catch (e) {
      console.log('LIST GROUPS ERROR', e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/groups/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.group),
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const create =
  (values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.CREATION_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post('/groups/', values);

      dispatch({
        type: constants.CREATION_SUCCESS,
        payload: normalize(res.data, schema.group),
      });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.CREATION_FAILED });
      Sentry.captureException(e);
      actions.setErrors(e.response.data);
      actions.setSubmitting(false);

      throw e;
    }
  };

export const update =
  (values, actions) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.EDIT_START });
    actions.setSubmitting(true);

    const { id, ...data } = values;
    try {
      const res = await api.patch(`/groups/${id}/`, data);

      dispatch({
        type: constants.EDIT_SUCCESS,
        payload: normalize({ id, ...res.data }, schema.group),
      });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.EDIT_FAILED });
      Sentry.captureException(e);
      actions.setErrors(e.response.data);
      actions.setSubmitting(false);
      throw e;
    }
  };

export const remove =
  (id) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.DELETION_START });
    try {
      await api.delete(`/groups/${id}/`);

      dispatch({
        type: constants.DELETION_SUCCESS,
        payload: {
          id,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.DELETION_FAILED });
      Sentry.captureException(e);
    }
  };

export const clear = () => ({ type: constants.CLEAR });
export const createClear = () => ({ type: constants.CREATION_CLEAR });
export const updateClear = () => ({ type: constants.EDIT_CLEAR });
export const removeClear = () => ({ type: constants.DELETION_CLEAR });
