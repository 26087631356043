/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';
import { Redirect } from 'react-router-dom';
import { actions, connect } from 'data';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { restorePasswordSuccessPath } from 'constants/urls';
import RestorePasswordForm from 'components/shared/restore-password';

import { WithTranslateFormErrors } from 'utils/translation-validation';
import { WithTurnstileCaptcha } from 'utils/turnstile-captcha';
import { RestorePasswordSchema } from 'utils/validation';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  restorePassword: actions.session.restorePassword,
};

/* eslint-disable react/prefer-stateless-function */
class RestorePassword extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    restorePassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={RestorePasswordSchema(this.props.t)}
        onSubmit={this.props.restorePassword}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect
                to={
                  this.props.location.state ? this.props.location.state.location.pathname : restorePasswordSuccessPath
                }
              />
            );
          }
          return (
            <WithTurnstileCaptcha formName='forgot-password' formikProps={formikProps}>
              <WithTranslateFormErrors {...formikProps}>
                <RestorePasswordForm {...formikProps} />
              </WithTranslateFormErrors>
            </WithTurnstileCaptcha>
          );
        }}
      />
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RestorePassword));
