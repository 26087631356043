/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  itemsPagination,
  listLoadingProgress,
  gatewaysLoadingProgress,
  loadingProgress,
  dataUsageLoadingProgress,
  connectivityLoadingProgress,
  registeredDevicesLoadingProgress,
  farmsLoadingProgress,
  onlineDevicesLoadingProgress,
  unregisteredDevicesLoadingProgress,
  unregisteredDevicesPagination,
  rdiLoadingProgress,
  temperatureHumidityLoadingProgress,
  availabilityLoadingProgress,
  weekKPILoadingProgress,
  monthKPILoadingProgress,
  threeMonthsKPILoadingProgress,
  coughDataPagination,
  coughDataLoadingProgress,
  colorHistoryLoadingProgress,
  colorHistoryPagination,
} from 'data/reducers/admin/devices';

import createImmutableEqualSelector from '../../utils/immutable-selector';

const getState = (state) => state.adminDevices;

export const getEntities = (state) => getState(state).get('entities');
export const getGateways = (state) => getState(state).get('gateways');
export const getRegisteredDevices = (state) => getState(state).get('registeredDevices');
export const getFarms = (state) => getState(state).get('farms');
export const getDataUsages = (state) => getState(state).get('dataUsage');
export const getConnectivities = (state) => getState(state).get('connectivity');
export const getItem = (state, id) => getEntities(state).get(id);
export const getOnlineDevices = (state) => getState(state).get('onlineDevices');
export const getUnregisteredDevices = (state) => getState(state).get('unregisteredDevices');
export const getRDI = (state) => getState(state).get('rdi');
export const getTemperatureHumidity = (state) => getState(state).get('temperatureHumidity');
export const getWeekKPI = (state) => getState(state).get('weekKPI');
export const getMonthKPI = (state) => getState(state).get('monthKPI');
export const getThreeMonthsKPI = (state) => getState(state).get('threeMonthsKPI');
export const getCoughDataList = (state) => getState(state).get('coughData');
export const getColorHistoryList = (state) => getState(state).get('colorHistory');

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (devices, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.device], new Map({ devices })),
);

export const getGatewaysList = createImmutableEqualSelector(getGateways, (gateways) => new Map({ gateways }));

export const getFarmsList = createImmutableEqualSelector(getFarms, (farms) => new Map({ farms }));

export const getAvailability = (state) => getState(state).get('availability');

export const getDataUsage = createImmutableEqualSelector(getDataUsages, getState, (dataUsages, state) =>
  denormalize(state.get('dataUsage'), [schemas.dataUsage], new Map({ dataUsages })),
);

export const getConnectivity = createImmutableEqualSelector(
  getConnectivities,
  (connectivity) => new Map({ connectivity }),
);

export const getRegisteredDevicesList = createImmutableEqualSelector(
  getRegisteredDevices,
  (registeredDevices) => new Map({ registeredDevices }),
);

export const getOnlineDevicesList = (state) => {
  const entities = new Map({
    onlineDevices: getOnlineDevices(state),
  });

  return entities.get('onlineDevices');
};

export const getUnregisteredDevicesList = createImmutableEqualSelector(
  getUnregisteredDevices,
  getState,
  (devices, state) =>
    denormalize(unregisteredDevicesPagination.getPageItems(state), [schemas.device], new Map({ devices })),
);

export const getTemperatureHumidityData = createImmutableEqualSelector(
  getTemperatureHumidity,
  (temperatureHumidity) => new Map({ temperatureHumidity }),
);

export const getItemsTotal = (state) => itemsPagination.getTotalItems(getState(state));
export const getItemsPerPage = (state) => itemsPagination.getItemsPerPage(getState(state));

export const getCoughDataTotal = (state) => coughDataPagination.getTotalItems(getState(state));
export const getCoughDataPerPage = (state) => coughDataPagination.getItemsPerPage(getState(state));

export const getCoughDataLoaded = (state) => coughDataLoadingProgress.getLoaded(getState(state));
export const getCoughDataLoading = (state) => coughDataLoadingProgress.getLoading(getState(state));
export const getCoughDataLoadFailed = (state) => coughDataLoadingProgress.getLoadFailed(getState(state));

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const getDataUsageLoaded = (state) => dataUsageLoadingProgress.getLoaded(getState(state));
export const getDataUsageLoading = (state) => dataUsageLoadingProgress.getLoading(getState(state));
export const getDataUsageFailed = (state) => dataUsageLoadingProgress.getLoadFailed(getState(state));

export const getGatewaysLoaded = (state) => gatewaysLoadingProgress.getLoaded(getState(state));
export const getGatewaysLoading = (state) => gatewaysLoadingProgress.getLoading(getState(state));
export const getGatewatsLoadFailed = (state) => gatewaysLoadingProgress.getLoadFailed(getState(state));

export const getConnectivityLoaded = (state) => connectivityLoadingProgress.getLoaded(getState(state));
export const getConnectivityLoading = (state) => connectivityLoadingProgress.getLoading(getState(state));
export const getConnectivityLoadFailed = (state) => connectivityLoadingProgress.getLoadFailed(getState(state));

export const getRegisteredDevicesLoaded = (state) => registeredDevicesLoadingProgress.getLoaded(getState(state));
export const getRegisteredDevicesLoading = (state) => registeredDevicesLoadingProgress.getLoading(getState(state));
export const getRegisteredDevicesLoadFailed = (state) =>
  registeredDevicesLoadingProgress.getLoadFailed(getState(state));

export const getOnlineDevicesLoaded = (state) => onlineDevicesLoadingProgress.getLoaded(getState(state));
export const getOnlineDevicesLoading = (state) => onlineDevicesLoadingProgress.getLoading(getState(state));
export const getOnlineDevicesLoadFailed = (state) => onlineDevicesLoadingProgress.getLoadFailed(getState(state));

export const getFarmsLoaded = (state) => farmsLoadingProgress.getLoaded(getState(state));
export const getFarmsLoading = (state) => farmsLoadingProgress.getLoading(getState(state));
export const getFarmsLoadFailed = (state) => farmsLoadingProgress.getLoadFailed(getState(state));

export const getRDILoaded = (state) => rdiLoadingProgress.getLoaded(getState(state));
export const getRDILoading = (state) => rdiLoadingProgress.getLoading(getState(state));
export const getRDIFailed = (state) => rdiLoadingProgress.getLoadFailed(getState(state));

export const getUnregisteredDevicesLoaded = (state) => unregisteredDevicesLoadingProgress.getLoaded(getState(state));
export const getUnregisteredDevicesLoading = (state) => unregisteredDevicesLoadingProgress.getLoading(getState(state));
export const getUnregisteredDevicesLoadFailed = (state) =>
  unregisteredDevicesLoadingProgress.getLoadFailed(getState(state));

export const getTemperatureHumidityLoaded = (state) => temperatureHumidityLoadingProgress.getLoaded(getState(state));
export const getTemperatureHumidityLoading = (state) => temperatureHumidityLoadingProgress.getLoading(getState(state));
export const getTemperatureHumidityFailed = (state) => temperatureHumidityLoadingProgress.getFailed(getState(state));

export const getAvailabilityLoaded = (state) => availabilityLoadingProgress.getLoaded(getState(state));
export const getAvailabilityLoading = (state) => availabilityLoadingProgress.getLoading(getState(state));
export const getAvailabilityFailed = (state) => availabilityLoadingProgress.getFailed(getState(state));

export const getWeekKPILoaded = (state) => weekKPILoadingProgress.getLoaded(getState(state));
export const getWeekKPILoading = (state) => weekKPILoadingProgress.getLoading(getState(state));
export const getWeekKPIFailed = (state) => weekKPILoadingProgress.getFailed(getState(state));

export const getMonthKPILoaded = (state) => monthKPILoadingProgress.getLoaded(getState(state));
export const getMonthKPILoading = (state) => monthKPILoadingProgress.getLoading(getState(state));
export const getMonthKPIFailed = (state) => monthKPILoadingProgress.getFailed(getState(state));

export const getThreeMonthsKPILoaded = (state) => threeMonthsKPILoadingProgress.getLoaded(getState(state));
export const getThreeMonthsKPILoading = (state) => threeMonthsKPILoadingProgress.getLoading(getState(state));
export const getThreeMonthsKPIFailed = (state) => threeMonthsKPILoadingProgress.getFailed(getState(state));

export const getColorHistoryListLoaded = (state) => colorHistoryLoadingProgress.getLoaded(getState(state));
export const getColorHistoryListLoading = (state) => colorHistoryLoadingProgress.getLoading(getState(state));
export const getColorHistoryListLoadFailed = (state) => colorHistoryLoadingProgress.getLoadFailed(getState(state));

export const getColorHistoryItemsTotal = (state) => colorHistoryPagination.getTotalItems(getState(state));
export const getColorHistoryItemsPerPage = (state) => colorHistoryPagination.getItemsPerPage(getState(state));
