/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export class HTTPError extends Error {
  constructor(message, status = 400) {
    super(message);
    this.status = status;
    Error.captureStackTrace(this, HTTPError);
  }

  status(status) {
    this.status = status;
    return this;
  }
}

export class ConnectionError extends HTTPError {
  constructor(message) {
    super(message);
    this.status = 504;
    Error.captureStackTrace(this, ConnectionError);
  }

  status(status) {
    this.status = status;
    return this;
  }
}
