/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, createTransform, persistReducer } from 'redux-persist';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import Immutable, { isImmutable } from 'immutable';

import createFilter from 'redux-persist-transform-filter-immutable';

import { baseURL } from 'config';
import API from 'data/api';
import * as schema from 'data/utils/schemas';
import { normalize } from 'normalizr';
import reducers, { entities } from 'data/reducers';
import Serialize from 'remotedev-serialize';
import * as Sentry from '@sentry/react';
import * as constants from 'data/constants/session';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (
      action.type === constants.LOGIN_SUCCESS ||
      action.type === constants.RECOVER_SUCCESS ||
      action.type === constants.REFRESH_SUCCESS
    ) {
      // Return null to not log the action to Sentry
      // Return a transformed action to remove sensitive information
      return null;
    }
    return action;
  },
  stateTransformer: (state) => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      session: {
        ...state.session,
        // Replace sensitive information with something else
        refresh: 'I love pizza',
        // or just remove it entirely
        access: null,
      },
      // You should also remove large data that is irrelevant to debugging to not clutter your Sentry issues
      api: null,
    };

    return transformedState;
  },
});

const immutableReconciler = (inboundState, originalState, reducedState) => {
  const newState = { ...reducedState };

  Object.keys(reducedState).forEach((reducerKey) => {
    if (reducerKey === '_persist' || originalState[reducerKey] !== reducedState[reducerKey]) {
      return;
    }
    newState[reducerKey] = isImmutable(newState[reducerKey])
      ? newState[reducerKey].merge(inboundState[reducerKey])
      : Object.assign(newState[reducerKey], inboundState[reducerKey]);
  });

  return newState;
};

const immutableTransform = (config = {}) => {
  const serializer = Serialize.immutable(Immutable, config.records);
  return createTransform(serializer.stringify, serializer.parse, config);
};

const createTransforms = () => Object.keys(entities).map((reducer) => createFilter(reducer, ['entities']));

/* eslint-disable */
export let apiInstance;
/* eslint-enable */

const storeCreator = ({ storage, language }) => {
  const composeEnhancers = composeWithDevTools({
    serialize: {
      immutable: Immutable,
    },
  });

  const persistConfig = {
    key: 'st/root',
    transforms: [...createTransforms(), createFilter('session', ['refresh']), immutableTransform()],
    storage,
    stateReconciler: immutableReconciler,
    version: '0.0.5',
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  const thunkExtraArguments = {
    schema,
    normalize,
  };

  const actionTimestamp = () => (next) => (action) =>
    next({
      ...action,
      timestamp: new Date(),
    });

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunk.withExtraArgument(thunkExtraArguments)),
      applyMiddleware(actionTimestamp),
      sentryReduxEnhancer,
    ),
  );

  const headers = { 'Accept-Language': language };

  apiInstance = new API(store, { baseURL, headers });

  thunkExtraArguments.api = apiInstance;

  const persistor = persistStore(store);
  return { store, persistor };
};

export default storeCreator;
