/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect, actions, selectors } from 'data';

import DataPrivacyPage from 'components/shared/terms-conditions';
import { FluidPreloader } from 'components/core/preloader';

const mapStateToProps = (state) => ({
  dataPrivacy: selectors.session.getDataPrivacy(state),
  dataPrivacyLoaded: selectors.session.getDataPrivacyLoaded(state),
});

const mapDispatchToProps = {
  fetchDataPrivacy: actions.session.fetchDataPrivacy,
};

class DataPrivacy extends React.Component {
  static propTypes = {
    fetchDataPrivacy: PropTypes.func.isRequired,
    dataPrivacyLoaded: PropTypes.bool,
    dataPrivacy: PropTypes.shape({}),
    country: PropTypes.string.isRequired,
  };

  static defaultProps = { dataPrivacyLoaded: false, dataPrivacy: null };

  componentDidMount() {
    this.props.fetchDataPrivacy(this.props.country);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country !== this.props.country) {
      this.props.fetchDataPrivacy(this.props.country);
    }
  }

  render() {
    return this.props.dataPrivacyLoaded ? <DataPrivacyPage privacy={this.props.dataPrivacy} /> : <FluidPreloader />;
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DataPrivacy));
