/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/users';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const itemsPagination = new Pagination('adminUsers');
export const loadingProgress = new LoadingProgress('adminUsers');
export const listLoadingProgress = new LoadingProgress('adminUsersList');

export const usersKpiloadingProgress = new LoadingProgress('usersKpi');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { users },
    } = payload;
    newState.mergeIn(['entities'], fromJS(users));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadKpi = (state, action) =>
  state.withMutations((newState) => {
    const { payload } = action;
    newState.mergeIn(['usersKpi'], fromJS(payload));
    usersKpiloadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
    itemsPagination.clear(newState);
  });

const clearDashboard = (state) =>
  state.withMutations((newState) => {
    usersKpiloadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.KPI_LOAD_START:
      return usersKpiloadingProgress.setLoading(state);
    case constants.KPI_LOAD_FAILED:
      return usersKpiloadingProgress.setLoadFailed(state);
    case constants.KPI_LOAD_SUCCESS:
      return loadKpi(state, action);

    case constants.CLEAR_DASHBOARD:
      return clearDashboard(state);

    case constants.CLEAR:
      return clear(state);
    default:
      return state;
  }
};
