/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/profile';
import * as Sentry from '@sentry/react';

export const fetchSingle =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get('/users/current/');
      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSettings =
  (userId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.SETTINGS_LOAD_START });
    try {
      const res = await api.get(`/users/${userId}/settings/`);

      dispatch({
        type: constants.SETTINGS_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      dispatch({ type: constants.SETTINGS_LOAD_FAILED });
    }
  };

export const fetchFaq =
  () =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.FAQ_LOAD_START });
    try {
      const res = await api.get('/pages/faq/');

      dispatch({
        type: constants.FAQ_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.FAQ_LOAD_FAILED });
    }
  };

export const clear = () => ({ type: constants.CLEAR });

export const updateSingle =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    const { id, ...leftValues } = values;
    dispatch({ type: constants.UPDATE_START });
    try {
      const data = new FormData();
      Object.entries(leftValues).forEach(([name, value]) => {
        data.append(name, value);
      });

      if (leftValues.profile_picture && leftValues.profile_picture instanceof File === false) {
        data.delete('profile_picture');
      }

      const res = await api.patch(`/users/${id}/settings/`, data);
      if (res.status !== 200) throw new Error(res.statusText);
      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus('success');
      dispatch({
        type: constants.UPDATE_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      actions.setErrors(e.response.data);
      actions.setSubmitting(false);
      dispatch({ type: constants.UPDATE_FAILED });
      Sentry.captureException(e);
      return false;
    }
  };

export const changePassword =
  (credentials, formik) =>
  async (dispatch, _, { api }) => {
    formik.setSubmitting(true);
    try {
      const res = await api.patch('/password/', credentials);
      formik.setSubmitting(false);
      dispatch({
        type: constants.CHANGE_PASSWORD_SUCCESS,
      });
      if (res.data.status === 'Password changed') {
        formik.setStatus(true);
        return true;
      }
      formik.setErrors([res.data.status]);
      return false;
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.CHANGE_PASSWORD_FAILED });
      formik.setSubmitting(false);
      Sentry.captureException(e);
      if (e.response && e.response.data) {
        formik.setErrors(e.response.data);
      }
      return false;
    }
  };

export const fetchLanguages =
  () =>
  async (dispatch, __, { api }) => {
    dispatch({ type: constants.LANGUAGE_LOAD_START });
    try {
      const res = await api.get('/users/languages/');

      dispatch({
        type: constants.LANGUAGE_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LANGUAGE_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const changeLanguage =
  (language) =>
  (dispatch, __, { api }) => {
    api.setLanguage(language);
    return dispatch({ type: constants.CHANGE_LANGUAGE });
  };

export const fetchCountries =
  () =>
  async (dispatch, __, { api }) => {
    dispatch({ type: constants.COUNTRIES_LOAD_START });
    try {
      const res = await api.get('/country/');

      dispatch({
        type: constants.COUNTRIES_LOAD_SUCCESS,
        payload: res.data.items || res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.COUNTRIES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const handleShowSiteRegistrationConfirm = (status) => ({
  type: constants.SET_SHOW_SITE_REGISTRATION_CONFIRM,
  payload: status,
});
