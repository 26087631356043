/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  itemsPagination,
  devicesPagination,
  listLoadingProgress,
  loadingProgress,
  availabilityLoadingProgress,
  availabilityPagination,
  deviceLoadingProgress,
  dataUsageLoadingProgress,
  gatewaysLoadingProgress,
  loginActivityLoadingProgress,
  weeklyLoadingProgress,
  monthlyLoadingProgress,
  statusLoadingProgress,
  structureLoadingProgress,
  rdiLoadingProgress,
  temperatureHumidityLoadingProgress,
  airspacesStatusesLoadingProgress,
  usersPagination,
  usersLoadingProgress,
  statsLoadingProgress,
  notificationsPagination,
  notificationsLoadingProgress,
  pushNotificationsPagination,
  pushNotificationsLoadingProgress,
  alertsPagination,
  alertsLoadingProgress,
  diseaseHistoryLoadingProgress,
} from 'data/reducers/admin/sites';

import createImmutableEqualSelector from '../../utils/immutable-selector';

const getState = (state) => state.adminSites;

export const getEntities = (state) => getState(state).get('entities');
export const getDevices = (state) => getState(state).get('devices');
export const getDataUsages = (state, id) => getState(state).get('dataUsage').get(id);
export const getGateways = (state) => getState(state).get('gateways');
export const getLoginActivities = (state) => getState(state).get('LoginActivities');
export const getWeekly = (state, id) => getState(state).get('weeklyDataUsage').get(id);
export const getMonthly = (state, id) => getState(state).get('monthlyDataUsage').get(id);
export const getItem = (state, id) => getEntities(state).get(id);
export const getStatuses = (state) => getState(state).get('statuses');
export const getStatus = (state, id) => getStatuses(state).get(id);
export const getSiteStructure = (state) => getState(state).get('structure');
export const getRDI = (state) => getState(state).get('rdi');
export const getTemperatureHumidity = (state) => getState(state).get('temperatureHumidity');
export const getAirspacesStatuses = (state) => getState(state).get('airspacesStatuses');
export const getAvailabilities = (state) => getState(state).get('availability');
export const getUsers = (state) => getState(state).get('users');

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (adminSites, state) =>
  denormalize(itemsPagination.getPageItems(state), [schemas.adminSite], new Map({ adminSites })),
);

export const getAvailability = createImmutableEqualSelector(getAvailabilities, getState, (availabilities, state) =>
  denormalize(availabilityPagination.getPageItems(state), schemas.availability, new Map({ availabilities })),
);

export const getDataUsage = () =>
  createImmutableEqualSelector([getDataUsages], (dataUsages) =>
    denormalize(
      dataUsages,
      [schemas.dataUsage],
      new Map({
        dataUsages,
      }),
    ),
  );

export const getSiteDevices = createImmutableEqualSelector(getDevices, getState, (devices, state) =>
  denormalize(devicesPagination.getPageItems(state), [schemas.device], new Map({ devices })),
);

export const getSiteDevicesTotal = (state) => devicesPagination.getTotalItems(getState(state));

export const getLoginActivity = createImmutableEqualSelector(getLoginActivities, getState, (loginActivities, state) =>
  denormalize(state.get('loginActivities'), [schemas.loginActivity], new Map({ loginActivities })),
);

export const getWeeklyDataUsage = () =>
  createImmutableEqualSelector([getWeekly], (weeklyDataUsage) =>
    denormalize(
      weeklyDataUsage,
      [schemas.dataUsage],
      new Map({
        weeklyDataUsage,
      }),
    ),
  );

export const getMonthlyDataUsage = () =>
  createImmutableEqualSelector([getMonthly], (monthlyDataUsage) =>
    denormalize(
      monthlyDataUsage,
      [schemas.dataUsage],
      new Map({
        monthlyDataUsage,
      }),
    ),
  );

export const getTemperatureHumidityData = createImmutableEqualSelector(
  getTemperatureHumidity,
  (temperatureHumidity) => new Map({ temperatureHumidity }),
);

export const getUsersList = createImmutableEqualSelector(getUsers, getState, (users, state) =>
  denormalize(usersPagination.getPageItems(state), [schemas.user], new Map({ users })),
);

export const getItemsTotal = (state) => itemsPagination.getTotalItems(getState(state));
export const getItemsPerPage = (state) => itemsPagination.getItemsPerPage(getState(state));

export const getUsersTotal = (state) => usersPagination.getTotalItems(getState(state));
export const getUsersPerPage = (state) => usersPagination.getItemsPerPage(getState(state));

export const getItemsListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getItemsListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getItemsListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));

export const getAvailabilityLoaded = (state) => availabilityLoadingProgress.getLoaded(getState(state));
export const getAvailabilityLoading = (state) => availabilityLoadingProgress.getLoading(getState(state));
export const getAvailabilityLoadFailed = (state) => availabilityLoadingProgress.getLoadFailed(getState(state));

export const getDevicesLoaded = (state) => deviceLoadingProgress.getLoaded(getState(state));
export const getDevicesLoading = (state) => deviceLoadingProgress.getLoading(getState(state));
export const getDevicesFailed = (state) => deviceLoadingProgress.getLoadFailed(getState(state));

export const getDataUsageLoaded = (state) => dataUsageLoadingProgress.getLoaded(getState(state));
export const getDataUsageLoading = (state) => dataUsageLoadingProgress.getLoading(getState(state));
export const getDataUsageFailed = (state) => dataUsageLoadingProgress.getLoadFailed(getState(state));

export const getGatewaysLoaded = (state) => gatewaysLoadingProgress.getLoaded(getState(state));
export const getGatewaysLoading = (state) => gatewaysLoadingProgress.getLoading(getState(state));
export const getGatewaysFailed = (state) => gatewaysLoadingProgress.getFailed(getState(state));

export const getLoginActivityLoaded = (state) => loginActivityLoadingProgress.getLoaded(getState(state));
export const getLoginActivityLoading = (state) => loginActivityLoadingProgress.getLoading(getState(state));
export const getLoginActivityFailed = (state) => loginActivityLoadingProgress.getFailed(getState(state));

export const getWeeklyLoaded = (state) => weeklyLoadingProgress.getLoaded(getState(state));
export const getWeeklyLoading = (state) => weeklyLoadingProgress.getLoading(getState(state));
export const getWeeklyFailed = (state) => weeklyLoadingProgress.getFailed(getState(state));

export const getMonthlyLoaded = (state) => monthlyLoadingProgress.getLoaded(getState(state));
export const getMonthlyLoading = (state) => monthlyLoadingProgress.getLoading(getState(state));
export const getMonthlyFailed = (state) => monthlyLoadingProgress.getFailed(getState(state));

export const getStatusLoaded = (state) => statusLoadingProgress.getLoaded(getState(state));
export const getStatusLoading = (state) => statusLoadingProgress.getLoading(getState(state));
export const getStatusLoadFailed = (state) => statusLoadingProgress.getLoadFailed(getState(state));

export const getStructureLoaded = (state) => structureLoadingProgress.getLoaded(getState(state));
export const getStructureLoading = (state) => structureLoadingProgress.getLoading(getState(state));
export const getStructureLoadFailed = (state) => structureLoadingProgress.getLoadFailed(getState(state));

export const getItemLoaded = (state) => loadingProgress.getLoaded(getState(state));
export const getItemLoading = (state) => loadingProgress.getLoading(getState(state));
export const getItemLoadFailed = (state) => loadingProgress.getLoadFailed(getState(state));

export const getRDILoaded = (state) => rdiLoadingProgress.getLoaded(getState(state));
export const getRDILoading = (state) => rdiLoadingProgress.getLoading(getState(state));
export const getRDIFailed = (state) => rdiLoadingProgress.getLoadFailed(getState(state));

export const getTemperatureHumidityLoaded = (state) => temperatureHumidityLoadingProgress.getLoaded(getState(state));
export const getTemperatureHumidityLoading = (state) => temperatureHumidityLoadingProgress.getLoading(getState(state));
export const getTemperatureHumidityFailed = (state) => temperatureHumidityLoadingProgress.getFailed(getState(state));

export const getAirspacesStatusesLoaded = (state) => airspacesStatusesLoadingProgress.getLoaded(getState(state));
export const getAirspacesStatusesLoading = (state) => airspacesStatusesLoadingProgress.getLoading(getState(state));
export const getAirspacesStatusesFailed = (state) => airspacesStatusesLoadingProgress.getLoadFailed(getState(state));

export const getUsersLoaded = (state) => usersLoadingProgress.getLoaded(getState(state));
export const getUsersLoading = (state) => usersLoadingProgress.getLoading(getState(state));
export const getUsersFailed = (state) => usersLoadingProgress.getLoadFailed(getState(state));

// Sites stats

export const getStats = (state) => getState(state).get('sitesStats');

export const getSiteStatsLoaded = (state) => statsLoadingProgress.getLoaded(getState(state));
export const getSiteStatsLoading = (state) => statsLoadingProgress.getLoading(getState(state));
export const getSiteStatsLoadFailed = (state) => statsLoadingProgress.getLoadFailed(getState(state));

// Site notifications
export const getNotifications = (state) => getState(state).get('notifications');
export const getPushNotifications = (state) => getState(state).get('pushNotifications');
export const getAlerts = (state) => getState(state).get('alerts');

export const getNotificationsList = createImmutableEqualSelector(getNotifications, getState, (notifications, state) =>
  denormalize(notificationsPagination.getPageItems(state), [schemas.notification], new Map({ notifications })),
);

export const getNotificationsTotal = (state) => notificationsPagination.getTotalItems(getState(state));
export const getNotificationsPerPage = (state) => notificationsPagination.getItemsPerPage(getState(state));

export const getNotificationsLoaded = (state) => notificationsLoadingProgress.getLoaded(getState(state));
export const getNotificationsLoading = (state) => notificationsLoadingProgress.getLoading(getState(state));
export const getNotificationsFailed = (state) => notificationsLoadingProgress.getFailed(getState(state));

export const getPushNotificationsList = createImmutableEqualSelector(
  getPushNotifications,
  getState,
  (pushNotifications, state) =>
    denormalize(
      pushNotificationsPagination.getPageItems(state),
      [schemas.pushNotification],
      new Map({ pushNotifications }),
    ),
);

export const getPushNotificationsTotal = (state) => pushNotificationsPagination.getTotalItems(getState(state));
export const getPushNotificationsPerPage = (state) => pushNotificationsPagination.getItemsPerPage(getState(state));

export const getPushNotificationsLoaded = (state) => pushNotificationsLoadingProgress.getLoaded(getState(state));
export const getPushNotificationsLoading = (state) => pushNotificationsLoadingProgress.getLoading(getState(state));
export const getPushNotificationsFailed = (state) => pushNotificationsLoadingProgress.getFailed(getState(state));

export const getAlertsList = createImmutableEqualSelector(getAlerts, getState, (alerts, state) =>
  denormalize(alertsPagination.getPageItems(state), [schemas.alert], new Map({ alerts })),
);

export const getAlertsTotal = (state) => alertsPagination.getTotalItems(getState(state));
export const getAlertsPerPage = (state) => alertsPagination.getItemsPerPage(getState(state));

export const getAlertsLoaded = (state) => alertsLoadingProgress.getLoaded(getState(state));
export const getAlertsLoading = (state) => alertsLoadingProgress.getLoading(getState(state));
export const getAlertsFailed = (state) => alertsLoadingProgress.getFailed(getState(state));

export const getDiseaseHistory = (state) => getState(state).get('diseaseHistory');

export const getDiseaseHistoryLoaded = (state) => diseaseHistoryLoadingProgress.getLoaded(getState(state));
export const getDiseaseHistoryLoading = (state) => diseaseHistoryLoadingProgress.getLoading(getState(state));
export const getDiseaseHistoryLoadFailed = (state) => diseaseHistoryLoadingProgress.getLoadFailed(getState(state));
