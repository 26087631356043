/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import Axios from 'axios';
import * as Sentry from '@sentry/react';
import { saveBlobGeneral } from 'data/utils/download';
import * as constants from '../constants/batches';

export const fetchActiveBatch =
  (siteId, airspaceId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.ACTIVE_BATCH_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/active/`);

      dispatch({
        type: constants.ACTIVE_BATCH_LOAD_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.ACTIVE_BATCH_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchBatches =
  (siteId, airspaceId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.BATCHES_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/`);

      dispatch({
        type: constants.BATCHES_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.BATCHES_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const startBatch =
  (id, values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.START_BATCH_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`/sites/${id}/batches/`, values);

      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus('success');

      dispatch({
        type: constants.START_BATCH_SUCCESS,
        payload: res.data,
      });

      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.START_BATCH_FAILED });
      Sentry.captureException(e);
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      return false;
    }
  };

export const editBatch =
  (batchId, values) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.EDIT_BATCH_START });

    try {
      const res = await api.patch(`/batches/${batchId}/`, values);

      dispatch({
        type: constants.EDIT_BATCH_SUCCESS,
        payload: res.data,
      });

      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.EDIT_BATCH_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const closeBatch =
  (batchId, values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CLOSE_BATCH_START });

    try {
      const res = await api.post(`/batches/${batchId}/close/`, values);

      dispatch({
        type: constants.CLOSE_BATCH_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CLOSE_BATCH_FAILED });
      if (e.response) actions.setErrors(e.response.data);
      actions.setSubmitting(false);
      Sentry.captureException(e);
      throw e;
    }
  };

export const addBatchComment =
  (siteId, airspaceId, batchId, values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.ADD_BATCH_COMMENT_START });
    actions.setSubmitting(true);
    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key !== 'attachments') {
          formData.append(key, values[key]);
        } else {
          for (let i = 0; i < values.attachments.length; i += 1) {
            formData.append('attachments', values.attachments[i]);
          }
        }
      });

      const res = await api.post(
        `/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/comments-daily/`,
        formData,
      );

      actions.setStatus('success');

      dispatch({
        type: constants.ADD_BATCH_COMMENT_SUCCESS,
        payload: res.data,
      });

      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.ADD_BATCH_COMMENT_FAILED });
      Sentry.captureException(e);
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      throw e;
    }
  };

export const addTreatment =
  (siteId, airspaceId, batchId, values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.ADD_TREATMENT_START });
    actions.setSubmitting(true);
    try {
      const res = await api.post(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/treatments/`, values);

      actions.setStatus('success');

      dispatch({
        type: constants.ADD_TREATMENT_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.ADD_TREATMENT_FAILED });
      Sentry.captureException(e);
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      return false;
    }
  };

export const fetchComments =
  (siteId, airspaceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.COMMENTS_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/daily/comments/`);

      dispatch({
        type: constants.COMMENTS_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.COMMENTS_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchEventDataByType =
  ({ siteId, airspaceId, batchId, event, date }) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.EVENT_DATA_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/${event}/days/`, {
        params: { date },
      });

      dispatch({
        type: constants.EVENT_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.EVENT_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchRecommendations =
  (siteId, airspaceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RECOMMENDATIONS_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/daily/recommendations/`);

      dispatch({
        type: constants.RECOMMENDATIONS_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RECOMMENDATIONS_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchTreatments =
  (siteId, airspaceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.TREATMENTS_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/daily/treatments/`);

      dispatch({
        type: constants.TREATMENTS_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.TREATMENTS_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchRhsData =
  (siteId, airspaceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RHS_DATA_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/rhs/`);

      dispatch({
        type: constants.RHS_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RHS_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchTemperatureData =
  (siteId, airspaceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.TEMPERATURE_DATA_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/temperature/`);

      dispatch({
        type: constants.TEMPERATURE_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.TEMPERATURE_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchHumidityData =
  (siteId, airspaceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.HUMIDITY_DATA_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/humidity/`);
      dispatch({
        type: constants.HUMIDITY_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.HUMIDITY_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchRhsDeviceData =
  (deviceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.RHS_DEVICE_DATA_LOAD_START });

    try {
      const res = await api.get(`/devices/${deviceId}/batches/${batchId}/rhs/`);

      dispatch({
        type: constants.RHS_DEVICE_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.RHS_DEVICE_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchTemperatureDeviceData =
  (deviceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.TEMPERATURE_DEVICE_DATA_LOAD_START });

    try {
      const res = await api.get(`/devices/${deviceId}/batches/${batchId}/temperature/`);

      dispatch({
        type: constants.TEMPERATURE_DEVICE_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.TEMPERATURE_DEVICE_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchHumidityDeviceData =
  (deviceId, batchId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.HUMIDITY_DEVICE_DATA_LOAD_START });

    try {
      const res = await api.get(`/devices/${deviceId}/batches/${batchId}/humidity/`);

      dispatch({
        type: constants.HUMIDITY_DEVICE_DATA_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.HUMIDITY_DEVICE_DATA_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const fetchLogbook =
  ({ siteId, airspaceId, batchId, page, perPage, lazy = true }) =>
  async (dispatch, _, { api, normalize, schema }) => {
    dispatch({ type: constants.LOGBOOK_LOAD_START });

    try {
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/${batchId}/logbook/`, {
        params: { page, perPage },
      });

      dispatch({
        type: constants.LOGBOOK_LOAD_SUCCESS,
        payload: {
          ...res.data,
          ...normalize(res.data.items, [schema.logbookItem]),
          lazy,
        },
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LOGBOOK_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const clearLogbook = () => ({ type: constants.CLEAR_LOGBOOK });

export const fetchSiteBatches =
  (siteId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.SITE_BATCHES_LOAD_START });
    try {
      const res = await api.get(`/sites/${siteId}/batches/`);

      dispatch({
        type: constants.SITE_BATCHES_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.SITE_BATCHES_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSiteExports =
  ({ siteId, page, perPage, orderingField, order, backgroundFetch = false }) =>
  async (dispatch, _, { api }) => {
    if (!backgroundFetch) {
      dispatch({ type: constants.SITE_EXPORTS_LOAD_START });
    }

    try {
      const params = { params: { page, perPage, ordering_field: orderingField, order } };
      const res = await api.get(`/sites/${siteId}/batches/exports/`, params);

      dispatch({
        type: constants.SITE_EXPORTS_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.SITE_EXPORTS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const createBatchExports =
  (batchId, airspaceId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.EXPORTS_CREATE_START });
    try {
      const res = await api.post(`/batches/${batchId}/exports/`, { airspace_pk: airspaceId });

      dispatch({
        type: constants.EXPORTS_CREATE_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.EXPORTS_CREATE_FAILED });
      Sentry.captureException(e);
    }
  };

export const downloadExports =
  (batchId, exportId) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.EXPORTS_DOWNLOAD_START });
    try {
      const res = await api.get(`batches/${batchId}/exports/${exportId}/`, {
        params: { file_type: 'xlsx' },
      });

      const authStorage = window.localStorage.getItem('auth');
      api.removeAuthorization();

      const { data: blob } = await Axios.get(res.data.download_url, {
        responseType: 'blob',
      });

      api.setAuthorization(authStorage);

      saveBlobGeneral(blob, 'batch-exports', 'xlsx');

      dispatch({
        type: constants.EXPORTS_DOWNLOAD_SUCCESS,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.EXPORTS_DOWNLOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchAirspaceExports =
  ({ siteId, airspaceId, page, perPage, orderingField, order, backgroundFetch = false }) =>
  async (dispatch, _, { api }) => {
    if (!backgroundFetch) {
      dispatch({ type: constants.AIRSPACE_EXPORTS_LOAD_START });
    }

    try {
      const params = { params: { page, perPage, ordering_field: orderingField, order } };
      const res = await api.get(`/sites/${siteId}/airspaces/${airspaceId}/batches/exports/`, params);

      dispatch({
        type: constants.AIRSPACE_EXPORTS_LOAD_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: constants.AIRSPACE_EXPORTS_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const clearGraphs = () => ({ type: constants.CLEAR_GRAPHS });

export const clear = () => ({ type: constants.CLEAR });
