/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop-types';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { actions, connect, apiInstance } from 'data';
import { Formik } from 'formik';
import { signInPath } from 'constants/urls';
import RecoverPasswordForm from 'components/shared/recover-password';

import { WithTranslateFormErrors } from 'utils/translation-validation';
import { RecoverPasswordSchema } from 'utils/validation';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  recoverPassword: actions.session.recoverPassword,
};

/* eslint-disable react/prefer-stateless-function */
class RecoverPassword extends React.Component {
  static propTypes = {
    location: RouterPropTypes.location.isRequired,
    recoverPassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    isTokenNoValid: false,
    token: null,
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const token = search.split('&')[0].substring(7);

    this.setState({ token });
    apiInstance
      .get(`/password/middleman/${token}`)
      .then(() => {
        this.setState({ isTokenNoValid: false });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isTokenNoValid: true });
      });
  }

  handleRecover = (values, formik) => {
    const credentials = {
      password: values.password,
      token: this.state.token,
    };

    this.props.recoverPassword(credentials, formik);
  };

  render() {
    if (this.state.isTokenNoValid) {
      return <Redirect to={signInPath} />;
    }

    return (
      <Formik
        initialValues={{
          password: '',
          confirm_password: '',
        }}
        validationSchema={RecoverPasswordSchema(this.props.t)}
        onSubmit={this.handleRecover}
        render={({ status, ...formikProps }) => {
          if (status) {
            return (
              <Redirect to={this.props.location.state ? this.props.location.state.location.pathname : signInPath} />
            );
          }
          return (
            <WithTranslateFormErrors {...formikProps}>
              <RecoverPasswordForm {...formikProps} />
            </WithTranslateFormErrors>
          );
        }}
      />
    );
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RecoverPassword));
