/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { format } from 'date-fns';

export const transformAirspacesOptions = (airspaces) => {
  if (airspaces) {
    return [
      { id: null, value: null, label: 'All' },
      ...airspaces.toJS().map((i) => ({ id: i.id, value: i.name, label: i.name })),
    ];
  }
  return null;
};

export const transformCountryOptions = (countries) =>
  countries.map((item) => ({ label: item.name, value: item.country_code }));

export const generateTimeframeArray = (timeframe) => {
  const res = [];
  const from = new Date(timeframe.from);
  const to = new Date(timeframe.to);
  while (from <= to) {
    res.push({ date: format(from, 'y-MM-dd') });
    from.setDate(from.getDate() + 1);
  }

  return res;
};

export const makeRoles = (t) => [
  { value: 'USER', label: t('user_role') },
  { value: 'ADMIN', label: t('admin_role') },
];
