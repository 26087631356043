/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withTranslation, Trans } from 'react-i18next';
import { DarkInput, PrimaryButton, FieldWrapper, GeneralFormError, colors } from 'ui';
import { axios, ImmutablePropTypes } from 'data';
import { transformCountryOptions } from 'utils/format-data';
import { termsConditionsPath, dataPrivacyPath, signInPath } from 'constants/urls';
import { Form, Field } from 'formik';
import logo from 'public/logo.png';
import { DarkSelect } from 'components/core/select';
import PhoneInput from 'components/core/phone-input';
import Checkbox from 'components/core/checkbox';

import HeaderShared from 'components/shared/layout/header';

import s from '../../styles.scss';

const pickerValueAdapter = (value) => value.value;

const getTimezones = () =>
  axios.get('/timezones-offset.json').then((res) => {
    const { data } = res;
    return data.map((zone) => ({
      value: zone.value,
      label: zone.label,
    }));
  });

class RegisterForm extends React.Component {
  state = {
    timezones: [],
  };

  componentDidMount() {
    getTimezones().then((timezones) => {
      this.setState({
        timezones,
      });
    });
  }

  handleOnChange = (value) => {
    this.props.setFieldValue('telephone', value);
  };

  onCountryChange = (option) => {
    this.props.handleSelectCountry(option);
    this.props.setFieldValue('country', option.value);
  };

  render() {
    const { className, countries, values, errors, validateForm, setFieldValue, t, ...formikProps } = this.props;
    const countryOptions = transformCountryOptions(countries);

    return (
      <div className={cx(s.sharedForm, className)}>
        <HeaderShared>
          <div className={s.sharedFormLogo} style={{ backgroundImage: `url(${logo})` }} />
        </HeaderShared>
        <HeaderShared className={s.sharedFormFooterRegistration}>
          {t('already_have_account')}
          <Link to={signInPath} className={s.sharedFormFooterLink}>
            {t('sign_in')}
          </Link>
        </HeaderShared>
        <Form>
          <Field
            id="first_name"
            name="first_name"
            placeholder={t('first_name')}
            component={FieldWrapper}
            inputComponent={DarkInput}
            className={s.sharedFormField}
            // eslint-disable-next-line camelcase
            error={errors.first_name}
          />
          <Field
            id="last_name"
            name="last_name"
            placeholder={t('last_name')}
            component={FieldWrapper}
            inputComponent={DarkInput}
            className={s.sharedFormField}
            // eslint-disable-next-line camelcase
            error={errors.last_name}
          />
          <Field
            id="email"
            name="email"
            placeholder={t('email')}
            component={FieldWrapper}
            inputComponent={DarkInput}
            className={s.sharedFormField}
            error={errors.email || errors.description}
          />
          <Field
            id="telephone"
            name="telephone"
            placeholder={t('telephone')}
            inputComponent={PhoneInput}
            options={this.props.countries.map((country) => ({ ...country, value: country.phone_code }))}
            component={FieldWrapper}
            className={s.sharedFormField}
            handleOnChange={this.handleOnChange}
            errorColor={colors.white}
            error={errors.telephone}
          />
          <Field
            id="password"
            name="password"
            secureTextEntry
            placeholder={t('password')}
            component={FieldWrapper}
            inputComponent={DarkInput}
            className={s.sharedFormField}
            error={errors.password || errors.password?.join(' ')}
            type="password"
          />
          <Field
            id="confirm_password"
            name="confirm_password"
            secureTextEntry
            placeholder={t('confirm_password')}
            component={FieldWrapper}
            inputComponent={DarkInput}
            className={s.sharedFormField}
            type="password"
          />
          <Field
            id="language"
            name="language"
            placeholder={t('select_language')}
            inputComponent={DarkSelect}
            onChangeAdapter={pickerValueAdapter}
            options={this.props.languages?.toJS()}
            component={FieldWrapper}
            valueAdapter={(value) => this.props.languages.find((x) => x.value === value)}
            className={s.sharedFormField}
            error={errors.language}
          />
          <Field
            id="country"
            name="country"
            placeholder={t('select_country')}
            inputComponent={DarkSelect}
            options={countryOptions}
            component={FieldWrapper}
            valueAdapter={(value) => countryOptions.find((x) => x.value === value)}
            className={s.input}
            onChange={this.onCountryChange}
            error={errors.country}
          />
          <Field
            id="timezone"
            name="timezone"
            placeholder={t('select_timezone')}
            inputComponent={DarkSelect}
            onChangeAdapter={pickerValueAdapter}
            options={this.state.timezones}
            component={FieldWrapper}
            valueAdapter={(value) => this.state.timezones.find((x) => x.value === value)}
            className={s.sharedFormField}
            error={errors.timezone}
          />

          <Field
            component={Checkbox}
            checked={values.policy_accepted}
            className={s.sharedFormField}
            id="policy_accepted_checkbox"
            name="policy_accepted_checkbox"
            label={
              <div className={s.sharedFormPolicyLabel}>
                <Trans i18nKey="agree_to_terms_of_use_and_data_privacy">
                  <Link to={termsConditionsPath} className={s.sharedFormPolicyLink} />
                  <Link to={dataPrivacyPath} className={s.sharedFormPolicyLink} />
                </Trans>
              </div>
            }
            onChange={() => setFieldValue('policy_accepted', !values.policy_accepted)}
            dark
          />

          <PrimaryButton
            type="button"
            className={s.sharedFormSubmit}
            onClick={() => validateForm(values, formikProps)}
            isDisabled={!values.policy_accepted || !formikProps.isValid}
          >
            {t('next')}
          </PrimaryButton>

          <GeneralFormError error={errors.description || errors[0]} />
        </Form>
      </div>
    );
  }
}

RegisterForm.defaultProps = {
  className: null,
  values: null,
  errors: null,
  languages: null,
};

RegisterForm.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.shape(),
  values: PropTypes.shape(),
  validateForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSelectCountry: PropTypes.func.isRequired,
  languages: ImmutablePropTypes.list,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RegisterForm);
