/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export const getState = (store) => store.api;

export const getBackendError = (store) => getState(store).get('backendError');

export const getUnauthorized = (store) => getBackendError(store) === 'unauthorized';

export const getAccountLocked = (store) => getBackendError(store) === 'too-many-requests';

export const getSuccess = (store) => !getBackendError(store);
