/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

// short = Jun 1
// regular = Jun 1, 2021
// full_day = Friday, April 29th, 1453
// month = June
// day_with_time = May 4th, 10:42
// full_day_with_time = Monday, 22 Mar, 09:15
// full_day_with_time_and_timezone =

export const defaultFormates = {
  short: 'MMM d',
  full_day: 'PPPP',
  full_with_time: 'd MMM yyy HH:mm',
  regular: 'dd MMM yyyy',
  month: 'MMMM',
  time: 'HH:mm',
  day_with_time: 'MMM do, HH:mm',
  day_with_brackets: 'dd MMM (E)',
  full_day_with_time: 'iiii, dd MMM, HH:mm',
  full_day_with_time_and_timezone: 'iiii, dd MMM, HH:mm, OOOO',
  full_day_with_brackets: 'dd MMM Y (E)',
};

export const localeToFormat = (locale) => {
  if (locale === 'cn') {
    return {
      ...defaultFormates,
      short: 'MMM d日',
      regular: 'PPP',
      full_with_time: 'PPpp',
      day_with_time: 'MMM d日, HH:mm',
      day_with_brackets: 'd日 MMM (E)',
      full_day_with_time: 'iiii, MMM d日, HH:mm',
      full_day_with_time_and_timezone: 'iiii, MMM d日, HH:mm, OOOO',
      full_day_with_brackets: 'd日 MMM Y (E)',
    };
  }

  return defaultFormates;
};
