/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as constants from 'data/constants/airspaces';
import * as Sentry from '@sentry/react';

export const fetchList =
  (site, filters, sort) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const params = { ...sort, ...filters };
      if (site.simple) {
        params.simple = site.simple;
      }
      const res = await api.get(`/sites/${site.id}/airspaces/`, { params });

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload: normalize(res.data, [schema.airspace]),
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };

export const clear = () => ({ type: constants.CLEAR });
