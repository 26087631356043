/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/groups';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const loadingProgress = new LoadingProgress('adminGroups');
export const listLoadingProgress = new LoadingProgress('adminGroupsList');
export const listPagination = new Pagination('adminGroups');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { adminGroups },
    } = payload;
    newState.mergeIn(['entities'], fromJS(adminGroups));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeData(newState, action.payload);
    newState.set('list', fromJS(action.payload.result));
    listPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);

    case constants.CLEAR:
      return clear(state);

    default:
      return state;
  }
};
