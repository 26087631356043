/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Turnstile } from '@marsidev/react-turnstile';
import { useTranslation } from 'react-i18next';
import { captchaKey } from 'data';
import Modal from 'components/core/modal';


const turnstileOptions = { size: 'invisible', responseField: false };

const WithTurnstileCaptcha = ({ formName, formikProps, children, ...turnstileProps }) => {
  const { t } = useTranslation();
  const turnstileRef = useRef(null);
  turnstileOptions.action = formName;

  const onSuccess = token => formikProps.setFieldValue('captcha_token', token, false);
  const onRefresh = () => {
    turnstileRef.current?.reset();
    formikProps.setErrors({...formikProps.errors, captcha_token: []});
  };

  return (
    <>
      {children}
      <Turnstile
        ref={turnstileRef}
        siteKey={captchaKey}
        options={turnstileOptions}
        onSuccess={onSuccess}
        {...turnstileProps}
      />
      <Modal
        show={formikProps.errors.captcha_token?.length}
        title={t('captcha_failed')}
        isConfirmModal
        confirmButtonText={t('refresh_form')}
        onConfirm={onRefresh}
        content={
          <div>
            <p>{t("captcha_refresh_notice")}</p>
            <small>Error detail: {formikProps.errors.captcha_token}</small>
          </div>
        }
      />
    </>
  );
};

WithTurnstileCaptcha.defaultProps = {
  children: null,
};

WithTurnstileCaptcha.propTypes = {
  formName: PropTypes.string.isRequired,
  formikProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export { WithTurnstileCaptcha };
