/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import {
  activeBatchLoadingProgress,
  batchesLoadingProgress,
  commentsLoadingProgress,
  recommendationsLoadingProgress,
  treatmentsLoadingProgress,
  eventDataLoadingProgress,
  rhsDataLoadingProgress,
  temperatureDataLoadingProgress,
  humidityDataLoadingProgress,
  rhsDeviceDataLoadingProgress,
  temperatureDeviceDataLoadingProgress,
  humidityDeviceDataLoadingProgress,
  logbookLoadingProgress,
  siteBatchesLoadingProgress,
  siteExportsLoadingProgress,
  createExportsLoadingProgress,
  airspaceExportsLoadingProgress,
} from '../reducers/batches';

import createImmutableEqualSelector from 'data/utils/immutable-selector';

const getState = (state) => state.batches;

export const getActiveBatch = (state) => getState(state).get('activeBatch');
export const getActiveBatchLoaded = (state) => activeBatchLoadingProgress.getLoaded(getState(state));
export const getActiveBatchLoading = (state) => activeBatchLoadingProgress.getLoading(getState(state));
export const getActiveBatchLoadFailed = (state) => activeBatchLoadingProgress.getFailed(getState(state));

export const getBatches = (state) => getState(state).getIn(['allBatches', 'items']);
export const getBatchesLoaded = (state) => batchesLoadingProgress.getLoaded(getState(state));
export const getBatchesLoading = (state) => batchesLoadingProgress.getLoading(getState(state));
export const getBatchesLoadFailed = (state) => batchesLoadingProgress.getFailed(getState(state));

export const getComments = (state) => getState(state).get('comments');
export const getCommentsLoaded = (state) => commentsLoadingProgress.getLoaded(getState(state));
export const getCommentsLoading = (state) => commentsLoadingProgress.getLoading(getState(state));
export const getCommentsLoadFailed = (state) => commentsLoadingProgress.getFailed(getState(state));

export const getRecommendations = (state) => getState(state).get('recommendations');
export const getRecommendationsLoaded = (state) => recommendationsLoadingProgress.getLoaded(getState(state));
export const getRecommendationsLoading = (state) => recommendationsLoadingProgress.getLoading(getState(state));
export const getRecommendationsLoadFailed = (state) => recommendationsLoadingProgress.getFailed(getState(state));

export const getTreatments = (state) => getState(state).get('treatments');
export const getTreatmentsLoaded = (state) => treatmentsLoadingProgress.getLoaded(getState(state));
export const getTreatmentsLoading = (state) => treatmentsLoadingProgress.getLoading(getState(state));
export const getTreatmentsLoadFailed = (state) => treatmentsLoadingProgress.getFailed(getState(state));

export const getEventData = (state) => getState(state).get('eventData');
export const getEventDataLoaded = (state) => eventDataLoadingProgress.getLoaded(getState(state));
export const getEventDataLoading = (state) => eventDataLoadingProgress.getLoading(getState(state));
export const getEventDataLoadFailed = (state) => eventDataLoadingProgress.getFailed(getState(state));

export const getRhsData = (state) => getState(state).get('rhsData');
export const getRhsDataLoaded = (state) => rhsDataLoadingProgress.getLoaded(getState(state));
export const getRhsDataLoading = (state) => rhsDataLoadingProgress.getLoading(getState(state));
export const getRhsDataFailed = (state) => rhsDataLoadingProgress.getFailed(getState(state));

export const getTemperatureData = (state) => getState(state).get('temperatureData');
export const getTemperatureDataLoaded = (state) => temperatureDataLoadingProgress.getLoaded(getState(state));
export const getTemperatureDataLoading = (state) => temperatureDataLoadingProgress.getLoading(getState(state));
export const getTemperatureDataFailed = (state) => temperatureDataLoadingProgress.getFailed(getState(state));

export const getHumidityData = (state) => getState(state).get('humidityData');
export const getHumidityDataLoaded = (state) => humidityDataLoadingProgress.getLoaded(getState(state));
export const getHumidityDataLoading = (state) => humidityDataLoadingProgress.getLoading(getState(state));
export const getHumidityDataFailed = (state) => humidityDataLoadingProgress.getFailed(getState(state));

export const getRhsDeviceData = (state) => getState(state).getIn(['device', 'rhsDeviceData']);
export const getRhsDeviceDataLoaded = (state) => rhsDeviceDataLoadingProgress.getLoaded(getState(state));
export const getRhsDeviceDataLoading = (state) => rhsDeviceDataLoadingProgress.getLoading(getState(state));
export const getRhsDeviceDataFailed = (state) => rhsDeviceDataLoadingProgress.getFailed(getState(state));

export const getTemperatureDeviceData = (state) => getState(state).getIn(['device', 'temperatureDeviceData']);
export const getTemperatureDeviceDataLoaded = (state) =>
  temperatureDeviceDataLoadingProgress.getLoaded(getState(state));
export const getTemperatureDeviceDataLoading = (state) =>
  temperatureDeviceDataLoadingProgress.getLoading(getState(state));
export const getTemperatureDeviceDataFailed = (state) =>
  temperatureDeviceDataLoadingProgress.getFailed(getState(state));

export const getHumidityDeviceData = (state) => getState(state).getIn(['device', 'humidityDeviceData']);
export const getHumidityDeviceDataLoaded = (state) => humidityDeviceDataLoadingProgress.getLoaded(getState(state));
export const getHumidityDeviceDataLoading = (state) => humidityDeviceDataLoadingProgress.getLoading(getState(state));
export const getHumidityDeviceDataFailed = (state) => humidityDeviceDataLoadingProgress.getFailed(getState(state));

export const getLogbookState = (state) => getState(state).get('logbook');
export const getLogbookEntities = (state) => getLogbookState(state).get('entities');

export const getLogbook = createImmutableEqualSelector(getLogbookEntities, getLogbookState, (items, state) =>
  denormalize(state, { entities: [schemas.logbookItem] }, new Map({ items })),
);

export const getLogbookLoaded = (state) => logbookLoadingProgress.getLoaded(getState(state));
export const getLogbookLoading = (state) => logbookLoadingProgress.getLoading(getState(state));
export const getLogbookFailed = (state) => logbookLoadingProgress.getFailed(getState(state));

export const getSiteBatches = (state) => getState(state).get('siteBatches');
export const getSiteBatchesLoaded = (state) => siteBatchesLoadingProgress.getLoaded(getState(state));
export const getSiteBatchesLoading = (state) => siteBatchesLoadingProgress.getLoading(getState(state));
export const getSiteBatchesFailed = (state) => siteBatchesLoadingProgress.getFailed(getState(state));

export const getSiteExports = (state) => getState(state).getIn(['siteExports', 'items']);
export const getSiteTotalItems = (state) => getState(state).getIn(['siteExports', 'total']);
export const getSiteExportsLoaded = (state) => siteExportsLoadingProgress.getLoaded(getState(state));
export const getSiteExportsLoading = (state) => siteExportsLoadingProgress.getLoading(getState(state));
export const getSiteExportsFailed = (state) => siteExportsLoadingProgress.getFailed(getState(state));

export const getCreateExportsLoaded = (state) => createExportsLoadingProgress.getLoaded(getState(state));
export const getCreateExportsLoading = (state) => createExportsLoadingProgress.getLoading(getState(state));
export const getCreateExportsFailed = (state) => createExportsLoadingProgress.getFailed(getState(state));

export const getAirspaceExports = (state) => getState(state).getIn(['airspaceExports', 'items']);
export const getAirspaceTotalItems = (state) => getState(state).getIn(['airspaceExports', 'total']);
export const getAirspaceExportsLoaded = (state) => airspaceExportsLoadingProgress.getLoaded(getState(state));
export const getAirspaceExportsLoading = (state) => airspaceExportsLoadingProgress.getLoading(getState(state));
export const getAirspaceExportsFailed = (state) => airspaceExportsLoadingProgress.getFailed(getState(state));
