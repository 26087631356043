/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from './styles.scss';

class TextArea extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.func,
    // eslint-disable-next-line
    form: PropTypes.object,
    autoresize: PropTypes.bool,
    showCounter: PropTypes.bool,
  };

  static defaultProps = {
    innerRef: null,
    autoresize: false,
    showCounter: false,
  };

  handleResize = (...args) => {
    this.textarea.style.height = 'inherit';

    const computed = window.getComputedStyle(this.textarea);
    const height =
      this.textarea.scrollHeight -
      parseInt(computed.getPropertyValue('padding-top'), 10) -
      parseInt(computed.getPropertyValue('padding-bottom'), 10);

    this.textarea.style.height = `${height}px`;
    this.props.field.onChange(...args);
  };

  bindRef = (textarea) => {
    if (this.props.innerRef) this.props.innerRef(textarea);
    this.textarea = textarea;
  };

  render() {
    const {
      field: { onChange, ...fieldProps },
      innerRef,
      form,
      autoresize,
      showCounter,
      ...props
    } = this.props;

    return (
      <div className={cx(s.wrapper, props.wrapperClassName)}>
        <textarea
          ref={this.bindRef}
          onChange={this.props.autoresize ? this.handleResize : onChange}
          {...fieldProps}
          {...props}
          className={cx(s.textArea, props.className, props.error && s.textAreaError)}
        />
        <div className={s.footer}>
          <span className={cx(s.footerError, props.errorColor)}>{props.error}</span>
          {showCounter && (
            <span className={s.footerCounter}>{`${fieldProps.value?.length || 0} / ${props.maxLength}`}</span>
          )}
        </div>
      </div>
    );
  }
}

export default TextArea;
