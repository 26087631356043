/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/sites/${type}`;

export const LOAD_START = prefix('LOAD_START');
export const LOAD_SUCCESS = prefix('LOAD_SUCCESS');
export const LOAD_FAILED = prefix('LOAD_FAILED');

export const LIST_LOAD_START = prefix('LIST_LOAD_START');
export const LIST_LOAD_SUCCESS = prefix('LIST_LOAD_SUCCESS');
export const LIST_LOAD_FAILED = prefix('LIST_LOAD_FAILED');

export const FILTER_GROUP = prefix('FILTER_GROUP');
export const FILTER_TYPE = prefix('FILTER_TYPE');
export const FILTER_STATUS = prefix('FILTER_STATUS');
export const FILTER_SEARCH = prefix('FILTER_SEARCH');

export const STATUS_LOAD_START = prefix('STATUS_LOAD_START');
export const STATUS_LOAD_SUCCESS = prefix('STATUS_LOAD_SUCCESS');
export const STATUS_LOAD_FAILED = prefix('STATUS_LOAD_FAILED');

export const STRUCTURE_LOAD_START = prefix('STRUCTURE_LOAD_START');
export const STRUCTURE_LOAD_SUCCESS = prefix('STRUCTURE_LOAD_SUCCESS');
export const STRUCTURE_LOAD_FAILED = prefix('STRUCTURE_LOAD_FAILED');

export const UPDATE_START = prefix('UPDATE_START');
export const UPDATE_SUCCESS = prefix('UPDATE_SUCCESS');
export const UPDATE_FAILED = prefix('UPDATE_FAILED');

export const CLEAR = prefix('CLEAR');

export const CLEAR_SINGLE = prefix('CLEAR_SINGLE');
export const CLEAR_SINGLE_STATUS = prefix('CLEAR_SINGLE_STATUS');
export const CLEAR_STRUCTURE = prefix('CLEAR_STRUCTURE');

export const CREATION_START = prefix('CREATION_START');
export const CREATION_SUCCESS = prefix('CREATION_SUCCESS');
export const CREATION_FAILED = prefix('CREATION_FAILED');
export const CREATION_CLEAR = prefix('CREATION_CLEAR');

export const LATEST_INFO_LOAD_START = prefix('LATEST_INFO_LOAD_START');
export const LATEST_INFO_LOAD_SUCCESS = prefix('LATEST_INFO_LOAD_SUCCESS');
export const LATEST_INFO_LOAD_FAILED = prefix('LATEST_INFO_LOAD_FAILED');

export const UPDATE_SITE_RECOMMENDATIONS_COUNT = prefix('UPDATE_SITE_RECOMMENDATIONS_COUNT');

export const INVITATIONS_LOAD_START = prefix('INVITATIONS_LOAD_START');
export const INVITATIONS_LOAD_SUCCESS = prefix('INVITATIONS_LOAD_SUCCESS');
export const INVITATIONS_LOAD_FAILED = prefix('INVITATIONS_LOAD_FAILED');
