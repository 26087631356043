/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import * as Sentry from '@sentry/react';
import * as constants from 'data/constants/site-creation';

export const setPage = (page) => ({ type: constants.SET_PAGE, payload: page });

export const setValues = (values) => ({ type: constants.SET_VALUES, payload: values });

export const resetValues = () => ({ type: constants.RESET_VALUES });

export const setEditPage = (page) => ({ type: constants.SET_EDIT_PAGE, payload: page });

export const activatePreinstall =
  (siteId, values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.PREINSTALL_ACTIVATE_START });
    actions.setSubmitting(true);
    try {
      const res = await api.patch(`/sites/${siteId}/activate/`, values);
      if (res.status !== 200) throw new Error(res.statusText);
      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus('success');
      dispatch({
        type: constants.PREINSTALL_ACTIVATE_SUCCESS,
      });
      return true;
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      dispatch({ type: constants.PREINSTALL_ACTIVATE_FAILED });
      if (e.response) {
        actions.setErrors(e.response.data);
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const checkDeviceAvailable =
  (device) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.CHECK_DEVICE_AVAILABLE_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/devices/search/`, {
        params: { device_code: device, delivery_status: 'SHIPPED' },
      });

      dispatch({
        type: constants.CHECK_DEVICE_AVAILABLE_SUCCESS,
      });

      return res.data.items;
    } catch (e) {
      console.error(e);
      dispatch({ type: constants.CHECK_DEVICE_AVAILABLE_FAILED });
      Sentry.captureException(e);
      throw e;
    }
  };
