/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

const prefix = (type) => `st/api/${type}`;

export const UNAUTHORIZED = prefix('UNAUTHORIZED');
export const SERVER_ERROR = prefix('SERVER_ERROR');
export const NOT_FOUND = prefix('NOT_FOUND');
export const CONNECTION_ERROR = prefix('CONNECTION_ERROR');
export const FORBIDDEN = prefix('FORBIDDEN');
export const TOO_MANY_REQUESTS = prefix('TOO_MANY_REQUESTS');

export const SUCCESS = prefix('SUCCESS');

export const CLEAR = prefix('CLEAR');
