/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import Icon from 'components/core/icon';

export const adminAccessRoles = ['Soundtalks Customer Support', 'Spectator User'];

export const recommendationIconByType = {
  ENVIRONMENT: Icon.icons.recommendationEnvironment,
  VETERINARIAN: Icon.icons.recommendationVeterinarian,
  PIG_CARE: Icon.icons.pigHead,
  DIAGNOSTICS: Icon.icons.recommendationDiagnostics,
  ANTIINFLAMMATORY: Icon.icons.pills,
  TREATMENT: Icon.icons.recommendationTreatment,
  ONGOING_TREATMENT: Icon.icons.recommendationOngoingTreatment,
};

export const recommendationStatus = { accept: 'ACCEPT', declined: 'DECLINED', noReaction: 'NO_REACTION' };

export const recommendationTranslationByType = {
  ENVIRONMENT: 'environment',
  VETERINARIAN: 'veterinarian',
  PIG_CARE: 'pig_care',
  DIAGNOSTICS: 'diagnostics',
  ANTIINFLAMMATORY: 'anti_inflammatory',
  TREATMENT: 'treatment',
  ONGOING_TREATMENT: 'treatment',
};

export const dropZones = [
  {
    label: 'nursery',
    value: 'Nursery',
    icon: Icon.icons.nursery,
  },
  {
    label: 'fattening',
    value: 'Fattening',
    icon: Icon.icons.fattening,
  },
  {
    label: 'wean to finish',
    value: 'Wean to finish',
    icon: Icon.icons.weanToFinish,
  },
];
