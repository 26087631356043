/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { colors, fontSize, sizes } from 'ui/constants';
import Text from '../text';
import cx from 'classnames';
import s from './styles.scss';

const WarningIndicator = ({ className, children, total, amount, label, ...props }) => (
  <div {...props} className={cx(s.indicatorStatus, amount > 0 && s.warning, className)}>
    <svg
      height={48}
      width={48}
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1021.000000, -499.000000)">
          <g transform="translate(986.000000, 436.000000)">
            <g transform="translate(30.000000, 67.000000)">
              <rect
                stroke={amount > 0 ? colors.warning : colors.secondary}
                strokeWidth="4"
                fill="none"
                x="7"
                y="-2"
                width="40"
                height="40"
                rx="2"
              />
              {amount > 0 ? (
                <text
                  fontSize={fontSize.xs}
                  fontWeight="400"
                  fill="none"
                  stroke="none"
                  className={{ fill: colors.primary }}
                >
                  <tspan x="27" y="22" textAnchor="middle">
                    {amount}/{total}
                  </tspan>
                </text>
              ) : (
                <polyline
                  stroke="none"
                  className={{ stroke: colors.primary }}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="40 20 23.5 37 16 29.2727273"
                  transform="translate(-1, -10)"
                />
              )}
            </g>
          </g>
        </g>
      </g>
    </svg>
    <Text size={Text.size.m} className={cx(s.secondary, amount > 0 && s.activeColors)}>
      {label}
    </Text>
  </div>
);

WarningIndicator.defaultProps = {
  children: null,
  className: null,
  total: null,
  amount: null,
  label: 'Warning',
};

WarningIndicator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  total: PropTypes.number,
  amount: PropTypes.number,
  label: PropTypes.string,
};

export default WarningIndicator;
