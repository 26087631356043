/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import s from './styles.scss';

const ContentBox = ({ className, children, ...props }) => (
  <div {...props} className={cx(s.contentBox, className)}>
    {children}
  </div>
);

ContentBox.defaultProps = {
  children: null,
  className: null,
};

ContentBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentBox;
