/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { isSameDay, endOfDay, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const setDateWithTimezoneOffset = (date) => {
  return new Date(date - date.getTimezoneOffset() * 60000);
};

export const getEndOfDayWithTimezoneOffset = (date) => setDateWithTimezoneOffset(endOfDay(new Date(date)));

export const timerameFormatter = (timeframe, timezone = 'UTC') => {
  const { from, to } = timeframe;

  return {
    from: setDateWithTimezoneOffset(startOfDay(new Date(from))),
    to: isSameDay(new Date(), new Date(to))
      ? setDateWithTimezoneOffset(utcToZonedTime(new Date(), timezone))
      : getEndOfDayWithTimezoneOffset(to),
  };
};
