/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

/* eslint-disable camelcase */
import * as constants from 'data/constants/admin/gateways';
import * as Sentry from '@sentry/react';

export const fetchList =
  (page = 1, perPage = 10, orderingField, order, search) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LIST_LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/gateways/`, {
        params: { page, perPage, ordering_field: orderingField, order, search },
      });

      const payload = {
        ...res.data,
        ...normalize(res.data.items, [schema.gateway]),
      };

      dispatch({
        type: constants.LIST_LOAD_SUCCESS,
        payload,
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      dispatch({ type: constants.LIST_LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const fetchSingle =
  (id) =>
  async (dispatch, _, { api, schema, normalize }) => {
    dispatch({ type: constants.LOAD_START });
    try {
      const res = await api.get(`/api/v2/admin-dashboard/gateways/${id}/`);

      dispatch({
        type: constants.LOAD_SUCCESS,
        payload: normalize(res.data, schema.gateway),
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      dispatch({ type: constants.LOAD_FAILED });
      Sentry.captureException(e);
    }
  };

export const bulkStatusChanger =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.BULK_STATUS_CHANGE_START });
    actions.setSubmitting(true);
    try {
      const res = await api.patch(`/api/v2/admin-dashboard/gateways/bulk/status/`, values);

      actions.setSubmitting(false);
      actions.setStatus(true);
      dispatch({ type: constants.BULK_STATUS_CHANGE_SUCCESS, payload: res });
      return true;
    } catch (e) {
      console.error(e.response && e.response.data);
      // dispatch({ type: constants.BULK_STATUS_CHANGE_FAILED });
      if (e?.response?.data?.device_codes) {
        actions.setErrors({ deviceCodes: e?.response?.data?.device_codes[0] });
      }
      actions.setSubmitting(false);
      Sentry.captureException(e);
      return false;
    }
  };

export const pcbFileUpload =
  (values, actions) =>
  async (dispatch, _, { api }) => {
    dispatch({ type: constants.PCB_UPLOAD_START });
    try {
      const data = new FormData();
      Object.entries(values).forEach(([name, value]) => {
        data.append(name, value);
      });
      const res = await api.patch(`/api/v1/ata/odoo/gateway-registration/`, data);
      dispatch({ type: constants.PCB_UPLOAD_SUCCESS, payload: res.data });
      return true;
    } catch (e) {
      console.error(e);
      if (e.response?.data?.length > 0) {
        actions.setErrors(e.response.data);
      }
      dispatch({ type: constants.PCB_UPLOAD_FAILED });
      Sentry.captureException(e);
      return false;
    }
  };

export const clearPcbUpload = () => ({ type: constants.CLEAR_PCB_UPLOAD });

export const clear = () => ({ type: constants.CLEAR });
