/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import circles from 'public/concentric-circles.svg';
import s from './styles.scss';

const LayoutShared = ({ className, children, header, footer }) => (
  <div className={cx(s.layout, className)}>
    {header && <div className={s.layoutHeader}>{header}</div>}
    <img src={circles} alt="" className={cx(s.layoutConcentric, s.layoutConcentricLeft)} />
    <img src={circles} alt="" className={cx(s.layoutConcentric, s.layoutConcentricRight)} />
    <div className={s.layoutContent}>{children}</div>
    {footer && <div className={s.layoutFooter}>{footer}</div>}
  </div>
);

LayoutShared.defaultProps = {
  className: null,
  children: null,
  header: null,
  footer: null,
};

LayoutShared.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export default LayoutShared;
