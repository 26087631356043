/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export const mapStyles = [
  {
    stylers: [
      {
        hue: '#263238',
      },
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    stylers: [
      {
        lightness: 55,
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    stylers: [
      {
        lightness: 35,
      },
    ],
  },
  {
    featureType: 'landscape.natural.terrain',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 50,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];
