/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schemas from 'data/utils/schemas';
import { listLoadingProgress, listPagination } from 'data/reducers/admin/groups';

import createImmutableEqualSelector from '../../utils/immutable-selector';

const getState = (state) => state.adminGroups;

export const getEntities = (state) => getState(state).get('entities');
export const getItem = (state, id) => getEntities(state).get(id);

export const getItemsList = createImmutableEqualSelector(getEntities, getState, (adminGroups, state) =>
  denormalize(listPagination.getPageItems(state), [schemas.adminGroup], new Map({ adminGroups })),
);

export const getItemsTotal = (state) => listPagination.getTotalItems(getState(state));
export const getItemsPerPage = (state) => listPagination.getItemsPerPage(getState(state));

export const getListLoaded = (state) => listLoadingProgress.getLoaded(getState(state));
export const getListLoading = (state) => listLoadingProgress.getLoading(getState(state));
export const getListLoadFailed = (state) => listLoadingProgress.getLoadFailed(getState(state));
