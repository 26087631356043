/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/api';

const initialState = fromJS({
  backendError: null,
});

const reset = (state) =>
  state.withMutations((newState) => {
    newState.set('backendError', null);
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.UNAUTHORIZED:
      return state.set('backendError', 'unauthorized');
    case constants.NOT_FOUND:
      return state.set('backendError', 'not-found');
    case constants.FORBIDDEN:
      return state.set('backendError', 'forbidden');
    case constants.SERVER_ERROR:
      return state.set('backendError', 'server-error');
    // case constants.CONNECTION_ERROR:
    //   return state.set('backendError', 'connection-error');
    case constants.TOO_MANY_REQUESTS:
      return state.set('backendError', 'too-many-requests');
    case constants.SUCCESS:
    case constants.CLEAR:
      return reset(state);
    default:
      return state;
  }
};
