/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export const sensors = {
  coughs: 0,
  temperature: 1,
  humidity: 2,
};

export const siteStatuses = {
  green: {
    id: 'green',
    name: 'Ok',
  },
  yellow: {
    id: 'yellow',
    name: 'Warning',
  },
  red: {
    id: 'red',
    name: 'Alarm',
  },
  blue: {
    id: 'blue',
    name: 'Offline',
  },
  8: {
    id: 8,
    name: 'Alarm',
  },
  4: {
    id: 4,
    name: 'Default',
  },
  5: {
    id: 5,
    name: 'Offline',
  },
  6: {
    id: 6,
    name: 'Ok',
  },
  7: {
    id: 7,
    name: 'Warning',
  },
  13: {
    id: 13,
    name: 'Alarm',
  },
  3: {
    id: 3,
    name: 'Alarm',
  },
  9: {
    id: 9,
    name: 'Default',
  },
  10: {
    id: 10,
    name: 'Offline',
  },
  11: {
    id: 11,
    name: 'Ok',
  },
  1: {
    id: 1,
    name: 'Success',
  },
  2: {
    id: 2,
    name: 'Warning',
  },
  12: {
    id: 12,
    name: 'Warning',
  },
};

export const siteTypes = {
  1: {
    id: 1,
    label: 'sow',
    name: 'Sow',
  },
  2: {
    id: 2,
    label: 'piglet',
    name: 'Piglet',
  },
  3: {
    id: 3,
    label: 'fattening',
    name: 'Fattening',
  },
};

export const Roles = {
  USER: 'USER',
  ADMIN: 'ADMIN',
};
