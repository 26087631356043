/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import { fromJS } from 'immutable';
import * as constants from 'data/constants/admin/gateways';
import LoadingProgress from 'data/utils/reducers/loading';
import Pagination from 'data/utils/reducers/pagination';

export const itemsPagination = new Pagination('adminGateways');
export const loadingProgress = new LoadingProgress('adminGateway');
export const listLoadingProgress = new LoadingProgress('adminGatewaysList');
export const pcbFileLoadingProgress = new LoadingProgress('adminPcbFile');

const mergeData = (state, payload) =>
  state.withMutations((newState) => {
    const {
      entities: { gateways },
    } = payload;
    newState.mergeIn(['entities'], fromJS(gateways));
  });

const loadList = (state, action) =>
  state.withMutations((newState) => {
    const { result, perPage, total } = action.payload;
    mergeData(newState, action.payload);
    itemsPagination.set(newState, total, perPage, fromJS(result));
    listLoadingProgress.setLoaded(newState);
  });

const loadEntity = (state, action) =>
  state.withMutations((newState) => {
    mergeData(newState, action.payload);
    loadingProgress.setLoaded(newState);
  });

const loadPcbFile = (state, action) =>
  state.withMutations((newState) => {
    newState.set('pcbLoadResult', action.payload);
    pcbFileLoadingProgress.setLoaded(newState);
  });

const clear = (state) =>
  state.withMutations((newState) => {
    listLoadingProgress.clear(newState);
    itemsPagination.clear(newState);
    loadingProgress.clear(newState);
    pcbFileLoadingProgress.clear(newState);
  });

const clearPcbUpload = (state) =>
  state.withMutations((newState) => {
    pcbFileLoadingProgress.clear(newState);
  });

const initialState = fromJS({
  entities: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST_LOAD_START:
      return listLoadingProgress.setLoading(state);
    case constants.LIST_LOAD_FAILED:
      return listLoadingProgress.setLoadFailed(state);
    case constants.LIST_LOAD_SUCCESS:
      return loadList(state, action);
    case constants.LOAD_START:
      return loadingProgress.setLoading(state);
    case constants.LOAD_FAILED:
      return loadingProgress.setLoadFailed(state);
    case constants.LOAD_SUCCESS:
      return loadEntity(state, action);
    case constants.PCB_UPLOAD_START:
      return pcbFileLoadingProgress.setLoading(state);
    case constants.PCB_UPLOAD_FAILED:
      return pcbFileLoadingProgress.setLoadFailed(state);
    case constants.PCB_UPLOAD_SUCCESS:
      return loadPcbFile(state, action);
    case constants.CLEAR_PCB_UPLOAD:
      return clearPcbUpload(state);

    case constants.CLEAR:
      return clear(state);
    default:
      return state;
  }
};
