/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'ui/constants';
import s from './styles.scss';

const HorizontalChart = ({ title, okAmount, alertsAmount, warningsAmount, total }) => {
  const ok = (okAmount / total) * 100;
  const alerts = (alertsAmount / total) * 100;
  const warnings = (warningsAmount / total) * 100;
  return (
    <div className={s.chartOuter}>
      <div className={s.title}>{title}</div>
      <div className={s.bar}>
        <div className={s.border}>
          <div
            className={s.filled}
            style={{
              backgroundColor: colors.success,
              width: `${ok}%`,
              left: 0,
              borderBottomLeftRadius: 5,
              borderTopLeftRadius: 5,
              borderBottomRightRadius: alerts === 0 && warnings === 0 ? 5 : 0,
              borderTopRightRadius: alerts === 0 && warnings === 0 ? 5 : 0,
            }}
          />
          <div
            className={s.filled}
            style={{
              backgroundColor: colors.warning,
              width: `${warnings}%`,
              left: `${0 + ok}%`,
              borderBottomLeftRadius: warnings + alerts === 100 ? 5 : 0,
              borderTopLeftRadius: warnings + alerts === 100 ? 5 : 0,
              borderBottomRightRadius: alerts === 0 ? 5 : 0,
              borderTopRightRadius: alerts === 0 ? 5 : 0,
            }}
          />
          <div
            className={s.filled}
            style={{
              backgroundColor: colors.error,
              width: `${alerts}%`,
              left: `${0 + ok + warnings}%`,
              borderBottomLeftRadius: alerts === 100 ? 5 : 0,
              borderTopLeftRadius: alerts === 100 ? 5 : 0,
              borderBottomRightRadius: 5,
              borderTopRightRadius: 5,
            }}
          />
        </div>
      </div>
    </div>
  );
};

HorizontalChart.defaultProps = {
  style: null,
  title: null,
  total: null,
  okAmount: null,
  alertsAmount: null,
  warningsAmount: null,
};

HorizontalChart.propTypes = {
  // eslint-disable-next-line
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  total: PropTypes.number,
  okAmount: PropTypes.number,
  alertsAmount: PropTypes.number,
  warningsAmount: PropTypes.number,
};

export default HorizontalChart;
